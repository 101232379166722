import { createBrowserRouter } from 'react-router-dom';
import { importPages } from './lazyRouter';
import { RouterPaths } from './constants';
import AdminLayout from 'layout/AdminLayout/AdminLayout';
import { LandingLayout } from 'layout';
import { JobsLayout } from 'features/jobs';
import AuthLayout from '../layout/AuthLayout/AuthLayout.tsx';
import ErrorView from 'components/ui/ErrorView/ErrorView.tsx';

export const router = createBrowserRouter([
  {
    element: <LandingLayout />,
    errorElement: <ErrorView />,
    children: [
      {
        path: RouterPaths.Home,
        element: importPages.Landing,
      },
      {
        path: RouterPaths.Career,
        element: importPages.Career,
      },
      {
        path: RouterPaths.Contact,
        element: importPages.Contact,
      },
      {
        path: RouterPaths.Job,
        element: importPages.Job,
      },
      {
        path: RouterPaths.AboutUs,
        element: importPages.AboutUs,
      },
      {
        path: RouterPaths.Technologies,
        element: importPages.Technologies,
      },
    ],
  },
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: RouterPaths.Login,
        element: importPages.Login,
      },
      {
        path: RouterPaths.ChangePassword,
        element: importPages.ChangePassword,
      },
    ],
  },
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      {
        path: RouterPaths.Dashboard,
        element: importPages.Dashboard,
      },
      {
        path: RouterPaths.Welcome,
        element: importPages.Welcome,
      },
      {
        path: RouterPaths.Homepage,
        element: importPages.Homepage,
      },
      {
        path: RouterPaths.Settings,
        element: importPages.Settings,
      },
      {
        path: RouterPaths.Users,
        element: importPages.Users,
      },
      {
        path: RouterPaths.Careers,
        element: importPages.Careers,
      },
      {
        element: <JobsLayout />,
        children: [
          {
            path: RouterPaths.Jobs,
            element: importPages.Jobs,
          },
          {
            path: RouterPaths.Jobs_create,
            element: importPages.JobsManagement,
          },
          {
            path: RouterPaths.Jobs_edit,
            element: importPages.JobsManagement,
          },
        ],
      },
      {
        path: RouterPaths.Contacts,
        element: importPages.Contacts,
      },
      {
        path: RouterPaths.ContactUs,
        element: importPages.ContactUs,
      },
      {
        path: RouterPaths.JobApplies,
        element: importPages.JobApplies,
      },
    ],
  },
  {
    path: RouterPaths.NotFound,
    element: importPages.NotFound,
  },
  {
    path: RouterPaths.Maintenance,
    element: <ErrorView isMaintenance />,
  },
  {
    path: RouterPaths.Error,
    element: <ErrorView />,
  },
]);
