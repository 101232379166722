import { DragIndexState, ITable, ITableActionType, ITableArgs, ITableReducer } from '../types';
import React, { Key, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import {
  FilterValue,
  SorterResult,
  SortOrder,
  TableCurrentDataSource,
  TableRowSelection,
} from 'antd/es/table/interface';
import { TablePaginationConfig } from 'antd/lib';
import { ExportTypesEnum } from '../constants';
import { useSearchParams } from 'react-router-dom';
import { DragEndEvent, DragOverEvent, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { tableReducer, tableReducerActions } from '../reducer/tableReducer';
import { Table, TableProps } from 'antd';
import { useAppDispatch } from 'hooks';
import { modalDispatcher } from '../../Modal/services/modalSlice.ts';
import { MODALS } from '_constants';
import {
  useDeleteConfigMutation,
  useGetConfigQuery,
  useLazyExportDataQuery,
  useSaveConfigMutation,
} from 'api';
import SortAscendIcon from '../components/Icons/SortAscendIcon.tsx';
import { SortDescendIcon } from '../components';
import {
  deleteConvertor,
  filtersConvertor,
  getDefaultFiltersAndOrders,
} from '../utils/filterHelpers.ts';

function useTable<T>({
  columns,
  total,
  editAction,
  deleteAction,
  tableKey,
}: ITableArgs<T>): ITable<T> {
  const dispatch = useAppDispatch();
  const { openModal } = modalDispatcher(dispatch);
  const [exportData] = useLazyExportDataQuery();
  const [saveConfig] = useSaveConfigMutation();
  const [deleteConfig] = useDeleteConfigMutation();
  const { data: configData, isFetching: configDataFetching } = useGetConfigQuery({
    Keys: tableKey,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsPage = searchParams.get('page');
  const paramsPageSize = searchParams.get('pageSize');
  const Page = paramsPage ? JSON.parse(paramsPage) : 1;
  const PageSize = paramsPageSize ? JSON.parse(paramsPageSize) : 1;
  const paramsFilters = searchParams.get('filters');
  const paramsOrder = searchParams.get('orderBy');

  const convertColumns = () => {
    return columns
      .filter((el) => el.title)
      ?.map((el) => ({
        visible: true,
        fixed: false,
        label: el.title as string,
        value: el.key,
      }));
  };

  const { initialOrders, initialFiltered } = getDefaultFiltersAndOrders(paramsFilters, paramsOrder);
  const setTableColumns = () =>
    columns?.map((el) => ({
      ...el,
      filtered: initialFiltered.includes(el.key as string),
      onHeaderCell: () => ({ id: el.key as string }),
      onCell: () => ({ id: el.key as string }),
      sortIcon: () => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              justifyContent: 'center',
            }}
          >
            <SortAscendIcon
              fill={initialOrders[el.key as string] === 'ascend' ? '#1677ff' : '#00000040'}
            />
            <SortDescendIcon
              fill={initialOrders[el.key as string] === 'descend' ? '#1677ff' : '#00000040'}
            />
          </div>
        );
      },
    }));

  const initialState = useMemo((): ITableReducer<T> => {
    return {
      checkedItemsIds: [],
      checkedItems: [],
      selectedAll: false,
      pageSize: 10,
      page: 1,
      customizeColumns: configData?.[tableKey]
        ? JSON.parse(configData?.[tableKey])
        : convertColumns(),
      tableColumns: setTableColumns(),
      isCheckAll: false,
    };
  }, [columns, configData]);

  const [reducerState, reducerDispatch] = useReducer<
    React.Reducer<ITableReducer<T>, ITableActionType<T>>
  >(tableReducer, initialState);

  const rowSelection: TableRowSelection<T> = {
    selectedRowKeys: reducerState.checkedItemsIds,
    onChange: (selectedRowKeys: React.Key[], selectedRows: T[]) => {
      reducerDispatch({
        type: tableReducerActions.SET_CHECKED_ITEMS_IDS,
        payload: selectedRowKeys,
      });
      reducerDispatch({
        type: tableReducerActions.SET_CHECKED_ITEMS,
        payload: selectedRows,
      });
      reducerDispatch({
        type: tableReducerActions.TOGGLE_CHECK_ALL,
        payload: false,
      });
    },
    onSelectAll: (selected: boolean) => {
      reducerDispatch({
        type: tableReducerActions.SET_SELECTED_ALL,
        payload: selected,
      });
      reducerDispatch({
        type: tableReducerActions.TOGGLE_CHECK_ALL,
        payload: false,
      });
    },
    selections: [
      Table.SELECTION_ALL,
      {
        key: 'all_db',
        text: 'Select All From DB',
        onSelect: (currentRowKeys) => {
          reducerDispatch({
            type: tableReducerActions.SET_CHECKED_ITEMS_IDS,
            payload: currentRowKeys,
          });
          reducerDispatch({
            type: tableReducerActions.TOGGLE_CHECK_ALL,
            payload: true,
          });
        },
      },
      Table.SELECTION_NONE,
    ],
    fixed: 'left',
  };

  const handleSort = useCallback(
    (key: Key, order: SortOrder | undefined) => {
      const currentParams = paramsOrder ? JSON.parse(paramsOrder) : {};
      if (Object.keys(currentParams).includes(key as string)) {
        setSearchParams((prev) => {
          if (!order) {
            const { [key as string]: _, ...rest } = currentParams;
            if (Object.keys(rest).length === 0) {
              prev.delete('orderBy');
            } else {
              prev.set('orderBy', JSON.stringify(rest));
            }
          } else {
            prev.set('orderBy', JSON.stringify({ ...currentParams, [key as string]: order }));
          }
          return prev;
        });
      } else {
        setSearchParams((prev) => {
          prev.set('orderBy', JSON.stringify({ ...currentParams, [key as string]: order }));
          return prev;
        });
      }
    },
    [paramsOrder, setSearchParams],
  );

  const handleTableChange: TableProps<T>['onChange'] = (
    _: TablePaginationConfig,
    __: Record<string, FilterValue | null>,
    sorter: SorterResult<T> | SorterResult<T>[],
    extra: TableCurrentDataSource<T>,
  ) => {
    if (extra.action === 'sort') {
      if (Array.isArray(sorter)) {
        return;
      }
      const { columnKey, order } = sorter;
      if (columnKey) {
        handleSort(columnKey, order);
      }
    }
  };

  const pagination: TablePaginationConfig = {
    position: ['bottomRight'],
    onChange: (selectedPage: number) => {
      setSearchParams((prev) => {
        prev.set('page', String(selectedPage));
        return prev;
      });
      reducerDispatch({
        type: tableReducerActions.SET_PAGE,
        payload: selectedPage,
      });
    },
    onShowSizeChange: (currentPage: number, pageSize: number) => {
      setSearchParams((prev) => {
        prev.set('page', String(currentPage));
        prev.set('pageSize', String(pageSize));
        return prev;
      });
      reducerDispatch({
        type: tableReducerActions.SET_PAGE_SIZE,
        payload: pageSize,
      });
      reducerDispatch({
        type: tableReducerActions.SET_PAGE,
        payload: currentPage,
      });
    },
    current: Page,
    pageSize: PageSize,
    total: total,
    showSizeChanger: true,
    showQuickJumper: true,
  };

  const onCheckColumn = (id: string) => {
    reducerDispatch({
      type: tableReducerActions.ON_SHOW_COLUMN,
      payload: id,
    });
  };

  const onColumnFix = (id: string) => {
    reducerDispatch({
      type: tableReducerActions.TOGGLE_FIX_COLUMN,
      payload: id,
    });
  };

  const onEdit = (value: T) => {
    editAction?.(value);
  };

  const handleDelete = () => {
    if (reducerState.isCheckAll) {
      deleteAction?.({
        filters: filtersConvertor(paramsFilters ? JSON.parse(paramsFilters) : []),
        tableKey,
        originalData: reducerState.checkedItems,
        isCheckAll: reducerState.isCheckAll,
      });
      return;
    }
    deleteAction?.({
      filters: deleteConvertor(reducerState.checkedItemsIds.map((el) => String(el))),
      tableKey,
      originalData: reducerState.checkedItems,
      isCheckAll: reducerState.isCheckAll,
    });
  };

  const onDelete = () => {
    deleteAction &&
      openModal({
        modalType: MODALS.CONFIRM_MODAL,
        props: {
          title: 'Delete items',
          message: 'Are you sure you want to remove this items?',
          action: async () => {
            await handleDelete();
            await handleSetDefault();
          },
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
        },
      });
  };

  const handleSetDefault = () => {
    reducerDispatch({
      type: tableReducerActions.SET_CHECKED_ITEMS_IDS,
      payload: [],
    });
    reducerDispatch({
      type: tableReducerActions.SET_CHECKED_ITEMS,
      payload: [],
    });
    reducerDispatch({
      type: tableReducerActions.SET_PAGE,
      payload: 1,
    });
  };

  const handleExport = (type: ExportTypesEnum) => {
    exportData({ tableKey, ExportType: type, Page, PageSize, Filter: '' });
  };

  const handleClearAllFilters = () => {
    setSearchParams((prev) => {
      prev.delete('filters');
      prev.delete('orderBy');
      return prev;
    });
  };

  const handleSaveAsDefault = () => {
    if (configData) {
      deleteConfig([tableKey]).then((res) => {
        if ('data' in res) {
          saveConfig({ [tableKey]: JSON.stringify(reducerState.customizeColumns) });
        }
      });
      return;
    }
    saveConfig({ [tableKey]: JSON.stringify(reducerState.customizeColumns) });
  };

  const cancelCustomizeColumns = () => {
    if (configData && configData[tableKey]) {
      reducerDispatch({
        type: tableReducerActions.SET_DEFAULT_CUSTOMIZE,
        payload: configData[tableKey],
      });
    } else {
      reducerDispatch({
        type: tableReducerActions.SET_DEFAULT_CUSTOMIZE,
        payload: JSON.stringify(convertColumns()),
      });
    }
  };

  ///////////////////// DND functions

  const [dragIndex, setDragIndex] = useState<DragIndexState>({
    active: -1,
    over: -1,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const onDragEnd = (data: DragEndEvent) => {
    const { active, over } = data;
    if (active.id !== over?.id) {
      reducerDispatch({
        type: tableReducerActions.DRAG_END_EVENT,
        payload: data,
      });
    }
    setDragIndex({ active: -1, over: -1 });
  };

  const onDragOver = ({ active, over }: DragOverEvent) => {
    const activeIndex = columns.findIndex((i) => i.key === active.id);
    const overIndex = columns.findIndex((i) => i.key === over?.id);
    setDragIndex({
      active: active.id,
      over: over?.id,
      direction: overIndex > activeIndex ? 'right' : 'left',
    });
  };

  /////////////////////////////////

  useEffect(() => {
    reducerDispatch({
      type: tableReducerActions.SET_TABLE_COLUMNS,
      payload: setTableColumns(),
    });
  }, [paramsFilters, paramsOrder]);

  useEffect(() => {
    if (configData && configData[tableKey]) {
      reducerDispatch({
        type: tableReducerActions.SET_DEFAULT_CUSTOMIZE,
        payload: configData[tableKey],
      });
    }
  }, [configData]);

  return {
    rowSelection,
    pagination,
    onChange: handleTableChange,
    customProps: {
      ...reducerState,
      onCheckColumn,
      onEdit,
      onDelete,
      handleExport,
      onColumnFix,
      handleClearAllFilters,
      handleSaveAsDefault,
      configDataFetching: configDataFetching,
      cancelCustomizeColumns,
    },
    dnd: { onDragEnd, onDragOver, sensors, dragIndex },
  };
}

export default useTable;
