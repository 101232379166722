import { Button, Popover } from 'antd';
import { ExportTypesEnum } from '../../constants';
import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';

interface Props {
  handleExport: (type: ExportTypesEnum) => void;
}

const ExportButton: React.FC<Props> = ({ handleExport }) => {
  const content = (
    <div style={{ padding: 8, display: 'flex', gap: 8, flexDirection: 'column' }}>
      {Object.keys(ExportTypesEnum).map((el) => {
        return (
          <p
            onClick={() => handleExport(ExportTypesEnum[el as keyof typeof ExportTypesEnum])}
            style={{ cursor: 'pointer' }}
            key={el}
          >{`.${el}`}</p>
        );
      })}
    </div>
  );

  return (
    <Popover content={content} trigger="click" placement="bottomLeft">
      <Button>
        Export <DownloadOutlined />
      </Button>
    </Popover>
  );
};

export default ExportButton;
