import { useDarkMode } from 'context';
import styles from './NotFoundView.module.scss';
import { logo_white, not_found_dark, not_found_white, panda_logo } from 'assets';
import { Button } from 'components/shared';
import { Link } from 'react-router-dom';

export default function NotFoundView() {
  const { isDarkMode } = useDarkMode();
  return (
    <div className={styles.notFound}>
      <div className={styles.notFoundInner}>
        <div>
          <img
            width={300}
            height={63}
            src={isDarkMode ? logo_white : panda_logo}
            alt="panda logo"
          />
        </div>
        <div>
          <img width={500} src={isDarkMode ? not_found_white : not_found_dark} alt="" />
        </div>
        <div className={styles.notFoundFooter}>
          <p>Page not found</p>
          <Link to="/">
            <Button>Back to homepage</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
