import React, { useEffect } from 'react';
import { ComparisonTypesEnum, FilterTypesEnum, IItemFields } from '../../types';
import { Input } from 'antd';
import { ISelectTypeV2 } from 'types';
import { useSearchParams } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

interface Props {
  type: FilterTypesEnum.NUMBER_COLLECTION | FilterTypesEnum.TEXT_COLLECTION;
  propertyName: string;
  name: string;
  comparisonType: string;
  options?: ISelectTypeV2[];
  handleSelected: (data: string[]) => void;
  selected: string[];
  handleChangeComparisonType: (value: ComparisonTypesEnum) => void;
}

const InputFilter: React.FC<Props> = ({
  type,
  name,
  handleSelected,
  selected,
  propertyName,
  handleChangeComparisonType,
}) => {
  const [searchParams] = useSearchParams();
  const paramsFilters = searchParams.get('filters');
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      handleSelected([e.target.value]);
      return;
    }
    handleSelected([]);
  };

  useEffect(() => {
    if (!paramsFilters) {
      handleSelected([]);
    }
    if (paramsFilters) {
      const filters: IItemFields[] = JSON.parse(paramsFilters);
      const findProperty = filters.find((el: IItemFields) => el.PropertyName === propertyName);
      if (findProperty) {
        handleSelected(findProperty.CheckedItems);
        handleChangeComparisonType(findProperty.ComparisonType as ComparisonTypesEnum);
      }
    }
  }, [paramsFilters]);

  return (
    <Input
      prefix={<SearchOutlined />}
      style={{ width: '100%' }}
      allowClear
      placeholder={name}
      id={`input_${name}`}
      onChange={handleInputChange}
      value={selected[0]}
      type={type === FilterTypesEnum.NUMBER_COLLECTION ? 'number' : 'text'}
    />
  );
};

export default InputFilter;
