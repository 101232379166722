import { ElementType } from 'react';
import { MODALS } from '_constants/constants';
import { ConfirmModal } from 'components/ui';
import { UsersModal } from 'features/users';
import { JobApplyModal } from 'features/jobApplies';
import JobConfirmModal from 'components/ui/JobConfirmModal/JobConfirmModal';

export type ModalList = Record<string, ElementType>;

export const modalList = {
  [MODALS.CONFIRM_MODAL]: ConfirmModal,
  [MODALS.USERS_MODAL]: UsersModal,
  [MODALS.JOB_APPLY_MODAL]: JobApplyModal,
  [MODALS.JOB_CONFIRM_MODAL]: JobConfirmModal,
} as ModalList;
