import { RootState } from 'store/store';
import { createSlice } from '@reduxjs/toolkit';
import { createDispatchers } from 'utils';

export const initialState: { forcePasswordChange: boolean | null } = {
  forcePasswordChange: null,
};

export const loginSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setPasswordChange: (state, { payload }) => {
      state.forcePasswordChange = payload;
    },
  },
});

export const { setPasswordChange } = loginSlice.actions;

export const loginDispatcher = createDispatchers(loginSlice.actions);
export const loginSelector = (state: RootState) => state.login;

export default loginSlice.reducer;
