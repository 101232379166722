export const antTheme = {
  token: {
    fontFamily: 'Inter',
    fontSize16: 'clamp(0.8rem, 1vw, 1rem)',
    fontSize18: 'clamp(0.875rem, 1.125vw, 1rem)',
    fontSize24: 'clamp(1.125rem, 1.5vw, 1.5rem)',
    fontSize32: 'clamp(1.5rem, 2vw, 2rem)',
    gradient1: 'linear-gradient(270deg, #3ECC99 -3.58%, #4E37D3 -3.57%, #F53A9A 101.67%)',
    colorPrimary: '#4E37D3',
    fullHeight: '100dvh',
    fullWidth: '100%',
    borderBottomBlack: '1px solid #00000029',
    flexCenter: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    colorPrimaryText: '#4C36CC',
  },
  components: {
    Button: {
      colorPrimary: '#FFB133',
      boxShadow: '0px 4px 4px 0px rgba(41, 46, 70, 0.24)',
      colorPrimaryHover: '#dc9522',
      algorithm: true,
    },
    Input: {
      algorithm: true,
    },
    DatePicker: {
      algorithm: true,
    },
    Select: {
      algorithm: true,
    },
    Form: {
      itemMarginBottom: 16,
    },
  },
};
