import { FilterTypesEnum } from '../../types';
import { Table as AntTable, TableColumnsType } from 'antd';
import { AGGREGATES_TITLES, FILTER_HELPER, TABLE_KEY_ENUM } from '../../constants';
import AggregatesDropDown from '../AggregatesDropDown/AggregatesDropDown';

interface Props<T> {
  columns: TableColumnsType<T>;
  aggregates?: { [key: string]: FilterTypesEnum };
  hasCheckboxes: boolean;
  path: TABLE_KEY_ENUM;
}

const AggregatesWrapper = <T extends Record<string, any>>({
  aggregates,
  columns,
  hasCheckboxes,
  path,
}: Props<T>) => {
  const renderContent = (columnName: string, type?: FilterTypesEnum) => {
    if (type && FILTER_HELPER[type].AggregateTypes.length > 0) {
      return (
        <AggregatesDropDown
          style={{ width: '100%' }}
          options={FILTER_HELPER[type].AggregateTypes.map((el) => ({
            label: AGGREGATES_TITLES[el as keyof typeof AGGREGATES_TITLES],
            value: el,
          }))}
          placeholder={'Aggregates'}
          aggregateType={type}
          path={path}
          columnName={columnName}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      {(hasCheckboxes
        ? [
            {
              title: 'checkboxes',
              dataIndex: 'checkboxes',
              key: 'checkboxes',
              align: 'center',
            },
            ...columns,
          ]
        : columns
      ).map((el, index) => {
        return (
          <AntTable.Summary.Cell key={el.key} index={index}>
            {renderContent(el.key as string, aggregates?.[el.key as keyof typeof aggregates])}
          </AntTable.Summary.Cell>
        );
      })}
    </>
  );
};

export default AggregatesWrapper;
