import { Select as AntSelect, SelectProps, Spin } from 'antd';
import React, { useState } from 'react';
import {
  ComparisonTypesEnum,
  detectComparisonTypeForSearch,
  filtersConvertor,
  FilterTypesEnum,
} from '../../utils';
import { IRequestData, ISelectTypeV2, ITableResponse } from 'types';
import { debounce } from 'utils/helpers.ts';

type Props<T> = {
  request?: (data: IRequestData) => Promise<any>;
  responseData?: ITableResponse<T>;
  loading?: boolean;
  PropertyName?: string;
} & SelectProps;

const Select = <T extends Record<string, any>>({
  request,
  responseData,
  loading,
  PropertyName = 'Name',
  ...props
}: Props<T>) => {
  const [options, setOptions] = useState<ISelectTypeV2[]>([]);
  const [page, setPage] = useState<number>(1);

  const handleSearch = debounce((value) => {
    getData(1, value);
  });

  const handleDropdownScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      (responseData?.totalCount || 0) > options.length && getData(page + 1);
    }
  };

  const onDropdownVisibleChange = (open: boolean) => {
    if (open) {
      getData(page);
    }
    if (!open) {
      setPage(1);
      setOptions([]);
    }
  };

  const getData = (page: number, search?: string) => {
    request?.({
      Page: page,
      PageSize: 10,
      Filter: filtersConvertor(
        search
          ? [
              {
                PropertyName: PropertyName,
                Search: search,
                Values: [search],
                CheckedItems: [],
                ComparisonType: detectComparisonTypeForSearch(FilterTypesEnum.TEXT),
                TypeForUi: ComparisonTypesEnum.EQUAL,
                ColumnType: FilterTypesEnum.TEXT,
              },
            ]
          : [],
      ),
    }).then((res: any) => {
      if ('data' in res) {
        setOptions((prev) => [
          ...prev,
          ...(res.data?.data.map((el: any) => ({ label: el.value, value: el.key })) || []),
        ]);
        setPage((prev) => res.data?.page || prev + 1);
      }
    });
  };

  return (
    <AntSelect
      {...props}
      id={`select_${props.id}`}
      showSearch
      allowClear
      style={{ width: '100%' }}
      placeholder={'Type here'}
      options={options}
      onSearch={handleSearch}
      onPopupScroll={handleDropdownScroll}
      onDropdownVisibleChange={onDropdownVisibleChange}
      labelInValue
      dropdownRender={(menu) => (
        <>
          {menu}
          {loading && (
            <div style={{ textAlign: 'center', padding: '8px' }}>
              <Spin />
            </div>
          )}
        </>
      )}
    />
  );
};

export default Select;
