import { IDeleteArgs, TCustomize } from '../../types';
import { Key, ReactNode } from 'react';
import { ExportTypesEnum } from '../../constants';
import { Button, Flex } from 'antd';
import CheckboxMenu from '../CheckboxMenu/CheckboxMenu.tsx';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ExportButton } from '../index.ts';

interface Props<T> {
  checkedItems: T[];
  exportTrigger?: boolean;
  onCheckColumn?: (id: string) => void;
  customizeColumns: TCustomize[];
  onColumnFix?: (id: string) => void;
  handleSaveAsDefault?: (options: TCustomize[]) => void;
  handleClearAllFilters?: () => void;
  customActions?: {
    title: string;
    action: (values: T[]) => void;
    icon?: ReactNode;
  }[];
  editAction?: (value: T) => void;
  handleEdit: () => void;
  onDelete?: () => void;
  deleteAction?: (deleteArgs: IDeleteArgs<T>) => void;
  tableAction?: ReactNode;
  handleExport?: (type: ExportTypesEnum) => void;
  canBeSave: boolean;
  cancelCustomizeColumns?: () => void;
  checkedItemsIds: Key[];
}

const TableHeader = <T extends Record<string, any>>({
  onCheckColumn,
  customizeColumns,
  onColumnFix,
  handleSaveAsDefault,
  handleClearAllFilters,
  customActions,
  checkedItems,
  editAction,
  handleEdit,
  deleteAction,
  onDelete,
  tableAction,
  exportTrigger,
  handleExport,
  canBeSave,
  cancelCustomizeColumns,
  checkedItemsIds,
}: Props<T>) => {
  return (
    <div
      style={{
        height: 60,
        width: '100%',
        backgroundColor: '#FFF',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 0 ',
      }}
    >
      <Flex gap={8} align={'center'}>
        {onCheckColumn && (
          <CheckboxMenu
            canBeSave={canBeSave}
            options={customizeColumns}
            onCheck={onCheckColumn}
            onToggleFixed={(id: string) => onColumnFix?.(id)}
            handleSaveAsDefault={handleSaveAsDefault}
            cancelCustomizeColumns={cancelCustomizeColumns}
          />
        )}
        {handleClearAllFilters && (
          <Button onClick={handleClearAllFilters}>Clear filters and sorts</Button>
        )}
        {customActions?.map((el) => (
          <Button key={el.title} onClick={() => el.action(checkedItems)}>
            {el.title}
          </Button>
        ))}
        {editAction && checkedItems?.length === 1 && (
          <Button type={'link'} onClick={handleEdit} style={{ color: '#4C36CC' }}>
            <EditOutlined />
            Edit
          </Button>
        )}
        {deleteAction && checkedItemsIds.length > 0 ? (
          <Button type={'link'} onClick={onDelete} style={{ color: '#4C36CC' }}>
            <DeleteOutlined />
            Delete
          </Button>
        ) : (
          <></>
        )}
      </Flex>
      <Flex align={'center'} gap={8}>
        {tableAction}
        {exportTrigger && handleExport && <ExportButton handleExport={handleExport} />}
      </Flex>
    </div>
  );
};

export default TableHeader;
