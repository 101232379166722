import { StorageKeys } from '_constants';

import { createContext, useState, useEffect, useMemo, ReactNode } from 'react';

// Define an interface for the context value
export interface DarkModeContextValue {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
}

// Step 1: Create a new context with the defined interface
export const DarkModeContext = createContext<DarkModeContextValue | undefined>(undefined);

// Define an interface for the provider props
interface DarkModeProviderProps {
  children: ReactNode;
}

// Utility functions for local storage
const getStoredDarkMode = (): boolean => {
  if (typeof window === 'undefined') return false;
  const localValue = window.localStorage.getItem(StorageKeys.IS_DARK_MODE);
  if (localValue !== null) {
    return JSON.parse(localValue);
  } else {
    // Check the browser's preferred color scheme
    const prefersDarkMode = window.matchMedia(StorageKeys.COLOR_SCHEME).matches;
    return prefersDarkMode;
  }
};

const setStoredDarkMode = (value: boolean) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(StorageKeys.IS_DARK_MODE, JSON.stringify(value));
  }
};

// Step 2: Create a provider component
export const DarkModeProvider = ({ children }: DarkModeProviderProps) => {
  const [isDarkMode, setIsDarkMode] = useState<boolean>(getStoredDarkMode);

  useEffect(() => {
    setStoredDarkMode(isDarkMode);
    if (isDarkMode) {
      document.body.classList.add(StorageKeys.DARK_MODE);
    } else {
      document.body.classList.remove(StorageKeys.DARK_MODE);
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };

  const value = useMemo(() => ({ isDarkMode, toggleDarkMode }), [isDarkMode]);

  return <DarkModeContext.Provider value={value}>{children}</DarkModeContext.Provider>;
};

// Custom hook to use the dark mode context
