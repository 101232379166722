import Collapse from 'components/shared/Collaps/Collaps';
import styles from './FAQ.module.scss';
import { useGetFaqQuery } from 'features/landing/services/landingApi';

export default function FAQ() {
  const { data: faq } = useGetFaqQuery();
  return (
    <section className={styles.faq}>
      <div
        style={{
          // background: 'var(--hero-bg)',
          position: 'relative',
        }}
      >
        <div className={styles.faqContent}>
          <h1
            className={styles.faqTitle}
            style={{
              textAlign: 'center',
              marginBottom: 40,
            }}
          >
            Frequently asked questions
          </h1>
          <div className={styles.faqContainer}>
            {faq?.map((item) => {
              return (
                <Collapse title={item.question} key={item.id}>
                  <p
                    style={{
                      marginTop: 32,
                    }}
                  >
                    {item.answer}
                  </p>
                </Collapse>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
}
