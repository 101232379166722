import { useDarkMode } from 'context';
import styles from './Footer.module.scss';
import { logo_white, panda_logo, pdf_dark, pdf_light } from 'assets';
import { Link } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { FacebookFilled, InstagramFilled, LinkedinFilled } from '@ant-design/icons';

export default function Footer() {
  const { isDarkMode } = useDarkMode();
  const { isMobile } = useWindowSize();
  const color = {
    color: isDarkMode ? '#9C9C9C' : '#000',
  };
  return (
    <footer className={styles.footer}>
      <div className={styles.info}>
        <div>
          <img src={isDarkMode ? logo_white : panda_logo} alt="logo" width={isMobile ? 156 : 236} />
        </div>
        <ul>
          <li>
            <p>8/2 Adonts Street, 0014, Yerevan, Armenia</p>
          </li>
          <li style={{ marginTop: '8px' }}>
            <a href={isDarkMode ? pdf_dark : pdf_light} rel="noreferrer" target="_blank">
              PandaTech LLC Requisites
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <Link to="tel:+374 41 55 14 55">Phone: +374 41 55 14 55</Link>
          </li>
          <li style={{ marginTop: '8px' }}>
            <Link to="mailto:info@pandatech.it"> info@pandatech.it</Link>
          </li>
        </ul>
      </div>
      <div className={styles.media}>
        <ul className={styles.socialMedia}>
          <li>
            <a href="https://www.facebook.com/pandatechsolution" rel="noreferrer" target="_blank">
              <FacebookFilled style={color} />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/pandatechsolution"
              rel="noreferrer"
              target="_blank"
            >
              <LinkedinFilled style={color} />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/pandatechinsights/" rel="noreferrer" target="_blank">
              <InstagramFilled style={color} />
            </a>
          </li>
        </ul>
        <p className={styles.cr}> Copyright {new Date().getFullYear()}, All rights reserved </p>
      </div>
    </footer>
  );
}
