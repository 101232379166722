import React, { ReactNode, useState } from 'react';
import { ComparisonTypesEnum, FilterTypesEnum, FilterTypesUiHelper } from '../../types';
import { Button, Col, Flex, Row, Select, Tabs } from 'antd';
import { FILTER_HELPER } from '../../constants';
import { useFilters } from '../../hooks/useFilters';
import { DatePickerFilter, InputFilter, SelectFilter } from '../index';
import { ISelectType, ISelectTypeV2 } from 'types';

interface Props {
  type: FilterTypesEnum;
  name: string;
  endpointPath?: string;
  propertyName: string;
  options?: ISelectTypeV2[];
  onClose: () => void;
}

const FiltersWrapper: React.FC<Props> = ({
  type,
  name,
  endpointPath,
  propertyName,
  options,
  onClose,
}) => {
  const [error, setError] = useState<string>('');
  const [selected, setSelected] = useState<string[]>([]);
  const [comparisonType, setComparisonType] = useState(FILTER_HELPER[type].DefaultSearchType);
  const { handleSubmit } = useFilters({
    propertyName,
    selected,
    comparisonType,
    type,
    onClose,
  });

  const handleCancel = () => onClose();

  const handleChangeComparisonType = (value: ComparisonTypesEnum) => {
    setComparisonType(value);
  };

  const handleSelected = (value: string[]) => {
    setSelected(value);
  };

  const handleError = (value: string) => {
    setError(value);
  };

  const renderInput = () => {
    switch (type) {
      case FilterTypesEnum.BASE_36_ID:
      case FilterTypesEnum.TEXT:
      case FilterTypesEnum.ENCRYPTED_DATA:
      case FilterTypesEnum.NUMBER:
      case FilterTypesEnum.BOOLEAN:
      case FilterTypesEnum.TAGS:
      case FilterTypesEnum.CURRENCY:
        return (
          <SelectFilter
            type={type}
            propertyName={propertyName}
            endpointPath={endpointPath}
            comparisonType={comparisonType}
            name={name}
            selected={selected}
            handleSelected={handleSelected}
            options={options}
            error={error}
            handleError={handleError}
            handleChangeComparisonType={handleChangeComparisonType}
          />
        );
      case FilterTypesEnum.NUMBER_COLLECTION:
      case FilterTypesEnum.TEXT_COLLECTION:
        return (
          <InputFilter
            type={type}
            propertyName={propertyName}
            name={name}
            comparisonType={comparisonType}
            selected={selected}
            handleSelected={handleSelected}
            handleChangeComparisonType={handleChangeComparisonType}
          />
        );
      case FilterTypesEnum.DATE:
        return (
          <DatePickerFilter
            type={type}
            propertyName={propertyName}
            comparisonType={comparisonType}
            selected={selected}
            handleSelected={handleSelected}
            handleChangeComparisonType={handleChangeComparisonType}
          />
        );
    }
  };

  const FILTER_TAB_ITEMS: ({ content: ReactNode } & ISelectType)[] = [
    {
      id: 'simpleFilter',
      name: 'Simple filter',
      content: (
        <Row gutter={16} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.06)' }}>
          <Col span={24}>{renderInput()}</Col>
        </Row>
      ),
    },
    {
      id: 'advancedFilter',
      name: 'Advanced Filter',
      content: (
        <Row gutter={16} style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.06)' }}>
          <Col span={12}>
            <Select
              style={{ width: '100%' }}
              options={FILTER_HELPER[type].FilterTypes.map((el) => ({
                label: FilterTypesUiHelper[el as keyof typeof FilterTypesUiHelper],
                value: el,
              }))}
              value={comparisonType}
              onChange={handleChangeComparisonType}
            />
          </Col>
          <Col span={12}>{renderInput()}</Col>
        </Row>
      ),
    },
  ];

  return (
    <Flex vertical style={{ padding: '16px', width: '340px' }} gap={16}>
      <Tabs
        defaultActiveKey="1"
        tabPosition={'top'}
        items={FILTER_TAB_ITEMS.map((el: { content: ReactNode } & ISelectType) => {
          return {
            label: el.name,
            key: el.id as string,
            children: el.content,
          };
        })}
      />
      <Flex justify={'end'} gap={8}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button type={'primary'} onClick={handleSubmit}>
          Save
        </Button>
      </Flex>
    </Flex>
  );
};

export default FiltersWrapper;
