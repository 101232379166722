import { modalDispatcher, modalSelector } from '../services/modalSlice';
import { modalList } from '../constants/modalList';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { MODALS } from '_constants/constants';

function Modal() {
  const dispatch = useAppDispatch();
  const { closeTopModal } = modalDispatcher(dispatch);
  const { modals } = useAppSelector(modalSelector);

  return (
    <>
      {modals.map((item) => {
        const {
          modalType,
          props: { closeAction, maxWidth = 'sm', ...rest },
        } = item;
        const onModalClose = () => {
          closeTopModal();
          closeAction?.();
        };

        return (
          <React.Fragment key={modalType}>
            {React.createElement(modalList[modalType as MODALS], {
              ...rest,
              maxWidth,
              closeAction,
              onModalClose,
            })}
          </React.Fragment>
        );
      })}
    </>
  );
}

export default Modal;
