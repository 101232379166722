import React, { useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const toolbarOptions = [
  [{ font: [] }],
  [{ size: ['small', false, 'large', 'huge'] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{ color: [] }, { background: [] }],
  [{ script: 'sub' }, { script: 'super' }],
  [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  [{ direction: 'rtl' }],
  [{ align: [] }],
  ['clean'],
];

const TextEditor: React.FC<{ value: string; onChange: (value: string) => void }> = ({
  onChange,
  value,
}) => {
  const quillRef = useRef<ReactQuill>(null);

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const toolbar = editor.getModule('toolbar');

      const cleanButton = toolbar.container.querySelector('.ql-clean');
      const handleClear = () => {
        editor.deleteText(0, editor.getLength());
        onChange('');
      };

      cleanButton?.addEventListener('click', handleClear);

      return () => {
        cleanButton?.removeEventListener('click', handleClear);
      };
    }
  }, [onChange]);

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        value={value}
        onChange={onChange}
        modules={{ toolbar: toolbarOptions }}
      />
    </div>
  );
};

export default TextEditor;
