import React, { useState } from 'react';
import { Button, Layout } from 'antd';
import { Navigate, NavLink, Outlet } from 'react-router-dom';
import styles from './Layout.module.scss';
import { useAppDispatch, useToken } from 'hooks';
import Navigation from '../Navigation/Navigation.tsx';
import { AccountIcon, ErrorBoundary, Modal, SnackBar } from 'components';
import { RouterPaths } from 'router/constants.ts';
import { getLSData } from 'utils';
import { CHANGE_PASS_KEY, MODALS, TOKEN_KEY } from '_constants/constants.ts';
import { LogoutOutlined } from '@ant-design/icons';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice.ts';
import { useLogOutMutation } from 'features/settings/services/settingsApi.ts';
import classes from 'classnames';
import ErrorView from 'components/ui/ErrorView/ErrorView.tsx';

const { Header, Content } = Layout;

const AdminLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { openModal } = modalDispatcher(dispatch);
  const forcePassChange = getLSData(CHANGE_PASS_KEY);
  const isAccessTokenActive = getLSData(TOKEN_KEY);
  const { fullHeight, flexCenter } = useToken();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [logOut] = useLogOutMutation();

  const toggleCollapsed = (value: boolean) => {
    setCollapsed(value);
  };

  if (!isAccessTokenActive || (isAccessTokenActive && forcePassChange)) {
    return <Navigate to={`/${RouterPaths.Login}`} />;
  }

  const handleLogOut = () => {
    openModal({
      modalType: MODALS.CONFIRM_MODAL,
      props: {
        title: 'Log Out',
        message: 'Are you sure you want to log out?',
        action: () => logOut(),
        confirmButtonText: 'Log Out',
        cancelButtonText: 'Cancel',
      },
    });
  };

  return (
    <ErrorBoundary fallback={<ErrorView />}>
      <div className="admin-layout">
        <SnackBar />
        <Modal />
        <Layout hasSider>
          <Navigation collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
          <Layout
            style={{
              marginLeft: !collapsed ? 200 : 80,
              transition: 'all 0.1s ease-in-out',
            }}
          >
            <Header
              className={styles.header}
              style={{
                width: `calc(100% - ${collapsed ? 80 : 200}px)`,
                zIndex: 1,
              }}
            >
              <NavLink
                className={({ isActive }) =>
                  classes(`${styles.listElement}`, {
                    [styles.active]: isActive,
                  })
                }
                to={RouterPaths.Settings}
                style={{ ...flexCenter, gap: 8, lineHeight: 3 }}
              >
                <AccountIcon />
                My Account
              </NavLink>
              <Button
                type={'link'}
                onClick={handleLogOut}
                style={{ ...flexCenter, gap: 8, color: '#000' }}
              >
                <LogoutOutlined />
                Log Out
              </Button>
            </Header>
            <Content
              style={{
                overflow: 'initial',
                minHeight: fullHeight,
                paddingTop: 120,
                paddingLeft: 48,
                paddingRight: 48,
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </div>
    </ErrorBoundary>
  );
};

export default AdminLayout;
