import styles from './ErrorView.module.scss';

import { useDarkMode } from 'context';

import { cosmos_man, logo_white, panda_logo } from 'assets';
import { Button } from 'components/shared';
import { Link } from 'react-router-dom';

export default function ErrorView({ isMaintenance }: { isMaintenance?: boolean }) {
  const { isDarkMode } = useDarkMode();
  return (
    <div className={styles.errorView}>
      <div className={styles.errorViewInner}>
        <div>
          <img
            width={300}
            height={63}
            src={isDarkMode ? logo_white : panda_logo}
            alt="panda logo"
          />
        </div>
        <div>
          <img width={500} src={cosmos_man} alt="" />
        </div>
        <div className={styles.errorViewFooter}>
          {isMaintenance ? (
            <>
              <p className={styles.errorViewTitle}>Currently Under Maintenance</p>
              <p className={styles.errorViewDesc}>
                We&apos;re currently making improvements to our site to enhance your experience.
                We&apos;ll be back in approximately 30 minutes. Thank you for your patience.
              </p>
            </>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
              }}
            >
              <p className={styles.errorViewTitle}>Something went wrong</p>
              <Link to="/">
                <Button>Back</Button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
