import { notification } from 'antd';
import React, { useEffect } from 'react';
import { snackBarDispatcher, snackBarState } from './services/SnackBarSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';

const SnackBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const { message, error, open } = useAppSelector(snackBarState);
  const { onClose } = snackBarDispatcher(dispatch);

  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (open) {
      api.info({
        message: error ? 'Error' : '',
        description: message,
        placement: 'topRight',
        onClose: () => onClose(),
        duration: 3,
        icon: error ? (
          <CloseCircleFilled style={{ color: '#FF7875' }} />
        ) : (
          <CheckCircleFilled style={{ color: '#52C41A' }} />
        ),
        style: {
          backgroundColor: error ? '#FFF2F0' : '#F6FFED',
          borderColor: error ? '#FF7875' : '#52C41A',
          boxShadow: 'none',
        },
      });
    }
  }, [open, message, error, api, onClose]);

  return <>{contextHolder}</>;
};

export default SnackBar;
