import React, { useState } from 'react';
import { Checkbox, Popover, Button, Flex, Row, Col } from 'antd';
import { TCustomize } from '../../types';
import { PinIcon } from '../Icons';
import { DownOutlined } from '@ant-design/icons';
import { useToken } from 'hooks';

interface Props {
  options: TCustomize[];
  onCheck: (key: string) => void;
  onToggleFixed: (key: string) => void;
  handleSaveAsDefault?: (options: TCustomize[]) => void;
  canBeSave: boolean;
  cancelCustomizeColumns?: () => void;
}

const CheckboxMenu: React.FC<Props> = ({
  options,
  onCheck,
  onToggleFixed,
  handleSaveAsDefault,
  canBeSave,
  cancelCustomizeColumns,
}) => {
  const { paddingMD } = useToken();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const handleCheck = (key: string) => {
    onCheck(key);
  };

  const handleToggleFixed = (key: string) => {
    onToggleFixed(key);
  };

  const checkboxRender = () => (
    <Flex vertical gap={8} style={{ minWidth: 300, padding: paddingMD }}>
      {options.map((el: TCustomize) => (
        <Row key={el.value} gutter={8}>
          <Col span={12}>{el.label}</Col>
          <Col
            span={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              justifyContent: 'end',
            }}
          >
            <Checkbox checked={el.visible} onChange={() => handleCheck(el.value)} />
            Show
          </Col>
          <Col
            span={6}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
              justifyContent: 'end',
            }}
          >
            {el.fixed ? (
              <PinIcon
                style={{ cursor: 'pointer' }}
                fill={'#4E37D3'}
                onClick={() => handleToggleFixed(el.value)}
              />
            ) : (
              <PinIcon style={{ cursor: 'pointer' }} onClick={() => handleToggleFixed(el.value)} />
            )}
            Pin
          </Col>
        </Row>
      ))}
      {canBeSave && handleSaveAsDefault && (
        <Flex justify={'end'} gap={16} style={{ marginTop: 16 }}>
          <Button
            onClick={() => {
              cancelCustomizeColumns?.();
              // setIsVisible((prev) => !prev);
            }}
          >
            Cancel
          </Button>
          <Button
            type={'primary'}
            onClick={() => {
              setIsVisible((prev) => !prev);
              handleSaveAsDefault(options);
            }}
          >
            Save as default
          </Button>
        </Flex>
      )}
    </Flex>
  );

  return (
    <Popover
      content={checkboxRender}
      open={isVisible}
      onOpenChange={() => setIsVisible((prev) => !prev)}
      trigger="click"
      placement="bottomLeft"
      style={{ zIndex: 10000 }}
    >
      <Button>
        Customize columns <DownOutlined />
      </Button>
    </Popover>
  );
};

export default CheckboxMenu;
