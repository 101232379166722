import { useContext } from 'react';
import { DarkModeContextValue, DarkModeContext } from './darkMode';

export const useDarkMode = (): DarkModeContextValue => {
  const context = useContext(DarkModeContext);
  if (context === undefined) {
    throw new Error('useDarkMode must be used within a DarkModeProvider');
  }
  return context;
};
