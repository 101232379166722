import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ViewJob from '../ViewJob/ViewJob.tsx';
import CreateJob from '../CreateJob/CreateJob.tsx';
import { Button, Flex, Typography } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { deleteConvertor, PageBackIcon } from 'components';
import { useDeleteJobsMutation } from '../../services/jobsApi.ts';
import { MODALS } from '_constants';
import { useAppDispatch } from 'hooks';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice.ts';
import { RouterPaths } from 'router/constants.ts';

const JobsManagementWrapper: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const jobId = params.jobId;
  const dispatch = useAppDispatch();
  const { openModal } = modalDispatcher(dispatch);
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteJobs] = useDeleteJobsMutation();
  const view = searchParams.get('view');

  const handleChangeView = () =>
    setSearchParams((prev) => {
      if (view) {
        prev.set('view', JSON.stringify(!JSON.parse(view)));
      }
      return prev;
    });

  const deleteAction = () => {
    jobId &&
      openModal({
        modalType: MODALS.CONFIRM_MODAL,
        props: {
          title: 'Remove',
          message: 'Are you sure you want to remove this vacancy',
          action: () => deleteJobs(deleteConvertor([jobId])),
          confirmButtonText: 'Remove',
          cancelButtonText: 'Cancel',
        },
      });
  };

  const handleClickBack = () => {
    navigate(`/admin/${RouterPaths.Jobs}`);
  };

  const renderTitle = (): string => {
    if (view) {
      return JSON.parse(view) ? 'View Vacancy' : 'Edit Vacancy';
    }
    return 'Create new Vacancy';
  };

  return (
    <Flex vertical style={{ backgroundColor: '#fff', width: '100%', height: '100%', padding: 24 }}>
      <Flex gap={8} align={'center'} justify={'space-between'}>
        <Flex gap={8}>
          <PageBackIcon style={{ cursor: 'pointer' }} onClick={handleClickBack} />
          <Typography>{renderTitle()}</Typography>
        </Flex>
        {jobId && view && (
          <Flex gap={8}>
            <Button type={'text'} onClick={handleChangeView}>
              <EditOutlined /> {JSON.parse(view) ? 'Edit' : 'View'}
            </Button>
            <Button type={'text'} onClick={deleteAction}>
              <DeleteOutlined /> Remove
            </Button>
          </Flex>
        )}
      </Flex>
      {view && JSON.parse(view) ? <ViewJob /> : <CreateJob />}
    </Flex>
  );
};

export default JobsManagementWrapper;
