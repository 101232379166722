import React from 'react';
import { Col, Flex, Row, Typography } from 'antd';
import { useToken } from 'hooks';
import { useParams } from 'react-router-dom';
import { useGetJobByIdQuery } from '../../services/jobsApi.ts';
import { Chip } from '../../../../components';
import { jobType, locationColor, locationText, typeText } from '../../../../_constants';

const ViewJob: React.FC = () => {
  const { gradient1, fontSize16, paddingSM } = useToken();
  const params = useParams();
  const { data: jobData } = useGetJobByIdQuery(params.jobId as string);

  return (
    <Flex vertical style={{ maxWidth: '846px', paddingTop: paddingSM, paddingBottom: 48 }}>
      <Typography style={{ fontSize: fontSize16, fontWeight: 600 }}>Short Description</Typography>
      <Typography.Text style={{ paddingTop: paddingSM }}>
        {jobData?.shortDescription}
      </Typography.Text>
      {jobData ? (
        <Row gutter={16} style={{ marginTop: 24 }}>
          {Object.values(JSON.parse(jobData.content) as Record<string, string>).map(
            (el: string, index: number) => (
              <Col span={24} key={index}>
                <div style={{ position: 'relative' }} dangerouslySetInnerHTML={{ __html: el }} />
                {index === 0 && (
                  <Flex gap={8} style={{ position: 'absolute', top: 0, right: 0 }}>
                    <Chip
                      title={typeText[jobData.type as keyof typeof typeText]}
                      color={jobType[jobData.type as keyof typeof jobType]}
                    />
                    <Chip
                      title={locationText[jobData.location as keyof typeof locationText]}
                      color={locationColor[jobData.location as keyof typeof locationColor]}
                    />
                  </Flex>
                )}
                <div
                  style={{
                    width: '100%',
                    height: 1,
                    margin: '16px 0',
                    borderRadius: 16,
                    background: gradient1,
                  }}
                ></div>
              </Col>
            ),
          )}
        </Row>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default ViewJob;
