import { info_image } from 'assets';
import {
  AboutUs,
  Solutions,
  Technologies,
  Values,
  HelpSection,
  HeroSection,
  JoinUs,
  InfoSection,
} from './components';
import useWindowSize from 'hooks/useWindowSize';

export default function Landing() {
  const { isMobile } = useWindowSize();
  return (
    <div>
      <HeroSection title="BUSINESS" subtitle="Designing your" />
      <div
        style={
          {
            // marginTop: '128px',
          }
        }
      >
        <Solutions />
      </div>
      <div
        style={{
          marginTop: isMobile ? '48px' : '128px',
        }}
      >
        <Technologies />
      </div>
      <div>
        <InfoSection
          image={info_image}
          title={'Empowering Businesses Through Digital Innovation'}
          description="We are committed to crafting digital products that propel your business towards success. Leveraging our expertise in UI/UX design, web development, and mobile development, we specialize in creating innovative solutions that are custom-tailored to your unique needs. Trust in our ability to deliver exceptional results that will drive your business forward and leave a lasting impact."
        />
      </div>
      <Values />
      <AboutUs />
      <div
        style={{
          marginTop: isMobile ? '48px' : '128px',
        }}
      >
        <JoinUs />
      </div>
      <div
        style={{
          marginTop: isMobile ? '48px' : '128px',
        }}
      >
        <HelpSection />
      </div>
    </div>
  );
}
