import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
    <circle cx={12} cy={12} r={12} fill="#95DE64" />
    <path
      fill="#fff"
      d="M17 12.713h-4.298V17h-1.415v-4.287H7v-1.426h4.287V7h1.415v4.287H17v1.426Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
