import React from 'react';
import { CheckboxOptionType, TableColumnsType, TableProps } from 'antd';
import { ExportTypesEnum, TABLE_KEY_ENUM } from '../constants';
import { DragEndEvent, DragOverEvent, UniqueIdentifier } from '@dnd-kit/core';
import { tableReducerActions } from '../reducer/tableReducer';
import { SortOrder } from 'antd/es/table/interface';

export interface IDeleteArgs<T> {
  filters: string;
  tableKey?: TABLE_KEY_ENUM;
  originalData?: T[];
  isCheckAll?: boolean;
}

export interface ITableArgs<T> {
  columns: TableColumnsType<T>;
  total?: number;
  tableKey: TABLE_KEY_ENUM;
  deleteAction?: (deleteArgs: IDeleteArgs<T>) => void;
  editAction?: (value: T) => void;
}

export type TCustomize = {
  visible: boolean;
  fixed: boolean;
} & CheckboxOptionType;

export interface ITableReducer<T> {
  checkedItems: T[];
  checkedItemsIds: React.Key[];
  selectedAll: boolean;
  pageSize: number;
  page: number;
  customizeColumns: TCustomize[];
  isCheckAll: boolean;
  onEdit?: (value: T) => void;
  onDelete?: () => void;
  onCheckColumn?: (id: string) => void;
  onColumnFix?: (id: string) => void;
  handleExport?: (type: ExportTypesEnum) => void;
  tableColumns: TableColumnsType<T>;
  handleClearAllFilters?: () => void;
  handleSaveAsDefault?: () => void;
  configDataFetching?: boolean;
  cancelCustomizeColumns?: () => void;
}

export interface ITableDND {
  onDragEnd: (data: DragEndEvent) => void;
  onDragOver: (data: DragOverEvent) => void;
  sensors: any;
  dragIndex: DragIndexState;
}

export interface ITable<T> extends TableProps {
  customProps: ITableReducer<T>;
  dnd: ITableDND;
}

export type ITableActionType<T> =
  | {
      type: tableReducerActions.SET_CHECKED_ITEMS;
      payload: T[];
    }
  | {
      type: tableReducerActions.SET_CHECKED_ITEMS_IDS;
      payload: React.Key[];
    }
  | {
      type: tableReducerActions.SET_SELECTED_ALL;
      payload: boolean;
    }
  | {
      type: tableReducerActions.SET_PAGE;
      payload: number;
    }
  | {
      type: tableReducerActions.SET_PAGE_SIZE;
      payload: number;
    }
  | {
      type: tableReducerActions.ON_SHOW_COLUMN;
      payload: string;
    }
  | {
      type: tableReducerActions.DRAG_END_EVENT;
      payload: DragEndEvent;
    }
  | {
      type: tableReducerActions.DRAG_OVER_EVENT;
      payload: DragOverEvent;
    }
  | {
      type: tableReducerActions.TOGGLE_FIX_COLUMN;
      payload: string;
    }
  | {
      type: tableReducerActions.SET_TABLE_COLUMNS;
      payload: TableColumnsType<T>;
    }
  | {
      type: tableReducerActions.SET_DEFAULT_CUSTOMIZE;
      payload: string;
    }
  | {
      type: tableReducerActions.TOGGLE_CHECK_ALL;
      payload: boolean;
    };

//////////////////////// DND types

export interface DragIndexState {
  active: UniqueIdentifier;
  over: UniqueIdentifier | undefined;
  direction?: 'left' | 'right';
}

export interface HeaderCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  id: string;
}

export interface BodyCellProps extends React.HTMLAttributes<HTMLTableCellElement> {
  id: string;
}

///////////////////////////

/////////// Filters

export enum ConditionalOperatorsEnums {
  Equal = '=',
  NotEqual = '!=',
  LessThan = '<',
  GreaterThan = '>',
  GreaterThanOrEqual = '>=',
  LessThanOrEqual = '<=',
  Contains = '=*',
  NotContains = '!*',
  StartsWith = '^',
  NotStartsWith = '!^',
  EndsWith = '$',
  NotEndsWith = '!$',
}

export enum FilterTypesEnum {
  ENCRYPTED_DATA = 'EncryptedData',
  BASE_36_ID = 'Base36Id',
  NUMBER = 'Number',
  TEXT = 'Text',
  CURRENCY = 'Currency',
  DATE = 'Date',
  BOOLEAN = 'Boolean',
  PERCENTAGE = 'Percentage',
  TAGS = 'Tags',
  NUMBER_COLLECTION = 'NumberCollection',
  TEXT_COLLECTION = 'TextCollection',
}

export enum ComparisonTypesEnum {
  EQUAL = 'Equal',
  NOT_EQUAL = 'NotEqual',
  GREATER_THAN = 'GreaterThan',
  GREATER_THAN_OR_EQUAL = 'GreaterThanOrEqual',
  LESS_THAN = 'LessThan',
  LESS_THAN_OR_EQUAL = 'LessThanOrEqual',
  BETWEEN = 'Between',
  STARTS_WITH = 'StartsWith',
  ENDS_WITH = 'EndsWith',
  CONTAINS = 'Contains',
  NOT_CONTAINS = 'NotContains',
  IN = 'In',
}

export enum FilterTypesUiHelper {
  'Equal' = '=',
  'NotEqual' = '!=',
  'Contains' = 'Contains',
  'NotContains' = 'Not Contains',
  'StartsWith' = 'Starts with',
  'EndsWith' = 'Ends with',
  'Between' = 'Between',
  'GreaterThan' = '>',
  'GreaterThanOrEqual' = '>=',
  'LessThan' = '<',
  'LessThanOrEqual' = '<=',
  'In' = 'In',
}

export interface IItemFields {
  PropertyName: string;
  Values: any[];
  ComparisonType: ComparisonTypesEnum;
  TypeForUi: string;
  Search: string | number;
  CheckedItems: any[];
  ColumnType: FilterTypesEnum;
}

export interface IColumnsConstantsDefault {
  initialFiltered: string[];
  initialOrders: { [key: string]: SortOrder | undefined };
}

export enum AggregatesEnum {
  TOTAL = 'Total',
  UNIQUE_COUNT = 'Total Unique Count',
  AVERAGE = 'Total AVG',
  MIN = 'Total MIN',
  MAX = 'Total MAX',
  SUM = 'Total SUM',
}

export type IFilterHelper = {
  [key in FilterTypesEnum]: {
    ColumnType: FilterTypesEnum;
    AggregateTypes: AggregatesEnum[];
    FilterTypes: ComparisonTypesEnum[];
    DefaultSearchType: ComparisonTypesEnum;
    CanBeSorted: boolean;
  };
};
