import { solutionCardContent } from 'features/landing/constants';
import styles from './Solutions.module.scss';
import { Container, ServiceCard } from 'components';
import { useDarkMode } from 'context';

export default function Solutions() {
  const { isDarkMode } = useDarkMode();

  return (
    <section className={styles.container}>
      <Container>
        <h1 className={styles.title}>End-to-End Software Development Solutions</h1>
        <p className={styles.description}>
          From conceptualization to launch and beyond, we provide a fully-functional and scalable
          solution tailored to your specific requirements. Our services extend to providing solution
          architecture after feasibility studies, comprehensive documentation of the application
          after deployment, and ongoing maintenance. You can rest assured that we are committed to
          delivering a seamless experience, from start to finish.
        </p>
        <div className={styles.content}>
          {solutionCardContent(!isDarkMode).map((item) => (
            <ServiceCard
              key={item.id}
              title={item.title}
              description={item.description}
              image={item.imageUrl}
            />
          ))}
        </div>
      </Container>
    </section>
  );
}
