import { Grid } from 'components';
import styles from './Team.module.scss';

export default function Team() {
  return (
    <section className={styles.team}>
      <div className={styles.teamContainer}>
        <h1>Faces of Success: Meet Our Exceptional Team</h1>
        <p>
          Behind every remarkable achievement at PandaTech is a dedicated team of talented
          individuals. Get to know the brilliant minds driving our success as we proudly introduce
          you to our exceptional team. From innovative thinkers to collaborative problem solvers,
          each member brings their unique skills and passion to the table. Take a glimpse into our
          vibrant work culture and see the smiling faces that make PandaTech a truly extraordinary
          place to be.
        </p>
        <div className={styles.gridContent}>
          <Grid />
        </div>
      </div>
    </section>
  );
}
