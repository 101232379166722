import useWindowSize from 'hooks/useWindowSize';
import { JoinUs, TechnologiesPreview } from './components';

export default function TechnologiesLanding() {
  const { isMobile } = useWindowSize();
  return (
    <div>
      <TechnologiesPreview />
      <div
        style={{
          margin: !isMobile ? '128px 0' : '0',
        }}
      >
        <div
          style={{
            paddingBottom: isMobile ? '48px' : '0',
          }}
        >
          <JoinUs />
        </div>
      </div>
    </div>
  );
}
