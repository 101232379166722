import { teamCardContent } from 'features/landing/constants';
import styles from './TeamDescription.module.scss';
import { DescriptionCard } from 'components';
export default function TeamDescription() {
  return (
    <section>
      <div className={styles.teamDescription}>
        {teamCardContent.map((card) => (
          <DescriptionCard key={card.title} title={card.title} description={card.description} />
        ))}
      </div>
    </section>
  );
}
