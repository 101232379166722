import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none" {...props}>
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <path
          fill="#656A81"
          d="M1 9.625A2.625 2.625 0 0 1 3.625 7h15.75A2.625 2.625 0 0 1 22 9.625v10.5a2.625 2.625 0 0 1-2.625 2.625H3.625A2.625 2.625 0 0 1 1 20.125v-10.5Zm2.625-1.313a1.313 1.313 0 0 0-1.313 1.313v.285l9.188 5.512 9.188-5.512v-.285a1.313 1.313 0 0 0-1.313-1.313H3.625Zm17.063 3.128-6.245 3.747 6.245 3.775V11.44Zm-.045 9.028-7.475-4.517-1.668 1.002-1.67-1.002-7.473 4.515a1.312 1.312 0 0 0 1.268.971h15.75a1.312 1.312 0 0 0 1.268-.97Zm-18.33-1.506 6.244-3.775-6.245-3.747V18.962Z"
        />
      </g>
      <circle cx={21} cy={8} r={4.5} fill="#656A81" stroke="#F7F6FB" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h25v25H0z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M1 7h21v16H1z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
