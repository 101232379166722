import {
  ComparisonTypesEnum,
  ConditionalOperatorsEnums,
  FilterTypesEnum,
  IColumnsConstantsDefault,
  IItemFields,
} from '../types';
import { TBase36Id } from 'types';

export const filtersConvertor = (filterData: IItemFields[] = []) => {
  const regex = /([(),|\\]|\[\/i)/g;
  const returnType = (item: any) => {
    if (typeof item === 'number' || typeof item === 'boolean') {
      return item;
    } else if (typeof item === 'string') {
      return item.replace(regex, '\\$1');
    } else {
      return item === null ? item : item.toISOString();
    }
  };

  const generateFilterString = (filter: IItemFields) => {
    let filterString = '';
    switch (filter.ComparisonType) {
      case ComparisonTypesEnum.BETWEEN:
        {
          const from =
            filter.PropertyName +
            ConditionalOperatorsEnums.GreaterThanOrEqual +
            returnType(filter.Values[0]);
          const to =
            filter.PropertyName +
            ConditionalOperatorsEnums.LessThanOrEqual +
            returnType(filter.Values[1] || filter.Values[0]);

          filterString = `${from},${to}`;
        }
        break;
      case ComparisonTypesEnum.STARTS_WITH:
      case ComparisonTypesEnum.ENDS_WITH:
        {
          let conditionOperators =
            ConditionalOperatorsEnums[filter.TypeForUi as keyof typeof ConditionalOperatorsEnums];
          if (filter.ColumnType === FilterTypesEnum.TAGS) {
            conditionOperators = ConditionalOperatorsEnums.Equal;
          }
          const filterValues = filter.Values.map((item) => {
            return filter.PropertyName + conditionOperators + returnType(item);
          });

          filterString = `(${filterValues.join('|')})`;
        }
        break;
      case ComparisonTypesEnum.NOT_EQUAL:
        {
          let conditionOperators =
            ConditionalOperatorsEnums[filter.TypeForUi as keyof typeof ConditionalOperatorsEnums];
          if (filter.ColumnType === FilterTypesEnum.TAGS) {
            conditionOperators = ConditionalOperatorsEnums.Equal;
          }
          const filterValues = filter.Values.map((item) => {
            return filter.PropertyName + conditionOperators + returnType(item);
          });
          filterString = filterValues.join(',');
        }
        break;
      case ComparisonTypesEnum.IN:
      case ComparisonTypesEnum.EQUAL:
        {
          let conditionOperators =
            ConditionalOperatorsEnums[filter.TypeForUi as keyof typeof ConditionalOperatorsEnums];
          if (filter.ColumnType === FilterTypesEnum.TAGS) {
            conditionOperators = ConditionalOperatorsEnums.Equal;
          }
          const filterValues = filter.Values.map((item) => {
            return filter.PropertyName + conditionOperators + returnType(item);
          });
          filterString =
            filter.ColumnType === FilterTypesEnum.TAGS
              ? filterValues.join(',')
              : `(${filterValues.join('|')})`;
        }
        break;
      default:
        filterString = `${filter.PropertyName}${
          ConditionalOperatorsEnums[filter.ComparisonType as keyof typeof ConditionalOperatorsEnums]
        }${returnType(filter.Values[0])}`;
        break;
    }
    return filterString;
  };

  const filters = filterData.map(generateFilterString);

  const combinedFilterString = filters.join(',');
  const output = {
    page: 2,
    pageSize: 10,
    orderBy: 'name desc',
    filter: `${combinedFilterString}`,
  };
  return output.filter;
};

export const detectComparisonTypeForSearch = (type: FilterTypesEnum): ComparisonTypesEnum => {
  switch (type) {
    case FilterTypesEnum.BASE_36_ID:
    case FilterTypesEnum.ENCRYPTED_DATA:
    case FilterTypesEnum.NUMBER:
      return ComparisonTypesEnum.IN;
    case FilterTypesEnum.TEXT:
      return ComparisonTypesEnum.CONTAINS;
    default:
      return ComparisonTypesEnum.CONTAINS;
  }
};

export const deleteConvertor = (values: TBase36Id[]): string => {
  return filtersConvertor([
    {
      CheckedItems: values,
      Values: values,
      ColumnType: FilterTypesEnum.BASE_36_ID,
      ComparisonType: ComparisonTypesEnum.EQUAL,
      Search: '',
      TypeForUi: 'Equal',
      PropertyName: 'Id',
    },
  ]);
};

export const orderBy = (paramsOrderBy: string | null) => {
  if (paramsOrderBy) {
    const orderData = JSON.parse(paramsOrderBy);
    return `&OrderBy=${Object.keys(orderData).map(
      (el) => `${el} ${orderData[el] === 'descend' ? 'desc' : ''}`,
    )}`;
  }
  return '';
};

export const getDefaultFiltersAndOrders = (
  paramsFilters: null | string,
  paramsOrderBy: null | string,
): IColumnsConstantsDefault => {
  return {
    initialFiltered: paramsFilters
      ? JSON.parse(paramsFilters).map((el: IItemFields) => el.PropertyName)
      : [],
    initialOrders: paramsOrderBy ? JSON.parse(paramsOrderBy) : {},
  };
};
