import React, { CSSProperties, ReactNode } from 'react';
import { Flex } from 'antd';

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

const PagesWrapper: React.FC<Props> = ({ children, style }) => {
  return (
    <Flex
      vertical
      gap={48}
      style={{ padding: 24, backgroundColor: '#fff', height: '100%', ...style }}
    >
      {children}
    </Flex>
  );
};

export default PagesWrapper;
