import { api } from 'api';
import { ICreateNewJob, IEditJob, IGetAllJobs, IGetJobById } from '../types/types.ts';
import { jobsApiRoutes } from '../constants';
import { IRequestData, ITableResponse, TBase36Id } from '../../../types';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice.ts';
import { MODALS } from '_constants';
import { openSuccessSnackBar } from 'components/ui/SnackBar/services/SnackBarSlice.ts';

const withTags = api.enhanceEndpoints({
  addTagTypes: ['Jobs', 'JobById'],
});

export const jobsApi = withTags.injectEndpoints({
  endpoints: (builder) => ({
    getAllJobs: builder.query<ITableResponse<IGetAllJobs>, IRequestData>({
      query: (params) => ({
        url: jobsApiRoutes.JOBS,
        params,
      }),
      providesTags: ['Jobs'],
    }),
    getJobById: builder.query<IGetJobById, TBase36Id>({
      query: (id) => ({
        url: jobsApiRoutes.JOB_BY_ID(id),
      }),
      providesTags: ['JobById'],
    }),
    createJob: builder.mutation<IGetJobById, ICreateNewJob>({
      query: (body) => ({
        url: jobsApiRoutes.JOBS,
        method: 'POST',
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            dispatch(openSuccessSnackBar({ message: 'Job has been created successfully' }));
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Jobs']),
    }),
    editJob: builder.mutation<IGetJobById, IEditJob>({
      query: ({ id, ...body }) => ({
        url: jobsApiRoutes.JOB_BY_ID(id),
        method: 'PATCH',
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            dispatch(openSuccessSnackBar({ message: 'Job has been edited successfully' }));
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Jobs', 'JobById']),
    }),
    deleteJobs: builder.mutation<void, string>({
      query: (filter) => ({
        url: jobsApiRoutes.JOBS,
        method: 'DELETE',
        body: { filter },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((res) => {
            if ('data' in res) {
              const { closeModal } = modalDispatcher(dispatch);
              closeModal({ modalType: MODALS.CONFIRM_MODAL });
              dispatch(openSuccessSnackBar({ message: 'Job deleted successfully' }));
            }
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Jobs']),
    }),
  }),
});

export const {
  useGetAllJobsQuery,
  useCreateJobMutation,
  useGetJobByIdQuery,
  useEditJobMutation,
  useDeleteJobsMutation,
} = jobsApi;
