interface CardProps {
  title: string;
  description: string;
}

export default function Card({ title, description }: CardProps): JSX.Element {
  return (
    <div className="card">
      <div className="front">
        <h1 className="animated-header title">{title}</h1>
        <p className="description">{description}</p>
      </div>
    </div>
  );
}
