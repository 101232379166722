import { api } from 'api';
import { userApiRoutes } from '../constants';
import { ICreateUser, IEditUser, IGetUsers } from '../types';
import { IRequestData, ITableResponse, TBase36Id } from 'types';
import { MODALS } from '_constants';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice.ts';
import { openSuccessSnackBar } from 'components/ui/SnackBar/services/SnackBarSlice.ts';

const apiWithTags = api.enhanceEndpoints({
  addTagTypes: ['Users', 'UserById'],
});

export const usersApi = apiWithTags.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<ITableResponse<IGetUsers>, IRequestData>({
      query: (params) => ({
        url: userApiRoutes.USERS,
        params,
      }),
      providesTags: ['Users'],
    }),
    getUserById: builder.query<IGetUsers, TBase36Id>({
      query: (id) => ({
        url: userApiRoutes.USER_BY_ID(id),
      }),
      providesTags: ['UserById'],
    }),
    createUser: builder.mutation<IGetUsers, ICreateUser>({
      query: (body) => ({
        url: userApiRoutes.USERS,
        method: 'POST',
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((res) => {
            if ('data' in res) {
              const { closeModal } = modalDispatcher(dispatch);
              closeModal({ modalType: MODALS.USERS_MODAL });
              dispatch(openSuccessSnackBar({ message: 'User created successfully' }));
            }
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Users']),
    }),
    editUser: builder.mutation<IGetUsers, IEditUser>({
      query: ({ id, ...body }) => ({
        url: userApiRoutes.USER_BY_ID(id),
        method: 'PUT',
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((res) => {
            if ('data' in res) {
              const { closeModal } = modalDispatcher(dispatch);
              closeModal({ modalType: MODALS.USERS_MODAL });
              dispatch(openSuccessSnackBar({ message: 'User edited successfully' }));
            }
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Users', 'UserById']),
    }),
    changeUserStatus: builder.mutation<void, { status: number; id: TBase36Id }>({
      query: ({ status, id }) => ({
        url: userApiRoutes.USER_STATUS(id),
        method: 'PATCH',
        body: { status },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((res) => {
            if ('data' in res) {
              const { closeModal } = modalDispatcher(dispatch);
              closeModal({ modalType: MODALS.CONFIRM_MODAL });
              dispatch(openSuccessSnackBar({ message: 'User status changed successfully' }));
            }
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Users', 'UserById']),
    }),
    changeUserPassword: builder.mutation<void, { id: TBase36Id; newPassword: string }>({
      query: ({ id, newPassword }) => ({
        url: userApiRoutes.USER_PASSWORD(id),
        method: 'PATCH',
        body: { newPassword },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((res) => {
            if ('data' in res) {
              const { closeModal } = modalDispatcher(dispatch);
              closeModal({ modalType: MODALS.USERS_MODAL });
              dispatch(openSuccessSnackBar({ message: 'User password changed successfully' }));
            }
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Users', 'UserById']),
    }),
    deleteUsers: builder.mutation<void, string>({
      query: (filter) => ({
        url: userApiRoutes.USERS,
        method: 'DELETE',
        body: { filter },
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((res) => {
            if ('data' in res) {
              const { closeModal } = modalDispatcher(dispatch);
              closeModal({ modalType: MODALS.CONFIRM_MODAL });
              dispatch(openSuccessSnackBar({ message: 'User deleted successfully' }));
            }
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['Users']),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useCreateUserMutation,
  useDeleteUsersMutation,
  useGetUserByIdQuery,
  useEditUserMutation,
  useChangeUserPasswordMutation,
} = usersApi;
