import { Progress } from 'antd';
import { ProgressProps } from 'antd/lib';
import { useState, useEffect } from 'react';

const twoColors: ProgressProps['strokeColor'] = {
  '0%': '#4e37d3',
  '100%': '#3ecc99',
};
export default function LinearDeterminate({ setIsVisible }: { setIsVisible?: () => void }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    if (progress === 100) {
      setIsVisible && setIsVisible();
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [progress, setIsVisible]);

  return (
    <div
      style={{
        position: 'fixed',
        top: '72px',
        left: '0',
        width: '100%',
        zIndex: 9999,
      }}
    >
      <Progress percent={progress} showInfo={false} strokeColor={twoColors} size={{ height: 3 }} />
    </div>
  );
}
