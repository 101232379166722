import { panda_icon_small, panda_logo } from '../../assets';
import { Layout, Menu } from 'antd';
import React from 'react';
import { useStyles } from '../AdminLayout/useStyles.ts';
import { NAVIGATION_ITEMS, TABLE_ROUTES } from './constants.tsx';
import { NavLink, useNavigate } from 'react-router-dom';
import { RouterPaths } from 'router/constants.ts';

const { Sider } = Layout;

interface Props {
  toggleCollapsed: (value: boolean) => void;
  collapsed: boolean;
}

const Navigation: React.FC<Props> = ({ collapsed, toggleCollapsed }) => {
  const navigate = useNavigate();
  const { siderStyles } = useStyles();

  const navigationLinkConvertor = (key: string) => {
    return `${key}${TABLE_ROUTES.includes(key) ? '?pageSize=10&page=1' : ''}`;
  };

  return (
    <Sider
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => toggleCollapsed(value)}
      style={siderStyles}
    >
      <div
        style={{
          padding: 17,
          height: 76,
          cursor: 'pointer',
        }}
        onClick={() => navigate(RouterPaths.Welcome)}
      >
        <img src={collapsed ? panda_icon_small : panda_logo} alt="logo" />
      </div>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={['main']}
        defaultOpenKeys={[]}
        items={NAVIGATION_ITEMS.map((el: any) => ({
          ...el,
          label: el.label,
          children: el.children.map((item: any) => ({
            ...item,
            label: <NavLink to={navigationLinkConvertor(item.key)}>{item.label}</NavLink>,
          })),
        }))}
      />
    </Sider>
  );
};

export default Navigation;
