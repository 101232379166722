import { SVGProps, memo } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={72}
    fill="none"
    {...props}
  >
    <path
      fill="var(--icon-color)"
      d="M68.856 40.751h-2.232V6.273a3.962 3.962 0 0 0-3.958-3.957H9.333a3.962 3.962 0 0 0-3.957 3.957v34.478H3.144a.772.772 0 0 0-.771.772V46.2a4.624 4.624 0 0 0 4.62 4.62h17.87v2.207a2.21 2.21 0 0 1-2.208 2.207h-4.8a3.754 3.754 0 0 0-3.75 3.75 3.755 3.755 0 0 0 3.75 3.75H33.45c.411 4.155 3.882 6.948 7.664 6.948h5.41c4.256 0 7.718-3.463 7.718-7.719 0-4.255-3.462-7.717-7.719-7.717h-5.409a7.723 7.723 0 0 0-7.68 6.946H17.855a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.208h4.8a3.754 3.754 0 0 0 3.75-3.75v-2.207h38.604a4.624 4.624 0 0 0 4.618-4.62v-4.678a.77.77 0 0 0-.77-.772Zm-27.742 15.04h5.41a6.182 6.182 0 0 1 6.175 6.174 6.182 6.182 0 0 1-6.176 6.176h-5.41c-.452 0-.895-.052-1.328-.146 1.978-1.577 2.94-3.741 2.94-6.029 0-2.398-1.1-4.583-2.929-6.03.43-.095.87-.145 1.318-.145Zm-3.035.82a6.14 6.14 0 0 1 3.103 5.354 6.174 6.174 0 0 1-3.108 5.36 6.126 6.126 0 0 1-3.136-5.36c.001-2.113 1.058-4.166 3.14-5.354ZM6.92 6.273A2.417 2.417 0 0 1 9.334 3.86h53.334a2.417 2.417 0 0 1 2.415 2.414v34.478H6.92V6.273Zm34.67 36.024v1.395a.547.547 0 0 1-.547.546H30.959a.547.547 0 0 1-.547-.546v-1.395h11.177ZM68.083 46.2a3.08 3.08 0 0 1-3.075 3.077H6.992A3.08 3.08 0 0 1 3.916 46.2v-3.907l24.953.003v1.395c0 1.152.938 2.089 2.09 2.089h10.084a2.091 2.091 0 0 0 2.089-2.09v-1.394l24.953-.003V46.2Z"
    />
    <path
      fill="var(--icon-color)"
      d="M59.349 7.824H44.633a1.915 1.915 0 0 0-1.912 1.914v10.751c0 1.055.858 1.913 1.912 1.913h14.716a1.915 1.915 0 0 0 1.912-1.913V9.738a1.914 1.914 0 0 0-1.912-1.914Zm.37 12.665a.37.37 0 0 1-.37.37H44.634a.37.37 0 0 1-.37-.37V9.738a.37.37 0 0 1 .37-.37h14.716a.37.37 0 0 1 .369.37v10.751h.001Zm-48.27-9.962h2.921a.772.772 0 0 0 0-1.542h-2.92a.772.772 0 0 0 0 1.543Zm0 5.021h2.921a.772.772 0 0 0 0-1.543h-2.92a.772.772 0 0 0 0 1.543Zm7.43-5.02h11.725a.772.772 0 0 0 0-1.543H18.878a.772.772 0 0 0 0 1.543Zm15.342 0h3.617a.772.772 0 0 0 0-1.543h-3.617a.772.772 0 0 0 0 1.543Zm-15.342 5.02H21.5a.772.772 0 0 0 0-1.543h-2.622a.772.772 0 0 0 0 1.543Zm19.73-.771a.772.772 0 0 0-.772-.772H25.022a.772.772 0 0 0 0 1.543h12.815a.772.772 0 0 0 .772-.771ZM15.83 19.798H12.91a.772.772 0 0 0 0 1.543h2.92a.772.772 0 0 0 0-1.543Zm0 5.02H12.91a.772.772 0 0 0 0 1.542h2.92a.772.772 0 0 0 0-1.543Zm15.341-4.248a.772.772 0 0 0-.771-.772H20.339a.772.772 0 0 0 0 1.543h10.062a.772.772 0 0 0 .771-.771Zm8.126-.772h-4.036a.772.772 0 0 0 0 1.543h4.036a.772.772 0 0 0 0-1.543Zm-18.96 5.02a.772.772 0 0 0 0 1.542h2.957a.772.772 0 0 0 0-1.543h-2.957Zm18.96 0H27.45a.772.772 0 0 0 0 1.542h11.848a.772.772 0 1 0-.001-1.543Zm-25.265 5.338h-2.921a.772.772 0 0 0 0 1.543h2.92a.772.772 0 0 0 0-1.543Zm0 5.02h-2.921a.772.772 0 0 0 0 1.543h2.92a.772.772 0 0 0 0-1.543ZM18.54 31.7h1.798a.772.772 0 0 0 0-1.543H18.54a.772.772 0 0 0 0 1.543Zm18.96-1.543H25.206a.772.772 0 0 0 0 1.543H37.5a.772.772 0 0 0 0-1.543Zm0 5.02H18.54a.772.772 0 0 0 0 1.543H37.5a.772.772 0 0 0 0-1.543Zm16.154-5.02H44.48a.772.772 0 0 0 0 1.543h9.175a.772.772 0 0 0 0-1.543Zm-4.319 5.02H44.48a.772.772 0 0 0 0 1.543h4.856a.772.772 0 0 0 0-1.543Z"
    />
    <path
      fill="var(--icon-color)"
      d="M54.84 11.076a.771.771 0 0 0-1.077.175l-4.921 6.824a.772.772 0 0 0 1.252.902l4.921-6.825a.772.772 0 0 0-.175-1.076Zm1.376 3.555a.772.772 0 1 0-1.092 1.092l1.053 1.053-1.053 1.051a.774.774 0 0 0 0 1.092.774.774 0 0 0 1.092.002l1.6-1.598a.772.772 0 0 0 0-1.091l-1.6-1.6Zm-7.36-.278-1.053-1.051 1.053-1.054a.772.772 0 1 0-1.091-1.091l-1.6 1.6a.772.772 0 0 0 0 1.092l1.6 1.597c.302.3.79.3 1.092-.001.3-.303.3-.791 0-1.092ZM37.234 62.737h1.652a.772.772 0 0 0 0-1.543h-1.652a.772.772 0 0 0 0 1.543Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
