import { Container, Ellipse, ValuesCard } from 'components';
import styles from './Values.module.scss';
import { useDarkMode } from 'context';
import { valuesCardContent } from 'features/landing/constants';
import useWindowSize from 'hooks/useWindowSize';
import image from 'assets/values_bg.png';

export default function Values() {
  const { isMobile } = useWindowSize();
  const { isDarkMode } = useDarkMode();
  // console.log(isMobile);
  return (
    <section
      className={styles.container}
      style={
        isMobile
          ? {
              backgroundImage: `url(${image})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }
          : {}
      }
    >
      <Container className={styles.values}>
        <h1 className={styles.title}>Our Values</h1>
        <div className={styles.cardsWrapper}>
          {valuesCardContent(!isDarkMode).map((item) => (
            <ValuesCard
              title={item.title}
              key={item.id}
              icon={item.imageUrl}
              description={item.description}
            />
          ))}
        </div>
      </Container>

      {isMobile ? (
        <>
          {/* <Ellipse color="rgba(255, 0, 168, 0.32)" width={200} height={200} left={0} top={0} />
          <Ellipse color="rgba(255, 177, 51, 0.24)" width={150} height={150} left={20} top={300} />
          <Ellipse color="rgba(78, 55, 211, 0.48)" width={200} height={200} right={0} bottom={0} /> */}
        </>
      ) : (
        <>
          <Ellipse
            color="rgba(255, 177, 51, 0.24)"
            width={299}
            height={299}
            left={34}
            bottom={62}
          />
          <Ellipse
            color="rgba(255, 0, 168, 0.32)"
            width={isMobile ? 250 : 523}
            height={478}
            left={0}
            top={isMobile ? 900 : 70}
          />
          <Ellipse color="rgba(78, 55, 211, 0.48)" width={528} height={528} right={192} top={58} />
          <Ellipse
            color="rgba(62, 204, 153, 0.56)"
            width={299}
            height={299}
            right={135}
            top={237}
          />
        </>
      )}
    </section>
  );
}
