import React, { useEffect } from 'react';
import { ComparisonTypesEnum, FilterTypesEnum, IItemFields } from '../../types';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from 'antd';
import { useSearchParams } from 'react-router-dom';

interface Props {
  type: FilterTypesEnum.DATE;
  propertyName: string;
  comparisonType: string;
  handleSelected: (data: string[]) => void;
  selected: (string | Dayjs)[];
  handleChangeComparisonType: (value: ComparisonTypesEnum) => void;
}

const DatePickerFilter: React.FC<Props> = ({
  handleSelected,
  propertyName,
  selected,
  comparisonType,
  handleChangeComparisonType,
}) => {
  const [searchParams] = useSearchParams();
  const paramsFilters = searchParams.get('filters');

  const handleChangeDate = (date: (Dayjs | null)[] | null) => {
    if (!paramsFilters) {
      handleSelected([]);
    }
    if (date) {
      handleSelected(date.map((el) => (el ? el.toISOString() : new Date().toISOString())));
    } else {
      handleSelected([]);
    }
  };

  useEffect(() => {
    if (!paramsFilters) {
      handleSelected([]);
    }
    if (paramsFilters) {
      const filters: IItemFields[] = JSON.parse(paramsFilters);
      const findProperty = filters.find((el: IItemFields) => el.PropertyName === propertyName);
      if (findProperty) {
        handleSelected(findProperty.CheckedItems);
        handleChangeComparisonType(findProperty.ComparisonType as ComparisonTypesEnum);
      }
    }
  }, [paramsFilters]);

  return (
    <>
      {comparisonType === 'Between' ? (
        <DatePicker.RangePicker
          onChange={(date) => {
            handleChangeDate(date);
          }}
          showTime
          style={{ width: '100%' }}
          value={selected.length ? [dayjs(selected[0]), dayjs(selected[1])] : null}
        />
      ) : (
        <DatePicker
          onChange={(date) => {
            handleChangeDate(!date ? date : [date]);
          }}
          showTime
          style={{ width: '100%' }}
          value={selected[0] ? dayjs(selected[0]) : null}
        />
      )}
    </>
  );
};

export default DatePickerFilter;
