import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'1em'}
    height={'1em'}
    viewBox="0 0 9 9"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M2.52 2.312a.416.416 0 0 0 0-.59l-.159-.159a.416.416 0 0 0-.587.59l.158.158a.416.416 0 0 0 .588 0Zm-.853 2.48a.417.417 0 0 0-.417-.417H.834a.417.417 0 0 0 0 .833h.416c.23 0 .417-.186.417-.416ZM5.417.646a.417.417 0 1 0-.833 0v.396a.417.417 0 0 0 .833 0V.646Zm2.81 1.506a.415.415 0 0 0-.587-.587l-.158.158a.415.415 0 1 0 .587.587l.158-.158Zm-.752 5.123a.414.414 0 0 0 0 .584l.162.163a.414.414 0 1 0 .585-.586l-.163-.162a.414.414 0 0 0-.584.001Zm1.275-2.9a.417.417 0 0 0 0 .833h.417a.417.417 0 1 0 0-.833H8.75ZM5 2.292c-1.379 0-2.5 1.12-2.5 2.5 0 1.379 1.121 2.5 2.5 2.5 1.38 0 2.5-1.121 2.5-2.5 0-1.38-1.12-2.5-2.5-2.5Zm-.416 6.645a.417.417 0 0 0 .833 0v-.395a.417.417 0 1 0-.833 0v.395ZM1.772 7.431a.415.415 0 1 0 .589.586l.16-.16a.415.415 0 1 0-.59-.587l-.16.161Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
