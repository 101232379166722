import { SVGProps, memo } from 'react';
const SvgComponent = ({ fill = '#000', ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none" {...props}>
    <path
      fill={fill}
      fillOpacity={0.88}
      d="M11.737 10.44a5.115 5.115 0 0 0-1.102-1.634A5.136 5.136 0 0 0 9 7.704l-.016-.007a3.39 3.39 0 1 0-3.97.001l-.017.007a5.091 5.091 0 0 0-1.634 1.102A5.136 5.136 0 0 0 1.86 12.33a.11.11 0 0 0 .11.112h.82a.11.11 0 0 0 .109-.106 4.076 4.076 0 0 1 1.2-2.793A4.074 4.074 0 0 1 7 8.34c1.096 0 2.126.426 2.9 1.202a4.076 4.076 0 0 1 1.201 2.793c.001.06.05.106.11.106h.82a.11.11 0 0 0 .11-.112 5.098 5.098 0 0 0-.404-1.89ZM7 7.3a2.336 2.336 0 0 1-1.663-.688 2.336 2.336 0 0 1-.689-1.663c0-.627.245-1.218.689-1.662A2.336 2.336 0 0 1 7 2.597c.627 0 1.218.245 1.662.69.444.444.69 1.035.69 1.662 0 .628-.246 1.218-.69 1.663A2.336 2.336 0 0 1 7 7.3Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
