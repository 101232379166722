import React, { createContext, useContext } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { BodyCellProps, DragIndexState, HeaderCellProps } from '../types';

export const DragIndexContext = createContext<DragIndexState>({
  active: -1,
  over: -1,
});

const dragActiveStyle = (dragState: DragIndexState, id: string) => {
  const { active, over, direction } = dragState;
  let style: React.CSSProperties = {};
  if (active && active === id) {
    style = { backgroundColor: 'gray', opacity: 0.5 };
  } else if (over && id === over && active !== over) {
    style =
      direction === 'right'
        ? { borderRight: '1px dashed gray' }
        : { borderLeft: '1px dashed gray' };
  }
  return style;
};

export const TableBodyCell: React.FC<BodyCellProps> = ({ children, ...props }) => {
  const dragState = useContext<DragIndexState>(DragIndexContext);
  return (
    <td
      {...props}
      style={{
        ...props.style,
        ...dragActiveStyle(dragState, props.id),
        verticalAlign: 'middle',
      }}
    >
      <div
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
        title={children as string}
      >
        {children}
      </div>
    </td>
  );
};

export const TableHeaderCell: React.FC<HeaderCellProps> = (props) => {
  const dragState = useContext(DragIndexContext);
  const { attributes, listeners, setNodeRef, isDragging } = useSortable({
    id: props.id,
    disabled: !props['aria-label'],
  });
  const style: React.CSSProperties = {
    ...props.style,
    cursor: props['aria-label'] ? 'pointer' : 'default',
    ...(isDragging ? { position: 'relative', userSelect: 'none' } : {}),
    ...dragActiveStyle(dragState, props.id),
  };

  return <th {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
};
