import { SVGProps, memo } from "react";
const SvgComponent = ({ fill = "#000", ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={5}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      d="M7.942 4.57 4.21.096a.278.278 0 0 0-.42 0L.058 4.57c-.139.167-.014.412.21.412h7.464c.224 0 .349-.245.21-.412Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
