import { SVGProps, memo } from 'react';
const SvgComponent = ({ fill = '#757575', ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none" {...props}>
    <path
      fill={fill}
      d="M24 31.15c-.267 0-.517-.041-.75-.124a1.734 1.734 0 0 1-.65-.426l-7.2-7.2c-.367-.367-.55-.833-.55-1.4s.183-1.033.55-1.4c.367-.367.842-.558 1.426-.574.584-.016 1.059.159 1.424.524L22 24.3V10c0-.567.192-1.042.576-1.426.384-.384.859-.575 1.424-.574.567 0 1.042.192 1.426.576.384.384.575.859.574 1.424v14.3l3.75-3.75c.367-.367.842-.542 1.426-.526.584.016 1.059.208 1.424.576.367.367.55.833.55 1.4s-.183 1.033-.55 1.4l-7.2 7.2c-.2.2-.417.342-.65.426a2.166 2.166 0 0 1-.75.124ZM12 40c-1.1 0-2.042-.392-2.826-1.176C8.39 38.04 7.999 37.099 8 36v-4c0-.567.192-1.042.576-1.426.384-.384.859-.575 1.424-.574.567 0 1.042.192 1.426.576.384.384.575.859.574 1.424v4h24v-4c0-.567.192-1.042.576-1.426.384-.384.859-.575 1.424-.574.567 0 1.042.192 1.426.576.384.384.575.859.574 1.424v4c0 1.1-.392 2.042-1.176 2.826C38.04 39.61 37.099 40.001 36 40H12Z"
    />
  </svg>
);
const UploadIcon = memo(SvgComponent);
export default UploadIcon;
