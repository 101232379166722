import { SVGProps, memo } from 'react';
const SvgComponent = ({ fill = '#C2C2C2', ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
    <path
      fill={fill}
      d="M13.273 0A2.727 2.727 0 0 1 16 2.727v10.546A2.727 2.727 0 0 1 13.273 16H2.727A2.727 2.727 0 0 1 0 13.273V2.727A2.727 2.727 0 0 1 2.727 0h10.546ZM1.09 2.727v2h10.182V14.91h2a1.636 1.636 0 0 0 1.636-1.636V2.727a1.636 1.636 0 0 0-1.636-1.636H2.727a1.636 1.636 0 0 0-1.636 1.636Zm4.727 7.455h4.364V5.818H5.818v4.364Zm4.364 4.727v-3.636H5.818v3.636h4.364ZM4.727 5.82H1.091v4.363h3.636V5.818Zm-3.636 5.454v2a1.636 1.636 0 0 0 1.636 1.636h2v-3.636H1.091Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
