import React, { forwardRef, ComponentProps } from 'react';
import styles from './Container.module.scss';
type TContainer = {
  children: React.ReactNode;
  className?: string;
} & ComponentProps<'div'>;

const Container = forwardRef<HTMLDivElement, TContainer>((props, ref) => {
  return (
    <div className={`${styles.containerDiv} ${props.className}`} ref={ref} {...props}>
      {props.children}
    </div>
  );
});
export default Container;
