import { AnimatedCard, Button, Chip } from 'components/shared';
import styles from './JobCard.module.scss';
import { park_icon } from 'assets';
import { TJob } from 'features/landing/types';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';

export default function JobCard({ title, expiresAt, shortDescription, jobId, location }: TJob) {
  const locationText = {
    2: 'On-site',
    1: 'Remote',
    3: 'Hybrid',
  };
  const locationColor = {
    2: 'var(--location-type-color-2)',
    1: 'var(--location-type-color-1)',
    3: 'var(--location-type-color-3)',
  };

  return (
    <div style={{ position: 'relative', overflow: 'visible' }}>
      <div className={styles.chevron}>
        <img loading="lazy" width={48} height={48} src={park_icon} alt="chevron" />
      </div>
      <AnimatedCard>
        <div className={styles.jobCardContent}>
          <div className={styles.heading}>
            <p className={styles.title}>{title}</p>
            <Chip
              title={locationText[location as keyof typeof locationText]}
              color={locationColor[location as keyof typeof locationColor]}
            />
          </div>
          <div className={styles.description}>
            <p>{shortDescription}</p>
          </div>

          <div className={styles.date}>
            <time dateTime={expiresAt}>End Date: {format(expiresAt, 'dd/MM/yyyy')}</time>
          </div>
          <div className={styles.action}>
            <Link to={`/career/${jobId}`}>
              <Button
                style={{
                  width: '100%',
                }}
              >
                View Description
              </Button>
            </Link>
          </div>
        </div>
      </AnimatedCard>
    </div>
  );
}
