import { ISelectType } from "types";

export const TAB_ITEMS: ISelectType[] = [
  { id: "personalInfo", name: "Personal Information" },
  { id: "changePassword", name: "Change password" },
  { id: "logOut", name: "Log out" },
];

export const settingsApiRoutes = {
  LOG_OUT: "v1/my-account/logout",
  PASSWORD: "v1/my-account/password",
  PERSONAL_INFORMATION: "v1/my-account/personal-information",
};
