import { SVGProps, memo } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={40}
    height={40}
    fill="none"
    {...props}
  >
    <circle cx={20} cy={20} r={20} fill="#E7E7E7" />
    <path
      fill="#000"
      d="M18.5 14h3a1.5 1.5 0 1 0-3 0Zm-1 0a2.5 2.5 0 0 1 5 0h5a.5.5 0 0 1 0 1h-1.054l-1.194 10.344A3 3 0 0 1 22.272 28h-4.544a3 3 0 0 1-2.98-2.656L13.554 15H12.5a.5.5 0 0 1 0-1h5Zm-1.759 11.23A2 2 0 0 0 17.728 27h4.544a2 2 0 0 0 1.987-1.77L25.439 15H14.561l1.18 10.23ZM18.5 17.5a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0v-6a.5.5 0 0 1 .5-.5Zm3.5.5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0v-6Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
