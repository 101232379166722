import { Tag } from 'antd';
import React from 'react';

interface Props {
  status: number;
  text: string | number;
  width?: string;
}

const textColors = {
  0: 'default',
  1: 'success',
  2: 'error',
};

const StatusCard: React.FC<Props> = ({ status, text }) => {
  return (
    <Tag color={textColors[status as keyof typeof textColors]} key={status}>
      {text}
    </Tag>
  );
};

export default StatusCard;
