import styles from './ValuesCard.module.scss';

interface ValuesCardProps {
  description: string;
  icon: string;
  title: string;
}

export default function ValuesCard({ description, icon, title }: ValuesCardProps): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.flipContainer}>
        <div className={styles.flipper}>
          <div className={styles.front}>
            <img src={icon} alt="service_card_Icon" width={70} height={70} />
          </div>
          <div className={styles.back}>{description}</div>
        </div>
      </div>
      <h1 className={styles.title}>{title}</h1>
    </div>
  );
}
