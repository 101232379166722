import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} fill="none" {...props}>
    <path
      fill="#656A81"
      d="M13.117 3.73a1.313 1.313 0 0 0-1.234 0l-7.875 4.2a1.313 1.313 0 0 0-.696 1.158v1.073l7.513 4.548 1.675-1.005 1.675 1.005 7.512-4.548V9.088a1.313 1.313 0 0 0-.695-1.158l-7.875-4.2Zm8.57 7.967-6.24 3.776 6.24 3.743v-7.52Zm-.045 9.022L12.5 15.234 3.358 20.72a1.313 1.313 0 0 0 1.267.968h15.75a1.313 1.313 0 0 0 1.267-.97Zm-18.33-1.503 6.24-3.743-6.24-3.778v7.521Zm7.953-16.645a2.625 2.625 0 0 1 2.47 0l7.875 4.2A2.625 2.625 0 0 1 23 9.088v11.287A2.625 2.625 0 0 1 20.375 23H4.625A2.625 2.625 0 0 1 2 20.375V9.088A2.625 2.625 0 0 1 3.39 6.77l7.875-4.2Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
