import { useAppDispatch } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useGetJobAppliesQuery } from '../../services/jobAppliesApi.ts';
import { Flex, Typography } from 'antd';
import { IGetJobApplies } from '../../types';
import { filtersConvertor, PagesWrapper, Table, TABLE_KEY_ENUM } from 'components';
import { jobAppliesTableColumns, jobAppliesTableStructure } from '../../constants';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice.ts';
import { MODALS } from '_constants';
import { convertOrder } from 'utils/helpers.ts';

const JobAppliesWrapper = () => {
  const dispatch = useAppDispatch();
  const { openModal } = modalDispatcher(dispatch);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const pageSize = Number(searchParams.get('pageSize'));
  const filters = searchParams.get('filters');
  const paramsOrderBy = searchParams.get('orderBy');

  const { data: jobAppliesData, isFetching } = useGetJobAppliesQuery({
    Page: page || 1,
    PageSize: pageSize || 10,
    Filter: filtersConvertor(filters ? JSON.parse(filters) : []),
    OrderBy: convertOrder(paramsOrderBy),
  });

  useEffect(() => {
    if (!page && !pageSize) {
      setSearchParams((prev) => ({ ...prev, pageSize: 10, page: 1 }));
      return;
    }
    if (!page) {
      setSearchParams((prev) => ({ ...prev, page: 1 }));
      return;
    }
    if (!pageSize) {
      setSearchParams((prev) => ({ ...prev, pageSize: 10 }));
      return;
    }
  }, []);

  return (
    <PagesWrapper>
      <Flex justify={'space-between'} align={'center'}>
        <Typography>Job Apply Requests</Typography>
      </Flex>
      <Table<IGetJobApplies>
        loading={isFetching}
        data={jobAppliesData?.data || []}
        tableKey={TABLE_KEY_ENUM.JOB_APPLIES}
        total={jobAppliesData?.totalCount}
        columns={jobAppliesTableColumns}
        tableStructure={jobAppliesTableStructure}
        // editAction={(data: IGetJobApplies) =>
        //   openModal({ modalType: MODALS.JOB_APPLY_MODAL, props: { data } })
        // }
        onRowClick={(data: IGetJobApplies) =>
          openModal({ modalType: MODALS.JOB_APPLY_MODAL, props: { data } })
        }
        canBeSave
      />
    </PagesWrapper>
  );
};

export default JobAppliesWrapper;
