import { TableColumnsType, TableProps, Table as AntTable } from 'antd';
import { TABLE_KEY_ENUM } from '../../constants';
import useTable from '../../hooks/useTable.tsx';
import { FilterTypesEnum, IDeleteArgs, TableHeader, TCustomize } from '../../index';
import { closestCenter, DndContext, DragOverlay, UniqueIdentifier } from '@dnd-kit/core';
import { ReactNode, useEffect } from 'react';
import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { DragIndexContext, TableBodyCell, TableHeaderCell } from '../../utils/helpers';
import { TBase36Id } from 'types';
import AggregatesWrapper from '../AggregatesWrapper/AggregatesWrapper.tsx';
import { useSearchParams } from 'react-router-dom';

interface Props<T> {
  data: (T & { id: TBase36Id })[];
  columns: TableColumnsType<T>;
  tableKey: TABLE_KEY_ENUM;
  tableStructure: TableProps<T>;
  total?: number;
  deleteAction?: (deleteArgs: IDeleteArgs<T>) => void;
  customActions?: { title: string; action: (values: T[]) => void; icon?: ReactNode }[];
  editAction?: (value: T) => void;
  exportTrigger?: boolean;
  onRowClick?: (record: T, index: number | undefined) => void;
  aggregates?: { [key: string]: FilterTypesEnum };
  tableAction?: ReactNode;
  handleSaveAsDefault?: (options: TCustomize[]) => void;
  loading?: boolean;
  canBeSave?: boolean;
  isDraggable?: boolean;
}

const Table = <T extends Record<string, any>>({
  tableKey,
  data,
  columns,
  tableStructure,
  total,
  deleteAction,
  editAction,
  exportTrigger = true,
  customActions,
  onRowClick,
  canBeSave = false,
  aggregates,
  loading,
  tableAction,
  isDraggable = true,
}: Props<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const pageSize = searchParams.get('pageSize');
  const { customProps, dnd, ...tableProps } = useTable<T>({
    columns,
    total,
    tableKey,
    deleteAction,
    editAction,
  });
  const { customizeColumns, checkedItems, onEdit, tableColumns, ...headerProps } = customProps;
  const { onDragEnd, onDragOver, dragIndex, sensors } = dnd;

  const handleEdit = () => {
    if (checkedItems.length === 1) {
      onEdit?.(checkedItems[0]);
    }
  };

  const rowClassName = (_: T, index: number) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };

  useEffect(() => {
    if (!page && !pageSize) {
      setSearchParams((prev) => ({ ...prev, pageSize: 10, page: 1 }));
      return;
    }
    if (!page) {
      setSearchParams((prev) => ({ ...prev, page: 1 }));
      return;
    }
    if (!pageSize) {
      setSearchParams((prev) => ({ ...prev, pageSize: 10 }));
      return;
    }
  }, []);

  return (
    <div>
      <TableHeader<T>
        {...headerProps}
        editAction={editAction}
        deleteAction={deleteAction}
        checkedItems={checkedItems}
        customizeColumns={customizeColumns}
        handleEdit={handleEdit}
        exportTrigger={exportTrigger}
        customActions={customActions}
        tableAction={tableAction}
        canBeSave={canBeSave}
      />
      <DndContext
        sensors={sensors}
        modifiers={[restrictToHorizontalAxis]}
        onDragEnd={onDragEnd}
        onDragOver={onDragOver}
        collisionDetection={closestCenter}
      >
        <SortableContext
          disabled={!isDraggable}
          items={tableColumns!.filter((el) => el.title).map((i) => i.key as UniqueIdentifier)}
          strategy={horizontalListSortingStrategy}
        >
          <DragIndexContext.Provider value={dragIndex}>
            <AntTable
              loading={loading}
              tableLayout={'fixed'}
              onRow={(record, index) => {
                return {
                  onClick: () => {
                    onRowClick?.(record, index);
                  },
                };
              }}
              {...tableProps}
              {...tableStructure}
              rowKey="key"
              dataSource={data.map((el: T & { id: TBase36Id }) => ({
                ...el,
                key: el.id,
              }))}
              columns={tableColumns.map((el) => {
                const hide = () => {
                  if (!el.title) return false;
                  if (customizeColumns) {
                    return !customizeColumns
                      .filter((el) => el.visible)
                      .map((el) => el.value)
                      .includes(el.key as string);
                  }
                  return false;
                };

                const fixed = () => {
                  if (!el.title) return 'right';
                  if (customizeColumns) {
                    return customizeColumns
                      .filter((el) => el.fixed)
                      .map((el) => el.value)
                      .includes(el.key as string);
                  }
                  return false;
                };

                return {
                  ...el,
                  hidden: hide(),
                  fixed: fixed(),
                };
              })}
              components={{
                header: { cell: TableHeaderCell },
                body: { cell: TableBodyCell },
              }}
              summary={() => {
                return aggregates ? (
                  <AntTable.Summary fixed={'bottom'}>
                    <AntTable.Summary.Row>
                      <AggregatesWrapper<T>
                        columns={columns}
                        aggregates={aggregates}
                        hasCheckboxes={!!tableProps.rowSelection}
                        path={tableKey}
                      />
                    </AntTable.Summary.Row>
                  </AntTable.Summary>
                ) : (
                  <></>
                );
              }}
              rowClassName={rowClassName}
            />
          </DragIndexContext.Provider>
        </SortableContext>
        <DragOverlay>
          <th style={{ backgroundColor: 'gray', padding: 16 }}>
            {columns[columns.findIndex((i) => i.key === dragIndex.active)]?.title as ReactNode}
          </th>
        </DragOverlay>
      </DndContext>
    </div>
  );
};

export default Table;
