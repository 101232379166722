import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { createDispatchers } from 'utils';

type InitialStateType = {
  open: boolean;
  error: boolean;
  message: string | null;
};

const initialState: InitialStateType = {
  open: false,
  error: false,
  message: null,
};

const snackBarSlice = createSlice({
  name: 'snackBar',
  initialState,
  reducers: {
    openErrorSnackBar: (state, { payload: { message } }) => {
      state.error = true;
      state.message = message;
      state.open = true;
    },
    openSuccessSnackBar: (state, { payload: { message } }) => {
      state.error = false;
      state.message = message;
      state.open = true;
    },
    onClose: (state) => {
      state.open = false;
    },
  },
});

export const snackBarDispatcher = createDispatchers(snackBarSlice.actions);
export const { openErrorSnackBar, openSuccessSnackBar, onClose } = snackBarSlice.actions;
export const snackBarState = (state: RootState) => state.snackBar;

export default snackBarSlice.reducer;
