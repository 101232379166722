import { ComponentProps, ReactNode, forwardRef } from 'react';
import styles from './Button.module.scss';

type TButton = {
  children: ReactNode;
  className?: string;
} & ComponentProps<'button'>;
const Button = forwardRef<HTMLButtonElement, TButton>(({ className, children, ...props }, ref) => {
  return (
    <button ref={ref} {...props} className={`${styles.button} ${className}`}>
      {children}
    </button>
  );
});
export default Button;
