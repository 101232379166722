import { api } from 'api';
import { removeLSData } from 'utils';
import { TOKEN_KEY } from '_constants/constants.ts';
import { settingsApiRoutes } from '../constants/constants.ts';
import { router } from 'router/router.tsx';
import { RouterPaths } from 'router/constants.ts';
import { IChangePassword, IGetPersonalInfo } from '../types';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice.ts';
import { openSuccessSnackBar } from 'components/ui/SnackBar/services/SnackBarSlice.ts';

export const settingsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    logOut: builder.mutation<void, void>({
      query: () => ({
        url: settingsApiRoutes.LOG_OUT,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then((res) => {
            if ('data' in res) {
              const { closeAllModals } = modalDispatcher(dispatch);
              removeLSData(TOKEN_KEY);
              closeAllModals();
              dispatch(api.util.resetApiState());
              router.navigate(RouterPaths.Login);
            }
          })
          .catch(() => {});
      },
    }),
    getPersonalInfo: builder.query<IGetPersonalInfo, void>({
      query: () => ({
        url: settingsApiRoutes.PERSONAL_INFORMATION,
      }),
    }),
    changePassword: builder.mutation<void, IChangePassword>({
      query: (body) => ({
        url: settingsApiRoutes.PASSWORD,
        method: 'PATCH',
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            dispatch(openSuccessSnackBar({ message: 'Password has been changed successfully' }));
          })
          .catch(() => {});
      },
    }),
  }),
});

export const { useLogOutMutation, useGetPersonalInfoQuery, useChangePasswordMutation } =
  settingsApi;
