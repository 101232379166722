import { SVGProps, memo } from "react";
const SvgComponent = ({ fill = "#000", ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={8}
    height={5}
    fill="none"
    {...props}
  >
    <path
      fill={fill}
      d="M7.942.411 4.21 4.885a.278.278 0 0 1-.42 0L.058.411C-.08.244.044 0 .268 0h7.464c.224 0 .349.244.21.411Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
