import { api } from 'api';
import { IRequestData, ITableResponse, TBase36Id } from 'types';
import { jobsAppliesApiRoutes } from '../constants';
import { IChangeJobStatus, IGetJobApplies } from '../types';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice';
import { openSuccessSnackBar } from 'components/ui/SnackBar/services/SnackBarSlice';

const withTags = api.enhanceEndpoints({
  addTagTypes: ['JobApplies'],
});

export const jobAppliesApi = withTags.injectEndpoints({
  endpoints: (builder) => ({
    getJobApplies: builder.query<ITableResponse<IGetJobApplies>, IRequestData>({
      query: (params) => ({
        url: jobsAppliesApiRoutes.JOB_APPLIES,
        params,
      }),
      providesTags: ['JobApplies'],
    }),
    changeJobStatus: builder.mutation<void, TBase36Id>({
      query: (id) => ({
        url: jobsAppliesApiRoutes.JOB_CHANGE_STATUS(id as string),
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            dispatch(openSuccessSnackBar({ message: 'Status has been changed successfully' }));
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['JobApplies']),
    }),
    changeJobApplicant: builder.mutation<IGetJobApplies, IChangeJobStatus>({
      query: ({ id, ...body }) => ({
        url: jobsAppliesApiRoutes.JOB_APPLIES_BY_ID(id as string),
        method: 'PUT',
        body,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            const { closeAllModals } = modalDispatcher(dispatch);
            closeAllModals();
            dispatch(openSuccessSnackBar({ message: 'Status has been changed successfully' }));
          })
          .catch(() => {});
      },
      invalidatesTags: (_, error) => (error ? [] : ['JobApplies']),
    }),
  }),
});

export const { useGetJobAppliesQuery, useChangeJobStatusMutation, useChangeJobApplicantMutation } =
  jobAppliesApi;
