import styles from './HeroSection.module.scss';
import { Ellipse } from 'components';
import { hero } from 'assets';
import useWindowSize from 'hooks/useWindowSize';

type HeroSectionProps = {
  title: string;
  subtitle: string;
  description?: string;
  image?: string;
};
export default function HeroSection({ title, subtitle, description, image }: HeroSectionProps) {
  const { isMobile } = useWindowSize();
  const picture = image || hero;
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroSectionInner}>
        <div className={styles.content}>
          <p className={styles.subtitle}>{subtitle}</p>
          <h1 className={`${styles.title} animated-header`}>{title}</h1>

          {description && <p className={styles.description}>{description}</p>}
        </div>
        <div
          style={{
            width: '100%',
            maxWidth: 700,
          }}
        >
          <img
            width={700}
            height={400}
            style={{
              // position: 'absolute',
              // top: 0,
              // right: 0,
              // zIndex: 2,
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              objectPosition: 'right top',
              filter: 'drop-shadow(2px 4px 6px  var(--hero-shadow))',
            }}
            loading="eager"
            role="presentation"
            srcSet={`${picture} 769w, ${picture} 1440w, ${picture} 1920w`}
            src={picture}
            sizes="(max-width: 600px) 796px, (max-width: 1440) 1440px, 1920px"
            alt="hero"
          />
        </div>
        {/* </picture> */}
      </div>
      {isMobile ? null : (
        <Ellipse
          width={isMobile ? 100 : 528}
          height={isMobile ? 100 : 528}
          color={'rgba(78, 55, 211, 0.30)'}
          top={isMobile ? 10 : 80}
          right={isMobile ? 10 : 550}
        />
      )}
      {isMobile ? null : (
        <Ellipse
          width={isMobile ? 100 : 299}
          height={isMobile ? 100 : 299}
          color={'rgba(62, 204, 153, 0.52)'}
          top={240}
          right={143}
        />
      )}
    </section>
  );
}
