import { ExclamationCircleFilled } from '@ant-design/icons';
import { MODALS, JOB_REVIEWED, JOB_REJECT } from '_constants';
import { ModalProps, Modal, Button, Typography, Flex, Checkbox, Row, Col } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice';
import {
  IGetJobApplies,
  useChangeJobApplicantMutation,
  useChangeJobStatusMutation,
} from 'features/jobApplies';
import { useAppDispatch, useToken } from 'hooks';
import { ReactNode, useState } from 'react';

type ConfirmModalProps = {
  title: string;
  message: string;
  cancelAction?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  description?: string;
  icon?: ReactNode;
  rejectMsg: string;
  data: IGetJobApplies;
  actionType: typeof JOB_REVIEWED | typeof JOB_REJECT;
} & ModalProps;

const JobConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  cancelAction,
  cancelButtonText,
  confirmButtonText,
  message,
  data,
  actionType,
}) => {
  const dispatch = useAppDispatch();
  const { fontSize16 } = useToken();
  const { closeModal } = modalDispatcher(dispatch);
  const [changeJobStatus] = useChangeJobStatusMutation();
  const [changeJobApplicant] = useChangeJobApplicantMutation();
  const [handleCheck, setHandleCheck] = useState<boolean>(false);

  const onModalClose = () => {
    cancelAction?.();
    closeModal({ modalType: MODALS.JOB_CONFIRM_MODAL });
  };

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setHandleCheck(e.target.checked);
  };

  const handleOk = async () => {
    await changeJobApplicant({
      id: data.id,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      minSalary: data.minSalary,
      phoneNumber: data.phoneNumber,
      status: Number(actionType),
    });
    if (Number(actionType) === Number(JOB_REJECT)) {
      if (handleCheck) {
        await changeJobStatus(data.id);
      }
    }
  };

  return (
    <Modal
      width={400}
      open={true}
      title={
        <Flex style={{ marginTop: 20 }}>
          <Col span={2}>
            <ExclamationCircleFilled style={{ color: '#FAAD14', width: '24px', height: '24px' }} />
          </Col>
          <Col span={22}>
            <span style={{ fontSize: '16px', fontWeight: 600 }}>{title}</span>
          </Col>
        </Flex>
      }
      onOk={handleOk}
      onCancel={onModalClose}
      centered
      footer={[
        <Flex
          key={null}
          justify="end"
          gap={16}
          style={{
            marginTop: actionType === JOB_REJECT ? 48 : 54,
          }}
        >
          <Button key="cancel" onClick={onModalClose}>
            {cancelButtonText}
          </Button>

          <Button key={'action'} type="primary" onClick={handleOk}>
            {confirmButtonText}
          </Button>
        </Flex>,
      ]}
    >
      {String(actionType) !== String(JOB_REVIEWED) && !data.rejectEmailSent && (
        <Row
          style={{
            marginTop: 38,
          }}
        >
          <Col span={2}></Col>
          <Col span={22}>
            <Checkbox checked={handleCheck} onChange={handleCheckboxChange}>
              Send rejection email
            </Checkbox>
          </Col>
        </Row>
      )}
      <Typography style={{ fontSize: fontSize16 }}>{message}</Typography>
    </Modal>
  );
};

export default JobConfirmModal;
