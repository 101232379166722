import { NavLink } from 'react-router-dom';
import styles from './LandingNav.module.scss';
import cx from 'classnames';

export default function LandingNav() {
  return (
    <nav className={styles.nav}>
      <ul>
        <li className="hover-line">
          <NavLink
            className={({ isActive }) =>
              cx(`${styles.listElement}`, {
                [styles.active]: isActive,
              })
            }
            to="/about-us"
          >
            About us
          </NavLink>
        </li>
        <li className="hover-line">
          <NavLink
            className={({ isActive }) =>
              cx(`${styles.listElement}`, {
                [styles.active]: isActive,
              })
            }
            to="/technologies"
          >
            Tech stack
          </NavLink>
        </li>
        <li className="hover-line">
          <NavLink
            className={({ isActive }) =>
              cx(`${styles.listElement}`, {
                [styles.active]: isActive,
              })
            }
            to="/career"
          >
            Careers
          </NavLink>
        </li>
        <li className="hover-line">
          <NavLink
            className={({ isActive }) =>
              cx(`${styles.listElement}`, {
                [styles.active]: isActive,
              })
            }
            to="/contact"
          >
            Contact us
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
