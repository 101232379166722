import React, { useRef, useState } from 'react';
import { ModalProps } from 'types';
import { ICreateUser, IGetUsers } from '../../types';
import { Button, Col, Form, Input, Modal, Row, Select } from 'antd';
import { UsersModalEnum } from 'features/users/constants';
import { useAppDispatch, useToken } from 'hooks';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice.ts';
import { MODALS, PASSWORD_MSG, PASSWORD_REGEXP, USER_ROLES } from '_constants/constants.ts';
import {
  useChangeUserPasswordMutation,
  useCreateUserMutation,
  useEditUserMutation,
  useGetUserByIdQuery,
} from '../../services/usersApi.ts';
import { UnderlinedButton } from 'components';
import { ArrowLeftOutlined } from '@ant-design/icons';

type Props = {
  type: UsersModalEnum;
  data?: IGetUsers;
} & ModalProps;

const UsersModal: React.FC<Props> = ({ type, data }) => {
  const { marginLG } = useToken();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [createUser] = useCreateUserMutation();
  const [changeUserPassword] = useChangeUserPasswordMutation();
  const [editUser] = useEditUserMutation();
  const [modalType] = useState<UsersModalEnum>(type);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const { closeModal } = modalDispatcher(dispatch);
  const { data: userData } = useGetUserByIdQuery(data?.id as string, { skip: !data });

  const inputRef = useRef<ICreateUser>({
    comment: '',
    fullName: '',
    userRole: null,
    password: '',
    username: '',
  });

  const passwordRef = useRef({
    newPassword: '',
    confirmPassword: '',
  });

  const handleSave = async () => {
    if (changePassword) {
      const confirmPasswordMatches = PASSWORD_REGEXP.test(passwordRef.current.confirmPassword);
      const newPasswordMatches = PASSWORD_REGEXP.test(passwordRef.current.newPassword);

      if (
        confirmPasswordMatches &&
        newPasswordMatches &&
        passwordRef.current.confirmPassword === passwordRef.current.newPassword
      ) {
        if (userData) {
          changeUserPassword({
            id: userData.id,
            newPassword: passwordRef.current.newPassword,
          });
        }
      }
      return;
    }
    if (userData) {
      editUser({
        id: userData.id,
        role: inputRef.current.userRole || userData.role,
        username: inputRef.current.username || userData.username,
        fullName: inputRef.current.fullName || userData.fullName,
        comment: inputRef.current.comment || userData.comment,
      });
    }
    createUser(inputRef.current);
  };

  const onModalClose = () => {
    closeModal({ modalType: MODALS.USERS_MODAL });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    inputRef.current = { ...inputRef.current, [name]: value };
  };

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    passwordRef.current = { ...passwordRef.current, [name]: value };
  };

  const validatePassword = (_: any, value: string) => {
    if (value && !PASSWORD_REGEXP.test(value)) {
      return Promise.reject(new Error(PASSWORD_MSG));
    }
    return Promise.resolve();
  };

  return (
    <Modal
      open={true}
      title={
        data && changePassword ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ArrowLeftOutlined
              onClick={() => setChangePassword(false)}
              style={{ cursor: 'pointer', marginRight: 16 }}
            />
            <span>{changePassword ? 'Change Password' : `${modalType} User`}</span>
          </div>
        ) : (
          `${modalType} User`
        )
      }
      onOk={handleSave}
      onCancel={onModalClose}
      centered
      footer={[
        <Button key="cancel" onClick={onModalClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" htmlType="submit" onClick={() => form.submit()}>
          Save
        </Button>,
      ]}
    >
      {changePassword ? (
        <Form
          form={form}
          layout="vertical"
          name="userForm"
          style={{ marginTop: marginLG }}
          onFinish={handleSave}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                label={'New Password'}
                name={'newPassword'}
                status="error"
                rules={[
                  {
                    required: true,
                    message: PASSWORD_MSG,
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password
                  autoComplete={'off'}
                  name="newPassword"
                  id="newPassword"
                  onChange={handleChangePassword}
                  size="large"
                  placeholder="Type here"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label={'Confirm Password'}
                name={'confirmPassword'}
                rules={[
                  {
                    required: true,
                    message: PASSWORD_MSG,
                    validator: validatePassword,
                  },
                ]}
              >
                <Input.Password
                  autoComplete={'off'}
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={handleChangePassword}
                  size="large"
                  placeholder="Type here"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <Form
          form={form}
          layout="vertical"
          name="userForm"
          style={{ marginTop: marginLG }}
          onFinish={handleSave}
          initialValues={{
            username: data?.username,
            fullName: data?.fullName,
            userRole: data?.role,
            comment: data?.comment,
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={'Username'} name={'username'} rules={[{ required: true }]}>
                <Input
                  autoComplete={'off'}
                  name="username"
                  id="username"
                  onChange={handleChange}
                  size="large"
                  placeholder="Type here"
                  defaultValue={userData?.username}
                  key={userData?.username}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={'Full name'} name={'fullName'} rules={[{ required: true }]}>
                <Input
                  autoComplete={'off'}
                  name="fullName"
                  id="fullName"
                  onChange={handleChange}
                  size="large"
                  placeholder="Type here"
                  defaultValue={userData?.fullName}
                  key={userData?.fullName}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={'Role'} name={'userRole'} rules={[{ required: true }]}>
                <Select
                  placeholder="Choose here"
                  id={'userRole'}
                  onChange={(value: number) =>
                    (inputRef.current = { ...inputRef.current, userRole: value })
                  }
                  options={USER_ROLES}
                  size={'large'}
                  defaultValue={userData?.role}
                  key={userData?.role}
                />
              </Form.Item>
            </Col>
            {type === UsersModalEnum.CREATE && (
              <Col span={24}>
                <Form.Item
                  label={'Password'}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: PASSWORD_MSG,
                      validator: validatePassword,
                    },
                  ]}
                >
                  <Input.Password
                    autoComplete={'off'}
                    name="password"
                    id="password"
                    onChange={handleChange}
                    size="large"
                    placeholder="Type here"
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item label={'Comment'}>
                <Input.TextArea
                  autoComplete={'off'}
                  name="comment"
                  id="comment"
                  onChange={handleChange}
                  size="large"
                  placeholder="Type here"
                  defaultValue={userData?.comment}
                  key={userData?.comment}
                />
              </Form.Item>
            </Col>
          </Row>
          {type === UsersModalEnum.EDIT && (
            <div style={{ textAlign: 'end', width: '100%' }}>
              <UnderlinedButton
                title={'Change Password'}
                onClick={() => {
                  setChangePassword(true);
                }}
              />
            </div>
          )}
        </Form>
      )}
    </Modal>
  );
};

export default UsersModal;
