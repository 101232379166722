import React, { useEffect, useState } from 'react';
import { Col, Popover, Row, SelectProps, Typography } from 'antd';
import { FilterTypesEnum } from '../../types';
import { AGGREGATES_TITLES, TABLE_KEY_ENUM } from '../../constants';
import { DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { useSearchParams } from 'react-router-dom';

type Props = {
  aggregateType: FilterTypesEnum;
  columnName: string;
  path: TABLE_KEY_ENUM;
} & SelectProps;

const AggregatesDropDown: React.FC<Props> = ({ options }) => {
  // const endpoint: string = process.env.REACT_APP_BASE_URL || "";
  const [isLoading] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>('');
  const [selected, setSelected] = useState<string>(AGGREGATES_TITLES.Total);
  const [value, setValue] = useState<string>('');
  const [searchParams] = useSearchParams();
  const paramsFilters = searchParams.get('filters');

  const handleChange = (value: string) => {
    console.log(value);
    // setIsLoading(true);
    // const orderBy = `${columnName} desc`;
    // const filters = filtersConvertor(
    //     paramsFilters ? JSON.parse(paramsFilters) : [],
    // );
    // axios
    //     .get(
    //         `${endpoint}/${path}/aggregated?PropertyName=${columnName}&AggregateType=${value}&Page=1&PageSize=1&Filter=${filters}&OrderBy=${orderBy}`,
    //     )
    //     .then((res) => {
    //         if ("data" in res) {
    //             setValue(res.data);
    //             setSelectedType(value);
    //             setSelected(
    //                 AGGREGATES_TITLES[value as keyof typeof AGGREGATES_TITLES],
    //             );
    //             setIsVisible((prev) => !prev);
    //             setIsVisible(false);
    //             setIsLoading(false);
    //         }
    //     });
  };

  const checkboxRender = () => (
    <Row style={{ width: 150 }}>
      {options?.map((el) => {
        return (
          <Col
            key={el.value}
            span={24}
            style={{ cursor: 'pointer' }}
            onClick={() => handleChange(el.value as string)}
          >
            {el.label}
          </Col>
        );
      })}
    </Row>
  );

  useEffect(() => {
    if (paramsFilters && selectedType) {
      handleChange(selectedType);
    }
    if (!paramsFilters && selected !== AGGREGATES_TITLES.Total) {
      resetAggregates();
    }
  }, [paramsFilters]);

  const resetAggregates = () => {
    setSelected(AGGREGATES_TITLES.Total);
    setSelectedType('');
    setValue('');
  };

  return (
    <>
      <Popover
        content={checkboxRender}
        open={isVisible}
        onOpenChange={() => setIsVisible((prev) => !prev)}
        trigger="click"
        placement="bottomLeft"
        style={{ zIndex: 10000 }}
      >
        <Row>
          <Col
            span={20}
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              fontWeight: 600,
              cursor: 'pointer',
            }}
          >
            {isLoading ? <LoadingOutlined /> : selected}
          </Col>
          <Col span={4}>
            <DownOutlined />
          </Col>
          <Col span={24}>
            <Typography
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {value}
            </Typography>
          </Col>
        </Row>
      </Popover>
    </>
  );
};

export default AggregatesDropDown;
