import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Space,
  Typography,
} from 'antd';
import { TextEditor } from 'components';
import { JOB_LOCATION, JOB_TYPES } from '../../constants';
import { PlusOutlined } from '@ant-design/icons';
import { RouterPaths } from 'router/constants.ts';
import { useToken } from 'hooks';
import {
  useCreateJobMutation,
  useEditJobMutation,
  useGetJobByIdQuery,
} from '../../services/jobsApi.ts';
import { ICreateNewJob } from '../../types/types.ts';
import { useNavigate, useParams } from 'react-router-dom';

const CreateJob: React.FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const isEditPage = !!params.jobId;
  const { marginMD, gradient1, paddingLG } = useToken();
  const { data: jobData } = useGetJobByIdQuery(params.jobId as string, { skip: !isEditPage });
  const [location, setLocation] = useState<number>(JOB_LOCATION['On-site']);
  const [type, setType] = useState<number>(JOB_TYPES['Full-Time']);
  const [contents, setContents] = useState<{ [key: string]: string }>({ 1: '' });
  const [createJob] = useCreateJobMutation();
  const [editJob] = useEditJobMutation();

  const dataRef = useRef<ICreateNewJob>({
    title: '',
    shortDescription: '',
    location: null,
    type: null,
    content: '',
  });
  const handleClickBack = () => {
    navigate(`/admin/${RouterPaths.Jobs}`);
  };

  function isContentEmpty(html: string): boolean {
    const div = document.createElement('div');
    div.innerHTML = html;

    Array.from(div.querySelectorAll('*')).forEach((node) => {
      if (node.nodeType === Node.ELEMENT_NODE && node.textContent?.trim() === '') {
        node.remove();
      }
    });

    return div.innerHTML.trim() === '';
  }

  const handleSubmit = () => {
    const filteredContents = Object.fromEntries(
      Object.entries(contents).filter(([_key, value]) => !isContentEmpty(value)),
    );

    if (isEditPage) {
      editJob({
        type,
        location,
        content: JSON.stringify(filteredContents),
        id: params.jobId as string,
        title: dataRef.current.title || (jobData?.title as string),
        shortDescription: dataRef.current.shortDescription || (jobData?.shortDescription as string),
      }).then((res) => {
        if ('data' in res) {
          navigate(`/admin/${RouterPaths.Jobs}`);
        }
      });
      return;
    }
    createJob({ ...dataRef.current, content: JSON.stringify(filteredContents) }).then((res) => {
      if ('data' in res) {
        navigate(`/admin/${RouterPaths.Jobs}`);
      }
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    dataRef.current = { ...dataRef.current, [name]: value };
  };

  const handleChangeText = (key: string, content: string) => {
    setContents((prev) => ({ ...prev, [key]: content }));
  };

  const handleChangeLocation = (e: RadioChangeEvent) => {
    setLocation(e.target.value);
    dataRef.current = { ...dataRef.current, location: e.target.value };
  };
  const handleChangeType = (e: RadioChangeEvent) => {
    setType(e.target.value);
    dataRef.current = { ...dataRef.current, type: e.target.value };
  };

  const addNewContent = () => {
    const lastElementIndex = Object.keys(contents)[Object.keys(contents).length - 1];
    if (!contents[lastElementIndex]) return;
    setContents((prev) => ({ ...prev, [String(lastElementIndex + 1)]: '' }));
  };

  useEffect(() => {
    if (jobData) {
      setContents(JSON.parse(jobData.content));
      setType(jobData.type);
      setLocation(jobData.location);
      dataRef.current = {
        title: jobData.title,
        shortDescription: jobData.shortDescription,
        location: jobData.location,
        type: jobData.type,
        content: jobData.content,
      };
    }
  }, [jobData]);

  return (
    <Flex vertical style={{ maxWidth: '478px' }}>
      <Form
        style={{ maxWidth: 942, marginTop: marginMD }}
        layout="vertical"
        name="vacancyForm"
        onFinish={handleSubmit}
        initialValues={dataRef.current!}
        key={dataRef.current.title}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label={'Title'} name={'title'} rules={[{ required: true }]}>
              <Input
                autoComplete={'off'}
                name="title"
                id="title"
                onChange={handleChange}
                size="middle"
                placeholder="Type here"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={'Location'} name={'location'} rules={[{ required: true }]}>
              <Radio.Group id={'location'} onChange={handleChangeLocation} value={location}>
                <Space direction="horizontal">
                  {Object.keys(JOB_LOCATION).map((el: string) => (
                    <Radio key={el} value={JOB_LOCATION[el as keyof typeof JOB_LOCATION]}>
                      {el}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={'Type'} name={'type'} rules={[{ required: true }]}>
              <Radio.Group id={'type'} onChange={handleChangeType} value={type}>
                <Space direction="horizontal">
                  {Object.keys(JOB_TYPES).map((el: string) => (
                    <Radio key={el} value={JOB_TYPES[el as keyof typeof JOB_TYPES]}>
                      {el}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={'Short Description'}
              name={'shortDescription'}
              rules={[{ required: true, max: 120 }]}
            >
              <Input.TextArea
                showCount
                maxLength={120}
                autoComplete={'off'}
                name="shortDescription"
                id="shortDescription"
                onChange={handleChange}
                size="middle"
                placeholder="Type here"
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{ marginTop: paddingLG }}>
            <div
              style={{
                width: '100%',
                height: 1,
                marginBottom: 16,
                borderRadius: 16,
                background: gradient1,
              }}
            ></div>
          </Col>
          <Col span={24}>
            <Typography>Content</Typography>
          </Col>
          {Object.keys(contents).map((el) => (
            <Col key={el} span={24} style={{ marginTop: 24 }}>
              <TextEditor
                value={contents[el]}
                onChange={(value: string) => handleChangeText(el, value)}
              />
            </Col>
          ))}
        </Row>
        <Button type={'text'} style={{ marginTop: 24 }} onClick={addNewContent}>
          <PlusOutlined />
          Add Content
        </Button>
        <Flex gap={16} style={{ marginTop: 24 }}>
          <Button type="default" size={'middle'} onClick={handleClickBack}>
            Cancel
          </Button>
          <Form.Item>
            <Button type="primary" size={'middle'} htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Flex>
  );
};

export default CreateJob;
