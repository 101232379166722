import { AnimatedCard } from 'components';
import styles from './ServiceCard.module.scss';
type ServicesCardProps = {
  image: string;
  title: string;
  description: string;
};
function ServiceCard({ image, title, description }: ServicesCardProps) {
  return (
    <AnimatedCard>
      <div className={styles.container}>
        <img width={72} height={72} src={image} alt="service_card_logo" className={styles.image} />
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.textWrapper}>
          <p className={`${styles.description}`}>{description}</p>
        </div>
      </div>
    </AnimatedCard>
  );
}

export default ServiceCard;
