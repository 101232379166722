import { createApi, fetchBaseQuery, type BaseQueryApi } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';
import { BaseQueryExtraOptions, FetchArgs } from 'types';
import { removeLSData, setLSData } from '../utils';
import { TOKEN_KEY } from '../_constants';
import { IUserData } from '../features/login/types';
import { loginApiRoutes } from '../features/login/constants';
import { router } from '../router/router.tsx';
import { RouterPaths } from '../router/constants.ts';
import { openErrorSnackBar } from 'components/ui/SnackBar/services/SnackBarSlice.ts';
import { ignoreErrorsEndpoints } from '_constants/igonreApiCalls.ts';
import handleErrorMEssages from 'utils/handleErrorMessages.tsx';

type Result = ReturnType<typeof baseQuery>;

const mutex = new Mutex();
interface ErrorData {
  message?: string;
}
const baseUrl = `${import.meta.env.VITE_BASE_URL}`;
export const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers) => {
    headers.set('client-type', '1');

    return headers;
  },
  credentials: 'include',
});

export const baseQueryWithReauth = async (
  args: FetchArgs,
  api: BaseQueryApi,
  extraOptions: BaseQueryExtraOptions,
) => {
  await mutex.waitForUnlock();
  let result: Result = await baseQuery(args, api, extraOptions);
  if ((result?.error && result.error.status === 500) || result?.error?.status === 'FETCH_ERROR') {
    router.navigate(`/${RouterPaths.Error}`);
  }

  if (result?.error && result.error.status !== 401) {
    const error = (result?.error?.data as ErrorData)?.message;

    !ignoreErrorsEndpoints.includes(api.endpoint) &&
      api.dispatch(
        openErrorSnackBar({
          message: error ? handleErrorMEssages(error) : 'Something went wrong',
        }),
      );
  }

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const authResult = await baseQuery(loginApiRoutes.REFRESH_TOKEN, api, extraOptions);
        if (authResult?.data) {
          setLSData(TOKEN_KEY, (authResult.data as IUserData).accessTokenSignature);
          result = await baseQuery(args, api, extraOptions);
        } else {
          removeLSData(TOKEN_KEY);
          router.navigate(`/${RouterPaths.Login}`);
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};
export const api = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
