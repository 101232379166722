import { FilterTypesEnum, IItemFields } from '../types';
import { useSearchParams } from 'react-router-dom';

interface Args {
  propertyName: string;
  selected: string[] | string;
  comparisonType: string;
  type: FilterTypesEnum;
  onClose: () => void;
}

export const useFilters = ({ propertyName, selected, comparisonType, type, onClose }: Args) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsFilters = searchParams.get('filters');

  const handleSubmit = () => {
    onClose();
    if (paramsFilters) {
      setSearchParams((prev) => {
        const parsedFilters: IItemFields[] = JSON.parse(paramsFilters);
        if (parsedFilters.length >= 1) {
          const sameFilter = parsedFilters.find(
            (el: IItemFields) => el.PropertyName === propertyName,
          );
          if (sameFilter) {
            if (selected.length > 0) {
              prev.set(
                'filters',
                JSON.stringify(
                  parsedFilters.map((el: IItemFields) => {
                    return el.PropertyName === propertyName
                      ? {
                          ...el,
                          Values: selected,
                          CheckedItems: selected,
                          ComparisonType: comparisonType,
                          TypeForUi: comparisonType,
                        }
                      : { ...el, ComparisonType: comparisonType };
                  }),
                ),
              );
            } else {
              prev.set(
                'filters',
                JSON.stringify(
                  parsedFilters.filter((el: IItemFields) => el.PropertyName !== propertyName),
                ),
              );
            }
          } else {
            if (selected.length > 0) {
              prev.set(
                'filters',
                JSON.stringify([
                  ...parsedFilters,
                  {
                    PropertyName: propertyName,
                    Search: '',
                    Values: selected,
                    CheckedItems: selected,
                    ComparisonType: comparisonType,
                    TypeForUi: comparisonType,
                    ColumnType: type,
                  },
                ]),
              );
            } else {
              prev.set(
                'filters',
                JSON.stringify(
                  parsedFilters.filter((el: IItemFields) => el.PropertyName !== propertyName),
                ),
              );
            }
          }
          prev.set('page', JSON.stringify(1));
          return prev;
        } else {
          if (selected.length > 0) {
            prev.set(
              'filters',
              JSON.stringify([
                {
                  PropertyName: propertyName,
                  Search: '',
                  Values: selected,
                  CheckedItems: selected,
                  ComparisonType: comparisonType,
                  TypeForUi: comparisonType,
                  ColumnType: type,
                },
              ]),
            );
          } else {
            prev.delete('filters');
          }
        }
        prev.set('page', JSON.stringify(1));
        return prev;
      });
      return;
    }
    setSearchParams((prev) => {
      if (selected && selected.length > 0) {
        prev.set(
          'filters',
          JSON.stringify([
            ...(paramsFilters ? JSON.parse(paramsFilters) : []),
            {
              PropertyName: propertyName,
              Search: '',
              Values: selected,
              CheckedItems: selected,
              ComparisonType: comparisonType,
              TypeForUi: comparisonType,
              ColumnType: type,
            },
          ]),
        );
        prev.set('page', JSON.stringify(1));
        return prev;
      }
      prev.set('page', JSON.stringify(1));
      return prev;
    });
  };

  return { handleSubmit };
};
