import { Button, Modal, Typography } from 'antd';
import React, { ReactNode } from 'react';
import { ModalProps } from '../../../types';
import { useAppDispatch, useToken } from '../../../hooks';
import { modalDispatcher } from '../../utils/Modal/services/modalSlice.ts';
import { MODALS } from '_constants/constants.ts';

type ConfirmModalProps = {
  title: string;
  message: string;
  action: () => void;
  cancelAction?: () => void;
  confirmButtonText?: string;
  cancelButtonText?: string;
  description?: string;
  icon?: ReactNode;
} & ModalProps;

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title,
  action,
  cancelAction,
  cancelButtonText,
  confirmButtonText,
  message,
}) => {
  const dispatch = useAppDispatch();
  const { fontSize16 } = useToken();
  const { closeModal } = modalDispatcher(dispatch);
  const onModalClose = () => {
    cancelAction?.();
    closeModal({ modalType: MODALS.CONFIRM_MODAL });
  };

  return (
    <Modal
      open={true}
      title={title}
      onOk={action}
      onCancel={onModalClose}
      centered
      footer={[
        <Button key="cancel" onClick={onModalClose}>
          {cancelButtonText}
        </Button>,
        <Button key={'action'} type="primary" onClick={action}>
          {confirmButtonText}
        </Button>,
      ]}
    >
      {message && <Typography style={{ fontSize: fontSize16 }}>{message}</Typography>}
    </Modal>
  );
};

export default ConfirmModal;
