import { Button, Flex, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouterPaths } from 'router/constants.ts';
import { IGetAllJobs, useDeleteJobsMutation, useGetAllJobsQuery } from 'features/jobs';
import { useEffect } from 'react';
import { filtersConvertor, IDeleteArgs, PagesWrapper, Table, TABLE_KEY_ENUM } from 'components';
import { jobsTableColumns, jobsTableStructure } from '../../constants';
import { convertOrder } from 'utils/helpers.ts';

const JobsWrapper = () => {
  const navigate = useNavigate();
  const [deleteJobs] = useDeleteJobsMutation();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page'));
  const pageSize = Number(searchParams.get('pageSize'));
  const filters = searchParams.get('filters');
  const paramsOrderBy = searchParams.get('orderBy');

  const { data: jobsData, isFetching } = useGetAllJobsQuery({
    Page: page || 1,
    PageSize: pageSize || 10,
    Filter: filtersConvertor(filters ? JSON.parse(filters) : []),
    OrderBy: convertOrder(paramsOrderBy),
  });

  const handleCreate = () => {
    navigate(`/admin/${RouterPaths.Jobs_create}`);
  };

  useEffect(() => {
    if (!page && !pageSize) {
      setSearchParams((prev) => ({ ...prev, pageSize: 10, page: 1 }));
      return;
    }
    if (!page) {
      setSearchParams((prev) => ({ ...prev, page: 1 }));
      return;
    }
    if (!pageSize) {
      setSearchParams((prev) => ({ ...prev, pageSize: 10 }));
      return;
    }
  }, []);

  const editAction = (data: IGetAllJobs) => {
    navigate(`/admin/jobs/edit/${data.id}?view=false`);
  };

  const deleteAction = ({ filters }: IDeleteArgs<IGetAllJobs>) => {
    deleteJobs(filters);
  };

  const rowClickAction = (record: IGetAllJobs) => {
    navigate(`/admin/jobs/edit/${record.id}?view=true`);
  };

  return (
    <PagesWrapper>
      <Flex justify={'space-between'} align={'center'}>
        <Typography>Vacancies</Typography>
        <Button type={'primary'} onClick={handleCreate}>
          Create new vacancy
        </Button>
      </Flex>
      <Table<IGetAllJobs>
        loading={isFetching}
        data={jobsData?.data || []}
        tableKey={TABLE_KEY_ENUM.JOBS}
        total={jobsData?.totalCount}
        columns={jobsTableColumns}
        tableStructure={jobsTableStructure}
        deleteAction={deleteAction}
        editAction={editAction}
        onRowClick={rowClickAction}
        exportTrigger={false}
        canBeSave
      />
    </PagesWrapper>
  );
};

export default JobsWrapper;
