import { ITableActionType, ITableReducer, TCustomize } from '../types';
import { arrayMove } from '@dnd-kit/sortable';

enum tableReducerActions {
  SET_CHECKED_ITEMS_IDS = 'setCheckItemsIds',
  SET_CHECKED_ITEMS = 'setCheckItems',
  SET_SELECTED_ALL = 'setSelectedAll',
  SET_PAGE = 'setPage',
  SET_PAGE_SIZE = 'setPageSize',
  ON_SHOW_COLUMN = 'onShowColumn',
  DRAG_END_EVENT = 'dragEndEvent',
  DRAG_OVER_EVENT = 'dragOverEvent',
  TOGGLE_FIX_COLUMN = 'toggleFixColumn',
  SET_TABLE_COLUMNS = 'setTableColumns',
  SET_DEFAULT_CUSTOMIZE = 'setDefaultCustomize',
  TOGGLE_CHECK_ALL = 'toggleCheckAll',
}

function tableReducer<T>(state: ITableReducer<T>, action: ITableActionType<T>) {
  const { type, payload } = action;
  switch (type) {
    case tableReducerActions.SET_CHECKED_ITEMS:
      return { ...state, checkedItems: payload };
    case tableReducerActions.SET_CHECKED_ITEMS_IDS:
      return { ...state, checkedItemsIds: payload };
    case tableReducerActions.SET_SELECTED_ALL:
      return { ...state, selectedAll: payload };
    case tableReducerActions.SET_PAGE:
      return { ...state, page: payload };
    case tableReducerActions.SET_PAGE_SIZE:
      return { ...state, pageSize: payload };
    case tableReducerActions.ON_SHOW_COLUMN: {
      return {
        ...state,
        customizeColumns: state.customizeColumns.map((el) =>
          el.value === payload ? { ...el, visible: !el.visible } : el,
        ),
      };
    }
    case tableReducerActions.TOGGLE_FIX_COLUMN: {
      return {
        ...state,
        customizeColumns: state.customizeColumns.map((el) =>
          el.value === payload ? { ...el, fixed: !el.fixed } : el,
        ),
      };
    }
    case tableReducerActions.TOGGLE_CHECK_ALL: {
      return {
        ...state,
        isCheckAll: payload,
      };
    }
    case tableReducerActions.DRAG_END_EVENT: {
      const { over, active } = payload;
      const activeIndex = state.tableColumns.findIndex((i) => i.key === active.id);
      const overIndex = state.tableColumns.findIndex((i) => i.key === over?.id);
      if (overIndex < 0) return state;
      if (!state.tableColumns[overIndex].title) {
        return state;
      }
      return {
        ...state,
        tableColumns: arrayMove(state.tableColumns, activeIndex, overIndex),
        customizeColumns: arrayMove(state.customizeColumns, activeIndex, overIndex),
      };
    }
    case tableReducerActions.SET_TABLE_COLUMNS: {
      const savedConfig = state.customizeColumns;
      const order = savedConfig.map((item: TCustomize) => item.value);
      const tableColumns = payload.sort((a, b) => {
        const aIndex = order.indexOf(a.key);
        const bIndex = order.indexOf(b.key);
        if (!a.title) return 1;
        if (!b.title) return -1;

        return aIndex - bIndex;
      });
      return { ...state, tableColumns: tableColumns };
    }
    case tableReducerActions.SET_DEFAULT_CUSTOMIZE: {
      const config = JSON.parse(payload);
      const order = config.map((item: TCustomize) => item.value);

      const tableColumns = state.tableColumns.sort((a, b) => {
        const aIndex = order.indexOf(a.key);
        const bIndex = order.indexOf(b.key);
        if (!a.title) return 1;
        if (!b.title) return -1;

        return aIndex - bIndex;
      });
      return { ...state, customizeColumns: config, tableColumns };
    }
    default:
      return state;
  }
}

export { tableReducer, tableReducerActions };
