import { SVGProps, memo } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={72}
    height={72}
    fill="none"
    {...props}
  >
    <g fill="#11024D" clipPath="url(#a)">
      <path d="M40.44 66.188h-8.752a.75.75 0 1 0 0 1.5h8.752a.75.75 0 1 0 0-1.5Z" />
      <path d="M59.415 31.65H57.75V3.503A3.503 3.503 0 0 0 54.248 0H17.753a3.503 3.503 0 0 0-3.503 3.502V9.75h-3.9a.75.75 0 0 0-.75.75V36a.75.75 0 0 0 .75.75h3.9v6.697h-4.02a.75.75 0 0 0-.75.75v11.865a.75.75 0 0 0 .75.75h4.02v11.686A3.502 3.502 0 0 0 17.753 72h36.495a3.503 3.503 0 0 0 3.502-3.502V45.052h1.665a.75.75 0 0 0 .75-.75V32.4a.75.75 0 0 0-.75-.75ZM15.75 3.503A2.01 2.01 0 0 1 17.753 1.5h36.495a2.01 2.01 0 0 1 2.002 2.002V4.89a3.42 3.42 0 0 0-2.002-.637H17.753a3.42 3.42 0 0 0-2.003.637V3.503ZM11.1 11.25h19.837v11.422L28.5 20.25a.75.75 0 0 0-1.065 0l-2.25 2.25L21 18.262a.75.75 0 0 0-1.058 0l-8.842 8.85V11.25Zm19.837 16.927L26.25 23.52l1.695-1.695 3 3-.008 3.352ZM11.1 35.25v-6l9.383-9.375L30.937 30.3v4.95H11.1Zm-.12 20.093V44.916h20.085v10.395l-20.085.03Zm45.27 13.154a2.01 2.01 0 0 1-2.002 2.003H17.753a2.01 2.01 0 0 1-2.003-2.002V63a3.472 3.472 0 0 0 2.003.63h36.495A3.472 3.472 0 0 0 56.25 63v5.498Zm0-8.37a2.003 2.003 0 0 1-2.002 1.995H17.753a2.002 2.002 0 0 1-2.003-1.995v-3.315h16.065a.75.75 0 0 0 .75-.75V44.168a.75.75 0 0 0-.75-.75H15.75V36.75h15.938a.75.75 0 0 0 .75-.75V10.5a.75.75 0 0 0-.75-.75H15.75V7.747a2.003 2.003 0 0 1 2.003-1.994h36.495a2.002 2.002 0 0 1 2.002 1.994V31.65H37.83a.75.75 0 0 0-.75.75v11.903a.75.75 0 0 0 .75.75h18.42v15.075Zm2.415-16.575H38.58V33.15h20.085v10.403Z" />
      <path d="M45.127 22.253A4.253 4.253 0 1 0 40.875 18a4.26 4.26 0 0 0 4.252 4.253Zm0-7.005a2.753 2.753 0 1 1 0 5.505 2.753 2.753 0 0 1 0-5.505Z" />
      <path d="M40.627 26.122v-.682h9v.682a.75.75 0 0 0 .75.75h2.873a.75.75 0 0 0 .75-.75V23.25a.75.75 0 0 0-.75-.75h-.69v-9h.69a.75.75 0 0 0 .75-.75V9.877a.75.75 0 0 0-.75-.75h-2.873a.75.75 0 0 0-.75.75v.683h-9v-.683a.75.75 0 0 0-.75-.75h-2.88a.75.75 0 0 0-.75.75v2.873a.75.75 0 0 0 .75.75h.69v9h-.69a.75.75 0 0 0-.75.75v2.872a.75.75 0 0 0 .75.75h2.88a.75.75 0 0 0 .75-.75Zm11.873-.75h-1.373V24H52.5v1.372Zm0-14.745V12h-1.373v-1.373H52.5Zm-14.753 0h1.38V12h-1.38v-1.373Zm1.44 2.873h.69a.75.75 0 0 0 .75-.75v-.69h9v.69a.75.75 0 0 0 .75.75h.683v9h-.683a.75.75 0 0 0-.75.75v.69h-9v-.69a.75.75 0 0 0-.75-.75h-.69v-9Zm-1.44 11.872V24h1.38v1.372h-1.38ZM18 32.37h6a.75.75 0 1 0 0-1.5h-6a.75.75 0 0 0 0 1.5Zm37.642 2.985H41.61a.75.75 0 0 0 0 1.5h14.032a.75.75 0 1 0 0-1.5Zm-8.392 4.312h-5.64a.75.75 0 0 0 0 1.5h5.64a.75.75 0 1 0 0-1.5Zm8.392 0h-4.927a.75.75 0 0 0 0 1.5h4.927a.75.75 0 1 0 0-1.5Zm-41.64 8.948h14.033a.75.75 0 1 0 0-1.5H14.002a.75.75 0 0 0 0 1.5Zm5.64 4.312a.75.75 0 1 0 0-1.5h-5.64a.75.75 0 0 0 0 1.5h5.64Zm8.393-1.5h-4.92a.75.75 0 0 0 0 1.5h4.92a.75.75 0 1 0 0-1.5Zm9.045 1.485v3.15a.75.75 0 0 0 1.5 0v-.825h14.67v.825a.75.75 0 0 0 1.5 0v-3.15a.75.75 0 0 0-1.5 0v.825H38.58v-.825a.75.75 0 0 0-1.5 0Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h72v72H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
