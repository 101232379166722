import { TBase36Id } from 'types';
import { TableColumnsType, TableProps } from 'antd';
import { FiltersWrapper, FilterTypesEnum } from 'components';
import { format } from 'date-fns';
import { IGetAllJobs } from '../types/types.ts';

export enum JobManagementTypeEnum {
  'CREATE' = 'Create new',
  'EDIT' = 'Edit',
}

export const JOB_LOCATION = {
  'On-site': 2,
  Remote: 1,
  Hybrid: 3,
};
export enum JobLocationEnum {
  'On-site' = 2,
  Remote = 1,
  Hybrid = 3,
}

export const JOB_TYPES = {
  'Full-Time': 1,
  'Part-Time': 2,
  Freelance: 3,
};
export enum JobTypesEnum {
  'Full-Time' = 1,
  'Part-Time' = 2,
  Freelance = 3,
}

export const jobsApiRoutes = {
  JOBS: 'v1/jobs',
  JOB_BY_ID: (id: TBase36Id) => `v1/jobs/${id}`,
};

export const jobsTableColumns: TableColumnsType<IGetAllJobs> = [
  {
    title: 'ID',
    width: 80,
    dataIndex: 'id',
    key: 'Id',
    align: 'center',
    sorter: true,
    render: (value) => {
      return (
        <div title={value} style={{ color: '#4E37D3' }}>
          {value}
        </div>
      );
    },
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.BASE_36_ID}
          name={'ID'}
          endpointPath={'jobs'}
          propertyName={'Id'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Title',
    width: 150,
    dataIndex: 'title',
    key: 'Title',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'Title'}
          endpointPath={'jobs'}
          propertyName={'Title'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Location',
    width: 150,
    dataIndex: 'location',
    key: 'Location',
    align: 'center',
    sorter: true,
    render: (_, rowData) => (
      <div title={JobLocationEnum[rowData.location]}>{JobLocationEnum[rowData.location]}</div>
    ),
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          onClose={close}
          type={FilterTypesEnum.ENCRYPTED_DATA}
          name={'Location'}
          propertyName={'Location'}
          options={Object.keys(JOB_LOCATION).map((el) => ({
            label: el,
            value: JOB_LOCATION[el as keyof typeof JOB_LOCATION],
          }))}
        />
      );
    },
  },
  {
    title: 'Type',
    width: 150,
    dataIndex: 'type',
    key: 'Type',
    align: 'center',
    sorter: true,
    render: (_, rowData) => (
      <div title={JobTypesEnum[rowData.type]}>{JobTypesEnum[rowData.type]}</div>
    ),
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          onClose={close}
          type={FilterTypesEnum.ENCRYPTED_DATA}
          name={'Type'}
          propertyName={'Type'}
          options={Object.keys(JOB_LOCATION).map((el) => ({
            label: el,
            value: JOB_TYPES[el as keyof typeof JOB_TYPES],
          }))}
        />
      );
    },
  },
  {
    title: 'Creation Date',
    width: 150,
    dataIndex: 'createdAt',
    key: 'CreatedAt',
    render: (value) => {
      return (
        <div title={String(format(value, 'dd/MM/yyyy hh:mm'))}>
          {format(value, 'dd/MM/yyyy hh:mm')}
        </div>
      );
    },
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          onClose={close}
          type={FilterTypesEnum.DATE}
          name={'Creation Date'}
          endpointPath={'users'}
          propertyName={'CreatedAt'}
        />
      );
    },
  },
];

export const jobsTableStructure: TableProps = {
  bordered: true,
  size: 'small',
  scroll: {
    y: 500,
    x: 800,
    scrollToFirstRowOnChange: true,
  },
};
