import { useGetTechnologyQuery } from 'features/landing/services/landingApi';
import { TTechnology } from 'features/landing/types';
import { lazy, useState, useEffect } from 'react';
import { Container } from 'components';
import { useDarkMode } from 'context';
const Carousel = lazy(() => import('components/shared/Carousel/Carousel'));

// Import other necessary hooks and components

export default function Technologies() {
  const { data, error, isLoading } = useGetTechnologyQuery();
  const { isDarkMode } = useDarkMode();

  const [imagesToShow, setImagesToShow] = useState<string[]>([]);

  useEffect(() => {
    // Separate images based on imageType
    const darkModeImages: string[] =
      data
        ?.filter((item: TTechnology) => item.colorMode === 2)
        .map((item: TTechnology) => item.imageUrl) || [];
    const lightModeImages: string[] =
      data
        ?.filter((item: TTechnology) => item.colorMode === 1)
        .map((item: TTechnology) => item.imageUrl) || [];

    // Decide which images to display based on isDarkMode
    setImagesToShow(isDarkMode ? darkModeImages : lightModeImages);
  }, [isDarkMode, data]); // Dependency array includes isDarkMode and data to re-run effect when they change

  if (isLoading) return <></>;
  if (error) return <></>;

  return (
    <Container data-component="Technologies">
      <h1 aria-label="carousel" />
      <div style={{ minHeight: 209 }}>
        <Carousel key="1" images={imagesToShow} />
        <Carousel key="2" images={imagesToShow} direction="left" />
      </div>
    </Container>
  );
}
