import styles from './Grid.module.scss';
import { team_1, team_2, team_3, team_4, team_5, team_6, team_7, team_8 } from 'assets';

const gridList = [
  {
    id: 1,
    image: team_3,
  },
  {
    id: 2,
    image: team_5,
  },
  {
    id: 3,
    image: team_2,
  },
  {
    id: 4,
    image: team_4,
  },
  {
    id: 5,
    image: team_1,
  },
  {
    id: 6,
    image: team_6,
  },
  {
    id: 7,
    image: team_7,
  },
  {
    id: 8,
    image: team_8,
  },
] as const;

export default function Grid() {
  const handleContextMenu = (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
  };
  return (
    <div className={styles.grid}>
      {gridList.map(({ image, id }) => (
        <div key={id} className={styles[`childe-${id}`]}>
          <img
            width={400}
            onContextMenu={handleContextMenu}
            height={400}
            src={image}
            loading="lazy"
            alt={`Image ${id}`}
          />
        </div>
      ))}
    </div>
  );
}
