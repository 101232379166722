import React from 'react';
import styles from './Collaps.module.scss';
import { UpOutlined } from '@ant-design/icons';

export default function Collapse({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div
      role="button"
      aria-label="toggle"
      className={styles.animatedCard}
      onClick={() => setIsOpen((prev) => !prev)}
    >
      <div className={styles.faqCntent}>
        <div className={styles.titleWrapper}>
          <button className={`${styles.collapseButton} ${isOpen ? styles.buttonOpen : ''}`}>
            <UpOutlined />
          </button>
          <h2>{title}</h2>
        </div>
        <div className={`${styles.collapseInner} ${isOpen ? styles.open : ''}`}>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </div>
  );
}
