import QuoteForm from './QuoteForm';
import styles from './HelpSection.module.scss';
import { Ellipse } from 'components';
import useWindowSize from 'hooks/useWindowSize';
export default function HelpSection() {
  const { isMobile } = useWindowSize();
  return (
    <section className={styles.helpSection}>
      {isMobile ? null : (
        <Ellipse
          width={isMobile ? 100 : 289}
          height={isMobile ? 80 : 214}
          color="rgba(78, 55, 211, 0.50)"
          right={250}
          top={74}
        />
      )}
      {isMobile ? null : (
        <Ellipse width={197} height={145} color="rgba(62, 204, 153, 0.72)" right={101} top={200} />
      )}

      <div className={styles.helpSectionInner}>
        <div className={styles.content}>
          <div
            style={{
              borderRadius: '16px',
              overflow: 'hidden',
              boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
            }}
          >
            <iframe
              className={styles.iframe}
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://maps.google.com/maps?q=${40.2137004},${44.5208088}&output=embed`}
            ></iframe>
          </div>
          <QuoteForm />
        </div>
      </div>
    </section>
  );
}
