import styles from './Chip.module.scss';

export default function Chip({ title, color }: { title: string; color: string }) {
  return (
    <div
      style={{
        background: color,
        color: '#000',
        borderRadius: '16px',
      }}
      className={styles.chip}
    >
      {title}
    </div>
  );
}
