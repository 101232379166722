import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} fill="none" {...props}>
    <path
      fill="#474C63"
      d="M6 8.681a.836.836 0 0 1-.281-.046.65.65 0 0 1-.244-.16l-2.7-2.7a.711.711 0 0 1-.206-.525c0-.213.069-.388.206-.525a.754.754 0 0 1 .535-.215.694.694 0 0 1 .534.196L5.25 6.112V.75c0-.212.072-.39.216-.535A.723.723 0 0 1 6 0c.213 0 .39.072.535.216A.723.723 0 0 1 6.75.75v5.362l1.406-1.406a.695.695 0 0 1 .535-.197.75.75 0 0 1 .534.216.711.711 0 0 1 .206.525.711.711 0 0 1-.206.525l-2.7 2.7a.662.662 0 0 1-.244.16A.812.812 0 0 1 6 8.68ZM1.5 12c-.413 0-.766-.147-1.06-.441A1.442 1.442 0 0 1 0 10.5V9c0-.213.072-.39.216-.535A.723.723 0 0 1 .75 8.25c.213 0 .39.072.535.216A.723.723 0 0 1 1.5 9v1.5h9V9c0-.213.072-.39.216-.535a.723.723 0 0 1 .534-.215c.213 0 .39.072.535.216A.723.723 0 0 1 12 9v1.5c0 .412-.147.766-.441 1.06-.294.294-.647.44-1.059.44h-9Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
