import { Button, Flex, Typography } from 'antd';
import { useAppDispatch } from 'hooks';
import { modalDispatcher } from 'components/utils/Modal/services/modalSlice.ts';
import { MODALS } from '_constants/constants.ts';
import { UsersModalEnum, usersTableColumns, usersTableStructure } from '../../constants';
import { useDeleteUsersMutation, useGetUsersQuery } from '../../services/usersApi.ts';
import { IGetUsers } from '../../types';
import { filtersConvertor, IDeleteArgs, PagesWrapper, Table } from 'components';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { TABLE_KEY_ENUM } from 'components/utils/Table';
import { convertOrder } from 'utils/helpers.ts';

const UsersWrapper = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [deleteUsers] = useDeleteUsersMutation();
  const page = Number(searchParams.get('page'));
  const pageSize = Number(searchParams.get('pageSize'));
  const filters = searchParams.get('filters');
  const paramsOrderBy = searchParams.get('orderBy');
  const dispatch = useAppDispatch();
  const { openModal } = modalDispatcher(dispatch);

  const handleOpenModal = (type: UsersModalEnum, data?: IGetUsers) => {
    openModal({
      modalType: MODALS.USERS_MODAL,
      props: {
        type,
        data,
      },
    });
  };

  const { data: usersData, isFetching } = useGetUsersQuery({
    Page: page || 1,
    PageSize: pageSize || 10,
    Filter: filtersConvertor(filters ? JSON.parse(filters) : []),
    OrderBy: convertOrder(paramsOrderBy),
  });

  useEffect(() => {
    if (!page && !pageSize) {
      setSearchParams((prev) => ({ ...prev, pageSize: 10, page: 1 }));
      return;
    }
    if (!page) {
      setSearchParams((prev) => ({ ...prev, page: 1 }));
      return;
    }
    if (!pageSize) {
      setSearchParams((prev) => ({ ...prev, pageSize: 10 }));
      return;
    }
  }, []);

  const editAction = (value: IGetUsers) => {
    handleOpenModal(UsersModalEnum.EDIT, value);
  };

  const deleteAction = ({ filters }: IDeleteArgs<IGetUsers>) => {
    deleteUsers(filters);
  };

  return (
    <PagesWrapper>
      <Flex justify={'space-between'} align={'center'}>
        <Typography>Users</Typography>
        <Button type={'primary'} onClick={() => handleOpenModal(UsersModalEnum.CREATE)}>
          Create new User
        </Button>
      </Flex>
      <Table<IGetUsers>
        loading={isFetching}
        data={usersData?.data || []}
        tableKey={TABLE_KEY_ENUM.USERS}
        total={usersData?.totalCount}
        columns={usersTableColumns}
        tableStructure={usersTableStructure}
        editAction={editAction}
        deleteAction={deleteAction}
        canBeSave
      />
    </PagesWrapper>
  );
};

export default UsersWrapper;
