import { TBase36Id } from 'types';
import { TableColumnsType, TableProps } from 'antd';
import { format } from 'date-fns';
import { IGetJobApplies } from '../types';
import { FiltersWrapper, FilterTypesEnum } from 'components';
import HandleBoolean from 'utils/handleBoolean';
import StatusCard from 'components/ui/StatusCard/StatusCard';
import { REVIEWED, REJECT } from '_constants';
import { DownloadOutlined } from '@ant-design/icons';

export const jobsAppliesApiRoutes = {
  JOB_APPLIES: 'v1/job-applicants',
  JOB_APPLIES_BY_ID: (id: TBase36Id) => `v1/job-applicants/${id}`,
  JOB_CHANGE_STATUS: (id: TBase36Id) => `v1/job-applicants/email/${id}`,
};

export enum JobAppliesModalEnum {
  VIEW = 'View',
}

export enum JobAppliesEnum {
  Applied = 0,
  Reviewed = 1,
  Rejected = 2,
}
export enum JobAppliesModalEnum {
  Reject = 0,
  Review = 1,
}

export const jobAppliesTableColumns: TableColumnsType<IGetJobApplies> = [
  {
    title: 'ID',
    width: 100,
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    sorter: true,
    render: (value) => {
      return (
        <div title={value} style={{ color: '#4E37D3' }}>
          {value}
        </div>
      );
    },
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.BASE_36_ID}
          name={'ID'}
          endpointPath={'job-applicants'}
          propertyName={'Id'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Creation Date',
    width: 150,
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    sorter: true,
    render: (value) => {
      return (
        <div title={String(format(value, 'dd/MM/yyyy hh:mm'))}>
          {format(value, 'dd/MM/yyyy hh:mm')}
        </div>
      );
    },
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.DATE}
          name={'CreatedAt'}
          endpointPath={'job-applicants'}
          propertyName={'CreatedAt'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'First name',
    width: 150,
    dataIndex: 'firstName',
    key: 'FirstName',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'FirstName'}
          endpointPath={'job-applicants'}
          propertyName={'FirstName'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Email Address',
    width: 150,
    dataIndex: 'email',
    key: 'Email',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'Email'}
          endpointPath={'job-applicants'}
          propertyName={'Email'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Cover Letter',
    width: 150,
    dataIndex: 'coverLetter',
    key: 'CoverLetter',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.BASE_36_ID}
          name={'CoverLetter'}
          endpointPath={'job-applicants'}
          propertyName={'CoverLetter'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Last Name',
    width: 150,
    dataIndex: 'lastName',
    key: 'LastName',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'LastName'}
          endpointPath={'job-applicants'}
          propertyName={'LastName'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Minimum Salary',
    width: 150,
    dataIndex: 'minSalary',
    key: 'MinimumSalary',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'MinSalary'}
          endpointPath={'job-applicants'}
          propertyName={'MinSalary'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Phone Number',
    width: 150,
    dataIndex: 'phoneNumber',
    key: 'PhoneNumber',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'PhoneNumber'}
          endpointPath={'job-applicants'}
          propertyName={'PhoneNumber'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Status',
    width: 150,
    dataIndex: 'status',
    key: 'Status',
    align: 'center',
    sorter: true,
    render(value) {
      return (
        <div
          title={String(JobAppliesEnum[value as keyof typeof JobAppliesEnum])}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <StatusCard status={value} text={JobAppliesEnum[value as keyof typeof JobAppliesEnum]} />
        </div>
      );
    },
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TAGS}
          name={'Status'}
          propertyName={'Status'}
          onClose={close}
          options={[
            { label: 'Applied', value: 0 },
            { label: 'Rejected', value: 1 },
            { label: 'Reviewed', value: 2 },
          ]}
        />
      );
    },
  },
  {
    title: 'Reject Email Sent',
    width: 150,
    dataIndex: 'rejectEmailSent',
    key: 'RejectEmailSent',
    align: 'center',
    sorter: true,
    render(value) {
      return <HandleBoolean value={value} trueText={REVIEWED} falseText={REJECT} />;
    },
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.BOOLEAN}
          name={'RejectEmailSent'}
          endpointPath={'job-applicants'}
          propertyName={'RejectEmailSent'}
          onClose={close}
          options={[
            { label: 'Review', value: 'true' },
            { label: 'Rejected', value: 'false' },
          ]}
        />
      );
    },
  },
  {
    title: 'Title',
    width: 150,
    dataIndex: 'title',
    key: 'Title',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'Title'}
          endpointPath={'job-applicants'}
          propertyName={'Title'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'CV file',
    width: 150,
    dataIndex: 'cvUrl',
    key: 'CvUrl',
    align: 'center',
    sorter: true,
    render: (value) => {
      return (
        <a
          title={''}
          style={{ display: 'flex', gap: 8, alignItems: 'center' }}
          rel="noreferrer"
          href={value}
          target={'_blank'}
          onClick={(e) => e.stopPropagation()}
        >
          <DownloadOutlined />
          Download CV
        </a>
      );
    },
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'CvUrl'}
          endpointPath={'job-applicants'}
          propertyName={'CvFileId'}
          onClose={close}
        />
      );
    },
  },
];

export const jobAppliesTableStructure: TableProps = {
  bordered: true,
  size: 'small',
  scroll: {
    y: 500,
    x: 800,
    scrollToFirstRowOnChange: true,
  },
};
