import { useEffect, useRef } from 'react';
import './Carousel.scss'; // Assuming you move the CSS to a separate file

const InfiniteScrollAnimation = ({
  images = [],
  direction = 'right',
  speed = 'slow',
}: {
  images: string[];
  direction?: 'right' | 'left';
  speed?: 'slow' | 'fast';
}) => {
  const scrollerRef = useRef<HTMLDivElement | null>(null);
  const scrollerInnerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // const scrollers = document.querySelectorAll(".scroller");
    const scroller = scrollerRef.current;
    const scrollerInner = scrollerInnerRef.current;

    if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      addAnimation();
    }

    function addAnimation() {
      if (!scroller || !scrollerInner) return;
      scroller.setAttribute('data-animated', 'true');
      const scrollerContent = Array.from(scrollerInner.children);
      scrollerContent.forEach((item) => {
        if (item instanceof Element) {
          const duplicatedItem = item.cloneNode(true);
          if (duplicatedItem instanceof Element) {
            duplicatedItem.setAttribute('aria-hidden', 'true');
            scrollerInner.appendChild(duplicatedItem);
          }
        }
      });
    }
  }, []);

  return (
    <div>
      <div ref={scrollerRef} className="scroller" data-direction={direction} data-speed={speed}>
        <div ref={scrollerInnerRef} className="scroller__inner">
          {images.map((image) => (
            <img loading="lazy" width={170} height={50} key={image} src={image} alt={image} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default InfiniteScrollAnimation;
