import { api } from 'api';
import { TFaq, TJob, TJobById, TQuote, TTechnology } from '../types';
import { landingApiRoutes } from '../constants';

const landingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTechnology: build.query<TTechnology[], void>({
      query: () => ({
        url: landingApiRoutes.technology,
      }),
    }),
    getCareer: build.query<TJob[], void>({
      query: () => ({
        url: landingApiRoutes.career,
      }),
    }),
    requestQuote: build.mutation<void, TQuote>({
      query: (data) => ({
        url: landingApiRoutes.quote,
        method: 'POST',
        body: data,
      }),
    }),
    applyForJob: build.mutation<void, FormData>({
      query: (data) => ({
        url: landingApiRoutes.applyForJob,
        method: 'POST',
        body: data,
      }),
    }),
    getJobById: build.query<TJobById, string>({
      query: (id) => ({
        url: landingApiRoutes.job(id),
      }),
    }),
    getFaq: build.query<TFaq[], void>({
      query: () => ({
        url: landingApiRoutes.faq,
      }),
    }),
  }),
});

export const {
  useGetTechnologyQuery,
  useRequestQuoteMutation,
  useApplyForJobMutation,
  useGetCareerQuery,
  useGetFaqQuery,
  useGetJobByIdQuery,
} = landingApi;
