import { RouterPaths } from '../../router/constants.ts';
import { MenuProps } from 'antd';
import {
  PicLeftOutlined,
  ProfileOutlined,
  ReconciliationOutlined,
  TeamOutlined,
} from '@ant-design/icons';

type MenuItem = Required<MenuProps>['items'][number];

export const NAVIGATION_ITEMS: MenuItem[] = [
  {
    key: 'main',
    label: 'Main',
    icon: <TeamOutlined />,
    children: [
      {
        icon: '',
        label: 'Users',
        key: RouterPaths.Users,
      },
    ],
  },
  {
    key: 'pages',
    label: 'Pages',
    icon: <PicLeftOutlined />,
    children: [
      {
        icon: '',
        label: 'Tech stack',
        key: RouterPaths.Homepage,
      },
      {
        icon: '',
        label: 'Careers page',
        key: RouterPaths.Careers,
      },
      {
        icon: '',
        label: 'Contact us',
        key: RouterPaths.ContactUs,
      },
    ],
  },
  {
    key: 'lists',
    label: 'Lists',
    icon: <ProfileOutlined />,
    children: [
      {
        icon: '',
        label: 'Vacancies',
        key: RouterPaths.Jobs,
      },
    ],
  },
  {
    key: 'requests',
    label: 'Requests',
    icon: <ReconciliationOutlined />,
    children: [
      {
        icon: '',
        label: 'Contact requests',
        key: RouterPaths.Contacts,
      },
      {
        icon: '',
        label: 'Job applicants',
        key: RouterPaths.JobApplies,
      },
    ],
  },
];

export const TABLE_ROUTES: string[] = [
  RouterPaths.JobApplies,
  RouterPaths.Contacts,
  RouterPaths.Users,
  RouterPaths.Jobs,
];
