import {
  CarOutlined,
  ClockCircleOutlined,
  CoffeeOutlined,
  FireOutlined,
  HeartOutlined,
  HomeOutlined,
  HourglassOutlined,
  MedicineBoxOutlined,
  ScheduleOutlined,
  StarOutlined,
  TeamOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import {
  ui,
  ui_white,
  coding,
  coding_white,
  web_design,
  web_design_white,
  structure,
  structure_white,
  shield,
  shield_white,
  star,
  start_white,
  puzzle,
  puzzle_white,
  hearth,
  hearth_white,
  design,
  design_white,
  sun,
  sun_white,
} from 'assets';

export const landingApiRoutes = {
  technology: 'v1/technologies',
  quote: 'v1/quote-requests',
  applyForJob: 'v1/job-applicants',
  career: 'v1/careers/admin',
  faq: '/v1/faq',
  job: (id: string) => `/v1/jobs/${id}`,
};

export const solutionCardContent = (isDark: boolean) =>
  [
    {
      id: 1,
      title: 'UI/UX Design',
      description:
        'Creating engaging and intuitive user interfaces, optimizing user experiences to enhance usability and satisfaction.',
      imageUrl: isDark ? web_design : web_design_white,
    },
    {
      id: 2,
      title: 'Web Development',
      description:
        'Building robust and scalable websites with cutting-edge technologies, tailored to meet your business objectives.',
      imageUrl: isDark ? coding : coding_white,
    },
    {
      id: 3,
      title: 'Mobile Development',
      description:
        'Crafting innovative and user-friendly mobile applications, delivering seamless experiences on the go.',
      imageUrl: isDark ? ui : ui_white,
    },
    {
      id: 4,
      title: 'Architecture Design',
      description:
        'Designing scalable and secure IT infrastructure solutions,  optimal performance and reliability.',
      imageUrl: isDark ? structure : structure_white,
    },
  ] as const;
export const valuesCardContent = (isDark: boolean) =>
  [
    {
      id: 1,
      title: 'Empathy',
      description: 'Understanding and compassion in all our interactions.',
      imageUrl: isDark ? hearth : hearth_white,
    },
    {
      id: 2,
      title: 'Atmosphere',
      description: 'A positive and inclusive environment that encourages collaboration and growth.',
      imageUrl: isDark ? sun : sun_white,
    },
    {
      id: 3,
      title: 'Creativity',
      description: 'Innovation and outside-the-box thinking to drive progress.',
      imageUrl: isDark ? design : design_white,
    },
    {
      id: 4,
      title: 'Integrity',
      description: 'The highest ethical standards and transparency in all our actions.',
      imageUrl: isDark ? puzzle : puzzle_white,
    },
    {
      id: 5,
      title: 'Stewardship',
      description: 'Responsibility for our impact on the environment and communities we serve.',
      imageUrl: isDark ? star : start_white,
    },
    {
      id: 6,
      title: 'Security',
      description:
        "The protection of our customers' data and provide a safe and secure environment.",
      imageUrl: isDark ? shield : shield_white,
    },
  ] as const;

export const teamCardContent = [
  {
    id: 1,
    title: 'What it means to work here',
    description:
      "Working at PandaTech is more than just a job; it's an opportunity to be part of something extraordinary. We foster a culture of innovation, collaboration, and personal growth. As a member of our team, you'll be empowered to unleash your full potential, contribute to groundbreaking projects, and make a real impact. We value diversity, encourage creativity, and provide a supportive environment where your ideas are valued, and your voice is heard.",
  },
  {
    id: 2,
    title: 'What our values are',
    description:
      'At PandaTech, we are guided by our core values of atmosphere, empathy, creativity, integrity, stewardship, and security. These values define who we are and how we operate. We foster a positive atmosphere that encourages collaboration and inclusivity. We prioritize empathy and understanding, fueling strong connections. Creativity is at the heart of our innovation. We uphold unwavering integrity, ensuring trust and transparency. Responsible stewardship and security are paramount in everything we do. These values inspire us to deliver excellence in all aspects of our work.',
  },
  {
    id: 3,
    title: 'What makes us unique',
    description:
      'At PandaTech, we take pride in our unique qualities that set us apart from the competition. Our unwavering commitment to excellence, innovation, and customer satisfaction is what makes us truly exceptional. With a dedicated team of talented professionals, we bring a fresh perspective and a relentless drive for success. Our collaborative approach, coupled with our agile mindset, allows us to adapt swiftly to evolving market demands. We combine cutting-edge technology with personalized solutions, ensuring that each client receives the utmost attention and tailored support. Join us and experience the unparalleled difference that makes PandaTech the preferred choice in our industry.',
  },
] as const;

export const benefits = [
  {
    id: 1,
    icon: HeartOutlined,
    title: 'Health insurance',
    description:
      'Your well-being is a big deal to us. Enjoy comprehensive health coverage for you and your family.',
  },
  {
    id: 2,
    icon: ClockCircleOutlined,
    title: 'Flexible schedules',
    description: 'Need to adjust your hours? No problem! Work in a way that fits your life.',
  },
  {
    id: 3,
    icon: HourglassOutlined,
    title: 'Work-Life Balance',
    description:
      'We support a balanced lifestyle so you can be your best self both at work and at home.',
  },
  {
    id: 4,
    icon: CarOutlined,
    title: 'Day Off',
    description: 'Take a well-deserved break whenever you need it.',
  },
  {
    id: 5,
    icon: MedicineBoxOutlined,
    title: 'Sick Leave',
    description:
      'Feeling under the weather? You have 10 sick days a year to recover and get back to your awesome self.',
  },

  {
    id: 6,
    icon: HomeOutlined,
    title: 'Time Off',
    description: 'Enjoy 2 work-from-home days per month and ample time off to stay balanced.',
  },
  {
    id: 7,
    icon: FireOutlined,
    title: 'Sports Package',
    description: 'Stay active and unwind with our sports package.',
  },
  {
    id: 8,
    icon: ScheduleOutlined,
    title: 'Annual Bonuses',
    description:
      'We love celebrating your hard work with a little extra something at the end of the year.',
  },
  {
    id: 9,
    icon: StarOutlined,
    title: 'Panda of the Month',
    description: 'Celebrating exceptional talent and dedication within our team!',
  },
  {
    id: 10,
    icon: TeamOutlined,
    title: 'Teambuilding Events',
    description: 'From fun outings to cool challenges, we mix work with play.',
  },
  {
    id: 11,
    icon: UserSwitchOutlined,
    title: 'Knowledge-Sharing Culture',
    description: 'Learn, grow, and share ideas with a team that pushes boundaries.',
  },
  {
    id: 12,
    icon: CoffeeOutlined,
    title: 'Free Refreshments',
    description: 'Unlimited tea, coffee, and snacks to keep your energy up.',
  },
] as const;
