import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import modalReducer from 'components/utils/Modal/services/modalSlice';
import snackReducer from 'components/ui/SnackBar/services/SnackBarSlice';
import loginReducer from 'features/login/services/loginSlice';
import { api } from 'api';

export const store = configureStore({
  reducer: {
    snackBar: snackReducer,
    modal: modalReducer,
    login: loginReducer,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(api.middleware),
  devTools: import.meta.env.VITE_NODE_ENV !== 'production',
});
setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
