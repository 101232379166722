import { ErrorBoundary, SnackBar } from '../../components';
import { Outlet } from 'react-router-dom';
import { auth_background, logo_white } from '../../assets';

const AuthLayout = () => {
  return (
    <ErrorBoundary fallback={<div>Error</div>}>
      <SnackBar />
      <div
        className="admin-layout"
        style={{
          backgroundImage: `url(${auth_background})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          position: 'relative',
          height: '100dvh',
          display: 'grid',
          placeContent: 'center',
        }}
      >
        <div style={{ position: 'absolute', left: 'calc(50% - 117px)', top: 24 }}>
          <img width={235} src={logo_white} alt={'panda_logo'} />
        </div>
        <div
          style={{
            width: '584px',
            border: '1px solid #fff',
            borderRadius: '16px',
            padding: 48,
            backdropFilter: 'blur(4px)',
          }}
        >
          <Outlet />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default AuthLayout;
