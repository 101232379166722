import { ApplyForJob } from './components';
import { TJobById } from './types';
import Job from './components/Job/Job.tsx';

type TJob = {
  data?: TJobById;
};
export default function JobLanding({ data }: TJob) {
  return (
    <>
      <Job type={data?.type} title={data?.title} location={String(data?.location)}>
        <div className="job">
          {data?.content &&
            Object.values(JSON.parse(data?.content as string)).map((el) => {
              return (
                <div
                  style={{
                    marginTop: '32px',
                  }}
                  className="jobContent"
                  key={el as string}
                  dangerouslySetInnerHTML={{ __html: el as TrustedHTML }}
                ></div>
              );
            })}
        </div>
      </Job>
      <ApplyForJob />
    </>
  );
}
