import { apply_now, apply_white_now, vector } from 'assets';
import { Button } from 'components';
import styles from './JoinUs.module.scss';
import { Link } from 'react-router-dom';
import { useDarkMode } from 'context';

export default function JoinUs() {
  const { isDarkMode } = useDarkMode();
  return (
    <section>
      <div className={styles.container}>
        <div
          style={{
            position: 'relative',
          }}
        >
          <h1>Are you ready to join our team?</h1>
          <div className={styles.apply}>
            <img
              style={{
                transform: 'translate(-52%, 5%)',
                width: '100%',
                height: '100%',
              }}
              src={isDarkMode ? apply_white_now : apply_now}
              alt="apply_now"
            />

            <img src={vector} alt="vector" />
          </div>
        </div>
        <p className={styles.description}>
          At PandaTech, you will be part of a new tech-powered family. Together we are going to face
          lots of challenges and give powerful and creative solutions to them.Every day.
        </p>
        <Link to="/career">
          <Button>See Open Roles</Button>
        </Link>
      </div>
    </section>
  );
}
