import { SVGProps, memo } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={48} height={48} fill="none" {...props}>
    <path
      fill="#000"
      fillOpacity={0.45}
      d="m41.493 20.92-.009-.038-5.26-13.364A1.81 1.81 0 0 0 34.5 6.243H13.18c-.796 0-1.504.53-1.729 1.294L6.534 20.765l-.014.033-.01.038c-.06.23-.08.463-.046.693-.005.075-.01.15-.01.225v17.152a2.855 2.855 0 0 0 2.85 2.85h29.4c1.57 0 2.85-1.28 2.855-2.85V21.754c0-.06 0-.122-.005-.173.02-.23 0-.45-.06-.661Zm-13.865-2.016-.014.736c-.038 2.105-1.49 3.52-3.614 3.52-1.036 0-1.927-.332-2.57-.965-.641-.633-.993-1.514-1.012-2.555l-.014-.736h-9.646l3.726-9.06h18.712l3.83 9.06h-9.398Zm-17.578 3.6h7.373c1.14 2.677 3.563 4.256 6.581 4.256 1.58 0 3.047-.44 4.233-1.275 1.04-.73 1.852-1.753 2.377-2.98h7.336v15.65h-27.9v-15.65Z"
    />
  </svg>
);
const Memo = memo(SvgComponent);
export default Memo;
