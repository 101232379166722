import styles from './Ellipse.module.scss';
type TEllipse = {
  width: number | string;
  height: number | string;
  color: string;
  zIndex?: number;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
};
export default function Ellipse({
  width,
  height,
  color,
  zIndex = 1,
  top,
  right,
  left,
  bottom,
}: TEllipse) {
  return (
    <span
      className={styles.ellipse}
      style={{
        width,
        height,
        background: color,
        zIndex,
        top,
        right,
        left,
        bottom,
      }}
    ></span>
  );
}
