export const pageList = [
  'Home',
  'Dashboard',
  'Landing',
  'Welcome',
  'Settings',
  'Users',
  'Careers',
  'Jobs',
  'JobsManagement',
  'Contacts',
  'JobApplies',
  'Login',
  'Homepage',
  'Career',
  'Contact',
  'ContactUs',
  'Job',
  'ChangePassword',
  'AboutUs',
  'Technologies',
  'NotFound',
  'Maintenance',
  'Error',
] as const;

export enum RouterPaths {
  Home = '/',
  ChangePassword = 'change-password',
  Homepage = 'homepage',
  Dashboard = 'dashboard',
  Welcome = 'welcome',
  Settings = 'settings',
  Users = 'users',
  Careers = 'careers',
  ContactUs = 'contact-us',
  Jobs = 'jobs',
  Job = 'career/:id',
  Jobs_create = 'jobs/create',
  Jobs_edit = 'jobs/edit/:jobId',
  Contacts = 'contacts',
  JobApplies = 'job-applies',
  Login = 'admin',
  Career = 'career',
  Contact = 'contact',
  AboutUs = 'about-us',
  Technologies = 'technologies',
  NotFound = '*',
  Maintenance = 'maintenance',
  Error = 'something-went-wrong',
}
