import { TBase36Id } from 'types';
import { TableColumnsType, TableProps } from 'antd';
import { format } from 'date-fns';
import { IGetUsers } from '../types';
import { Switch } from 'antd';
import { store } from 'store/store.ts';
import { openModal } from 'components/utils/Modal/services/modalSlice.ts';
import { MODALS, USER_ROLES, UserRoleEnum } from '_constants';
import { usersApi } from '../services/usersApi.ts';
import { FiltersWrapper, FilterTypesEnum } from '../../../components';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

const dispatch = store.dispatch;

export enum USER_STATUS_ENUM {
  ENABLED = 1,
  DISABLED = 2,
}

export const userApiRoutes = {
  USERS: 'v1/users',
  USER_BY_ID: (id: TBase36Id) => `v1/users/${id}`,
  USER_PASSWORD: (id: TBase36Id) => `v1/users/${id}/password`,
  USER_STATUS: (id: TBase36Id) => `v1/users/${id}/status`,
};

export enum UsersModalEnum {
  CREATE = 'Create',
  EDIT = 'Edit',
}

export const usersTableColumns: TableColumnsType<IGetUsers & { draggable?: boolean }> = [
  {
    title: 'ID',
    width: 80,
    dataIndex: 'id',
    key: 'Id',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.BASE_36_ID}
          name={'ID'}
          endpointPath={'users'}
          propertyName={'Id'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Username',
    width: 150,
    dataIndex: 'username',
    key: 'Username',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'Username'}
          endpointPath={'users'}
          propertyName={'Username'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Full name',
    width: 150,
    dataIndex: 'fullName',
    key: 'FullName',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.ENCRYPTED_DATA}
          name={'Full Name'}
          endpointPath={'users'}
          propertyName={'FullName'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Creation Date',
    width: 150,
    dataIndex: 'createdAt',
    key: 'CreatedAt',
    render: (value) => {
      return (
        <div title={String(format(value, 'dd/MM/yyyy hh:mm'))}>
          {format(value, 'dd/MM/yyyy hh:mm')}
        </div>
      );
    },
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.DATE}
          name={'Creation Date'}
          endpointPath={'users'}
          propertyName={'CreatedAt'}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Role',
    width: 150,
    dataIndex: 'role',
    key: 'Role',
    align: 'center',
    sorter: true,
    render: (value) => <div title={UserRoleEnum[value]}>{UserRoleEnum[value]}</div>,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TAGS}
          name={'Role'}
          propertyName={'Role'}
          options={USER_ROLES}
          onClose={close}
        />
      );
    },
  },
  {
    title: 'Comment',
    width: 150,
    dataIndex: 'comment',
    key: 'Comment',
    align: 'center',
    sorter: true,
    filterDropdown: ({ close }) => {
      return (
        <FiltersWrapper
          type={FilterTypesEnum.TEXT}
          name={'Comment'}
          endpointPath={'users'}
          propertyName={'Comment'}
          onClose={close}
        />
      );
    },
  },
  {
    title: '',
    width: 80,
    dataIndex: 'status',
    key: 'Status',
    fixed: 'right',
    align: 'center',
    render: (value: number, rowData: IGetUsers) => {
      const handleChangeStatus = async () => {
        dispatch(
          openModal({
            modalType: MODALS.CONFIRM_MODAL,
            props: {
              title: `${value === USER_STATUS_ENUM.DISABLED ? 'Enable' : 'Disable'} user`,
              message: `Are you sure you want to ${
                value === USER_STATUS_ENUM.DISABLED ? 'enable' : 'disable'
              } this user?`,
              action: () =>
                dispatch(
                  usersApi.endpoints.changeUserStatus.initiate({
                    status: value === USER_STATUS_ENUM.ENABLED ? 2 : 1,
                    id: rowData.id,
                  }) as any,
                ),
              confirmButtonText: value === USER_STATUS_ENUM.DISABLED ? 'Enable' : 'Disable',
              cancelButtonText: 'Cancel',
            },
          }),
        );
      };
      return (
        <Switch
          title={value === USER_STATUS_ENUM.DISABLED ? 'Enable user' : 'Disable user'}
          checked={value === USER_STATUS_ENUM.ENABLED}
          onChange={handleChangeStatus}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
        />
      );
    },
  },
];

export const usersTableStructure: TableProps = {
  bordered: true,
  size: 'small',
  scroll: {
    y: 500,
    x: 800,
    scrollToFirstRowOnChange: true,
  },
};
