import { SVGProps, memo } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'1em'}
    height={'1em'}
    viewBox="0 0 38 38"
    fill="none"
    {...props}
  >
    <defs>
      <filter id="shadow" x="0" y="0" width="200%" height="200%">
        <feOffset result="offOut" in="SourceAlpha" dx={3} dy={3} />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation={3} />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
      <filter
        id="a"
        width={38}
        height={38}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          result="hardAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dx={-1} dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1543_506" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_1543_506" result="shape" />
      </filter>
    </defs>
    <g filter="url(#a)" shapeRendering="crispEdges">
      <circle cx={20} cy={18} r={10} fill="#4D39D2" />
      <circle cx={20} cy={18} r={14} stroke="#4D39D2" strokeOpacity={0.1} strokeWidth={8} />
    </g>
    <g>
      <circle cx={20} cy={18} r={14} fill="none" filter="url(#shadow)" />
    </g>
  </svg>
);

const SwitcherIcon = memo(SvgComponent);
export default SwitcherIcon;
