import { panda_group, team_0 } from 'assets';
import { AboutUs, HeroSection, InfoSection, Team, Values } from './components';
import useWindowSize from 'hooks/useWindowSize';

export default function AboutUsLanding() {
  const { isMobile } = useWindowSize();
  return (
    <div>
      <HeroSection
        image={panda_group}
        title="Pandatech"
        subtitle="About"
        description="Founded in January 2023, PandaTech quickly evolved from a small team of 4 into a dynamic group of 20 specialists. Our journey began with the ambitious goal of creating the best Armenian FinTech application, overcoming significant challenges along the way. Today, we excel in designing, developing, and maintaining enterprise applications across various industries. Leveraging the latest technologies, we continuously strive for innovation and excellence, committed to delivering top-notch solutions for our clients."
      />
      <div>
        <div>
          <InfoSection
            image={team_0}
            title="Our Story"
            description="PandaTech began its journey in July 2022 with detailed business planning and officially opened in January 2023 with a team of 4 passionate professionals. Initially focusing on building the best Armenian FinTech application, we faced and conquered numerous challenges. Now, as a team of 20 experts, we have developed multiple enterprise applications and continue to grow. Embracing the latest technologies, we are dedicated to constant improvement and delivering exceptional solutions tailored to our clients' needs."
          />
        </div>
        <Values />
        <AboutUs />
        <div>
          <div
            style={{
              marginBottom: isMobile ? '54px' : '128px',
            }}
          >
            <Team />
          </div>
        </div>
      </div>
    </div>
  );
}
