import { useDarkMode } from 'context';
import { useGetTechnologyQuery } from 'features/landing/services/landingApi';
import { TTechnology } from 'features/landing/types';
import styles from './TechnologiesPreview.module.scss';
import { Ellipse } from 'components';
import useWindowSize from 'hooks/useWindowSize';
import { Skeleton } from 'antd';

export default function TechnologiesPreview() {
  const { data, error, isLoading } = useGetTechnologyQuery();
  const { isDarkMode } = useDarkMode();
  const { isMobile } = useWindowSize();

  // Separate images based on imageType
  const darkModeImages: string[] =
    data
      ?.filter((item: TTechnology) => item.colorMode === 2)
      .map((item: TTechnology) => item.imageUrl) || [];
  const lightModeImages: string[] =
    data
      ?.filter((item: TTechnology) => item.colorMode === 1)
      .map((item: TTechnology) => item.imageUrl) || [];

  if (error) return <></>;
  if (!data?.length) return <></>;
  // Decide which images to display based on isDarkMode
  const imagesToShow = isDarkMode ? darkModeImages : lightModeImages;
  return (
    <div className={styles.technologiesPreview}>
      {isLoading ? (
        <>
          <Skeleton />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(150px,1fr))',
              rowGap: '20px',
              marginTop: '20px',
            }}
          >
            {Array.from({ length: 20 }).map((_, index) => (
              <Skeleton.Image key={index} active />
            ))}
          </div>
        </>
      ) : (
        <>
          <Ellipse
            color="rgba(78, 55, 211, 0.30)"
            width={299}
            height={299}
            left={isMobile ? 30 : 300}
            top={-100}
          />
          <h1>Our technology stack</h1>
          <p>
            We&apos;re always researching and ready to adopt new and best technologies, ensuring we
            remain at the forefront of industry advancements. By utilizing these technologies, we
            strive to deliver exceptional digital experiences and stay ahead in the ever-evolving
            tech landscape.
          </p>
          <div className={styles.imageGrid}>
            {imagesToShow.map((imageUrl) => (
              <img key={imageUrl} src={imageUrl} alt="technology" />
            ))}
          </div>
        </>
      )}
    </div>
  );
}
