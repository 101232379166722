import styles from './InfoSection.module.scss';

export default function InfoSection({
  image,
  title,
  description,
}: {
  image: string;
  title: string;
  description: string;
}) {
  return (
    <section className={styles.infoSection}>
      <div className={styles.infoSectionInner}>
        <div className={styles.infoSectionContent}>
          <div className={styles.infoSectionImgWrapper}>
            <img width={600} height={600} loading="lazy" src={image} alt="info image" />
          </div>
          <div className={styles.infoSectionRightBlock}>
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
