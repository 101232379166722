import { JobCard } from 'components';
import { useGetCareerQuery } from 'features/landing/services/landingApi';
import styles from './Jobs.module.scss';

export default function Jobs() {
  const { data } = useGetCareerQuery();
  // if (!data) return null;
  return (
    <section className={styles.jobs}>
      <div className={styles.jobsInner}>
        {data?.length ? (
          <div className={styles.jobsCardWrapper}>
            {data?.map((job) => {
              return <JobCard key={job.id} {...job} />;
            })}
          </div>
        ) : (
          <NotAvailableJobs />
        )}
      </div>
    </section>
  );
}
function NotAvailableJobs() {
  return (
    <>
      <h1 className={styles.notAvailable}>We're Not Hiring Right Now, But Stay Tuned!</h1>
      <p className={styles.notAvailableDesc}>
        Thank you for your interest in joining our team! While we currently don&apos;t have any open
        positions, we are always on the lookout for talented and passionate individuals who share
        our vision. Please check back regularly or connect with us on our social media channels for
        updates on future opportunities. We look forward to the possibility of working together in
        the near future!
      </p>
    </>
  );
}
