import React, { CSSProperties } from "react";
import styles from "./UnderlinedButton.module.scss";

interface Props {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  style?: CSSProperties;
}

const UnderlinedButton: React.FC<Props> = ({
  title,
  onClick,
  disabled,
  style,
}) => {
  return (
    <span
      role="button"
      className={`${styles.underlinedButton} ${disabled && styles.disabled}`}
      style={style}
      onClick={() => !disabled && onClick()}
    >
      {title}
    </span>
  );
};

export default UnderlinedButton;
