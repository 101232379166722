import { Card, Container } from 'components';
import styles from './AboutUs.module.scss';
export default function AboutUs() {
  return (
    <section>
      <h1 aria-label="about-us" data-component="about-us"></h1>
      <Container className={styles.container}>
        <Card
          title="Our Mission"
          description="To revolutionize FinTech in Armenia and beyond by developing cutting-edge enterprise software solutions that adhere to the highest industry standards, including regulatory compliance. Through innovation and excellence, we aim to empower businesses and individuals with seamless, secure, and user-centric financial experiences."
        />
        <Card
          title="Our Vision"
          description="To establish ourselves as a globally renowned and trusted IT consulting and support partner, delivering exceptional solutions that drive success and growth for our clients. With a relentless commitment to excellence, we aspire to become a leading force in the software development industry, recognized for our expertise, reliability, and transformative impact."
        />
      </Container>
    </section>
  );
}
