import { hi } from 'assets';
import { HeroSection, Jobs, Team, TeamDescription } from './components';
import { Benefits } from './components/Job/Job';

export default function CareerLanding() {
  return (
    <div
      style={{
        // background: 'var(--hero-bg)',
        position: 'relative',
        zIndex: -1,
      }}
    >
      <HeroSection
        image={hi}
        subtitle="Let's start"
        title="CAREER"
        description="Exciting career opportunities await! If you're ready to join our tech-family, send us your CV and cover letter to be part of our innovative team."
      />
      <Jobs />
      <Benefits />
      <Team />
      <TeamDescription />
    </div>
  );
}
