import { Ellipse } from 'components';
import styles from './ApplyForJob.module.scss';
import ApplyForJobForm from './ApplyForJobForm';
import useWindowSize from 'hooks/useWindowSize';

export default function ApplyForJob() {
  const { isMobile } = useWindowSize();

  return (
    <section className={styles.applyForJob}>
      <Ellipse
        width={289}
        height={307}
        color="rgba(255, 0, 168, 0.16)"
        top={107}
        left={isMobile ? 0 : 200}
      />
      <Ellipse
        width={197}
        height={209}
        color="rgba(62, 204, 153, 0.72)"
        top={isMobile ? 500 : 410}
        right={isMobile ? 0 : 600}
      />

      <Ellipse width={300} height={250} color="rgba(78, 55, 211, 0.30)" top={200} right={201} />
      <div className={styles.applyForJobInner}>
        <ApplyForJobForm />
      </div>
    </section>
  );
}
