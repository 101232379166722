export const TOKEN_KEY = 'access_token';

export const CHANGE_PASS_KEY = 'change-pass';

export const REJECT = 'Reject';
export const REVIEWED = 'Review';
export const JOB_REJECT = 0;
export const JOB_REVIEWED = 1;

export const PASSWORD_REGEXP = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{10,}$/;

export const PASSWORD_MSG =
  'Password must include at least 10 characters, 1 uppercase, 1 lowercase, 1 special character, and 1 digit';

export enum MODALS {
  CONFIRM_MODAL = 'confirmModal',
  USERS_MODAL = 'usersModal',
  JOB_APPLY_MODAL = 'jobApplyModal',
  JOB_CONFIRM_MODAL = 'jobConfirmModal',
}

export enum UserRoleEnum {
  SuperAdmin = 1,
  Admin = 2,
  User = 3,
}

export interface IHandleBoolean {
  value: boolean;
  trueText: string;
  falseText: string;
}

export const USER_ROLES = [
  { value: UserRoleEnum.Admin, label: 'Admin' },
  { value: UserRoleEnum.User, label: 'User' },
];

export const globalApiRoutes = {
  EXPORT_DATA: (tableKey: string) => `v1/${tableKey}/export`,
  COLUMN_VALUES: (tableName: string) => `v1/${tableName}/column-distinct-values`,
  CONFIGS: 'v1/user/frontend/configs',
};

export const locationColor = {
  2: 'var(--location-type-color-2)',
  1: 'var(--location-type-color-1)',
  3: 'var(--location-type-color-3)',
};
export const jobType = {
  2: 'var(--type-color-1)',
  1: 'var(--type-color-2)',
  3: 'var(--type-color-3)',
};
export const locationText = {
  2: 'On-site',
  1: 'Remote',
  3: 'Hybrid',
};
export const typeText = {
  1: 'Full-time',
  2: 'Part-time',
  3: 'Freelance',
};
