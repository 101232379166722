import styles from './DescriptionCard.module.scss';
type DescriptionCardProps = {
  title: string;
  description: string;
};
export default function DescriptionCard({ description, title }: DescriptionCardProps) {
  return (
    <div className={styles.descriptionCard}>
      <h1 className={styles.descriptionCardTitle}>{title}</h1>
      <p className={styles.descriptionCardDescription}>{description}</p>
    </div>
  );
}
