import { NavLink } from 'react-router-dom';
import styles from './LandingNav.module.scss';
import cx from 'classnames';

export default function MobileNavi({ onLinkClick }: { onLinkClick: () => void }) {
  return (
    <nav className={styles.mobileNav}>
      <ul>
        <li>
          <NavLink
            onClick={onLinkClick}
            className={({ isActive }) =>
              cx(`${styles.mobileListElement}`, {
                [styles.active]: isActive,
              })
            }
            to="/about-us"
          >
            About us
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={onLinkClick}
            className={({ isActive }) =>
              cx(`${styles.mobileListElement}`, {
                [styles.active]: isActive,
              })
            }
            to="/technologies"
          >
            Tech stack
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={onLinkClick}
            className={({ isActive }) =>
              cx(`${styles.mobileListElement}`, {
                [styles.active]: isActive,
              })
            }
            to="/career"
          >
            Careers
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={onLinkClick}
            className={({ isActive }) =>
              cx(`${styles.mobileListElement}`, {
                [styles.active]: isActive,
              })
            }
            to="/contact"
          >
            Contact us
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}
