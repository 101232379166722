import styles from "./AnimatedCard.module.scss";
type AnimatedCardProps = {
  children: React.ReactNode;
};
export default function AnimatedCard({ children }: AnimatedCardProps) {
  return (
    <div className={styles.animatedCard}>
      <div>{children}</div>
    </div>
  );
}
