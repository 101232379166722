import { SVGProps, memo } from "react";
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"1em"}
    height={"1em"}
    viewBox="0 0 15 15"
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#fff"
        d="M7.224 7.218a3.734 3.734 0 0 1 .292-4.172c.26-.333-.086-.81-.491-.683-.472.151-.932.386-1.358.713-1.588 1.222-2.148 3.433-1.332 5.26a4.41 4.41 0 0 0 6.993 1.48c.316-.289.083-.825-.342-.768a3.726 3.726 0 0 1-3.762-1.83Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 5.285 9.154 0l5.285 9.154-9.154 5.285z" />
      </clipPath>
    </defs>
  </svg>
);
const MoonIcon = memo(SvgComponent);
export default MoonIcon;
