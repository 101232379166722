import React, { useState } from 'react';
import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';

type Props = TextAreaProps;

const AutosizeInput: React.FC<Props> = (props) => {
  const [autoSize, setAutoSize] = useState({ minRows: 1, maxRows: 1 });

  const handleQuestionFocus = () => {
    setAutoSize({ minRows: 1, maxRows: 60 });
  };

  const handleBlur = () => {
    setAutoSize({ minRows: 1, maxRows: 1 });
  };

  return (
    <Input.TextArea
      {...props}
      autoSize={autoSize}
      rows={1}
      onFocus={handleQuestionFocus}
      onBlur={handleBlur}
    />
  );
};

export default AutosizeInput;
