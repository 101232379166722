import { AnimatedCard, Ellipse } from 'components';
import styles from './Job.module.scss';
import { Chip } from 'components';
import { benefits } from 'features/landing/constants';
import useWindowSize from 'hooks/useWindowSize';
import { jobType, locationColor, locationText, typeText } from '_constants';

export default function Job({
  children,
  type,
  title,
  location,
}: {
  children: React.ReactNode;
  type?: number;
  title?: string;
  location: string | number;
}) {
  const { isMobile } = useWindowSize();

  return (
    <div className={styles.job}>
      <div className={styles.jobHeader}>
        {isMobile ? null : (
          <Ellipse
            color={'rgba(255, 0, 168, 0.32)'}
            width={'30vw'}
            height={'20vw'}
            left={290}
            top={0}
          />
        )}
        {!isMobile ? null : (
          <Ellipse
            color={'rgba(78, 55, 211, 0.48)'}
            width={'30vw'}
            height={'20vw'}
            right={142}
            top={0}
          />
        )}

        {isMobile ? null : (
          <Ellipse
            color={'rgba(62, 204, 153, 0.56)'}
            width={'20vw'}
            height={'15vw'}
            right={42}
            top={15}
          />
        )}

        <h1>{title || ''}</h1>
        <div className={styles.chipWrapper}>
          <Chip
            title={locationText[location as keyof typeof locationText]}
            color={locationColor[location as keyof typeof locationColor]}
          />
          <Chip
            title={typeText[type as keyof typeof typeText]}
            color={jobType[type as keyof typeof jobType]}
          />
        </div>
      </div>
      <div>{children}</div>
      {/* <div className={styles.offer}>
        <h2 className={styles.offerTitle}>What We Offer</h2>
        <div className={styles.jobOffer}>
          {benefits.map((benefit) => (
            <AnimatedCard key={benefit.id}>
              <div className={styles.offerCard}>
                <div className={styles.offerCardHeading}>
                  <div className={styles.icon}>
                    <benefit.icon />
                  </div>
                  <div className={styles.jobOfferItemTitle}>{benefit.title}</div>
                </div>
                {!isMobile ? (
                  <div>
                    <p className={styles.benefitDesc}>{benefit.description}</p>
                  </div>
                ) : null}
              </div>
            </AnimatedCard>
          ))}
        </div>
      </div> */}
      <Benefits />
    </div>
  );
}
export function Benefits() {
  const { isMobile } = useWindowSize();
  return (
    <div className={styles.offer}>
      <h2 className={styles.offerTitle}>What We Offer</h2>
      <div className={styles.jobOffer}>
        {benefits.map((benefit) => (
          <AnimatedCard key={benefit.id}>
            <div className={styles.offerCard}>
              <div className={styles.offerCardHeading}>
                <div className={styles.icon}>
                  <benefit.icon />
                </div>
                <div className={styles.jobOfferItemTitle}>{benefit.title}</div>
              </div>
              {!isMobile ? (
                <div>
                  <p className={styles.benefitDesc}>{benefit.description}</p>
                </div>
              ) : null}
            </div>
          </AnimatedCard>
        ))}
      </div>
    </div>
  );
}
