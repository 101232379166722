import { AggregatesEnum, ComparisonTypesEnum, FilterTypesEnum, IFilterHelper } from '../types';

export enum TABLE_KEY_ENUM {
  USERS = 'users',
  JOB_APPLIES = 'job-applicants',
  CONTACTS = 'quote-requests',
  JOBS = 'jobs',
}

export enum ExportTypesEnum {
  CSV = 'Csv',
  PDF = 'Pdf',
  XLSX = 'Xlsx',
}

export const FILTER_HELPER: IFilterHelper = {
  [FilterTypesEnum.ENCRYPTED_DATA]: {
    ColumnType: FilterTypesEnum.ENCRYPTED_DATA,
    AggregateTypes: [],
    FilterTypes: [ComparisonTypesEnum.EQUAL, ComparisonTypesEnum.NOT_EQUAL],
    DefaultSearchType: ComparisonTypesEnum.EQUAL,
    CanBeSorted: false,
  },
  [FilterTypesEnum.BASE_36_ID]: {
    ColumnType: FilterTypesEnum.BASE_36_ID,
    AggregateTypes: [AggregatesEnum.UNIQUE_COUNT],
    FilterTypes: [ComparisonTypesEnum.EQUAL, ComparisonTypesEnum.NOT_EQUAL],
    DefaultSearchType: ComparisonTypesEnum.EQUAL,
    CanBeSorted: true,
  },
  [FilterTypesEnum.NUMBER]: {
    ColumnType: FilterTypesEnum.NUMBER,
    AggregateTypes: [
      AggregatesEnum.UNIQUE_COUNT,
      AggregatesEnum.AVERAGE,
      AggregatesEnum.MIN,
      AggregatesEnum.MAX,
    ],
    FilterTypes: [
      ComparisonTypesEnum.EQUAL,
      ComparisonTypesEnum.NOT_EQUAL,
      ComparisonTypesEnum.GREATER_THAN,
      ComparisonTypesEnum.GREATER_THAN_OR_EQUAL,
      ComparisonTypesEnum.LESS_THAN,
      ComparisonTypesEnum.LESS_THAN_OR_EQUAL,
      ComparisonTypesEnum.BETWEEN,
    ],
    DefaultSearchType: ComparisonTypesEnum.EQUAL,
    CanBeSorted: true,
  },
  [FilterTypesEnum.TEXT]: {
    ColumnType: FilterTypesEnum.TEXT,
    AggregateTypes: [AggregatesEnum.UNIQUE_COUNT],
    FilterTypes: [
      ComparisonTypesEnum.EQUAL,
      ComparisonTypesEnum.NOT_EQUAL,
      ComparisonTypesEnum.STARTS_WITH,
      ComparisonTypesEnum.ENDS_WITH,
      ComparisonTypesEnum.CONTAINS,
      ComparisonTypesEnum.NOT_CONTAINS,
    ],
    DefaultSearchType: ComparisonTypesEnum.EQUAL,
    CanBeSorted: true,
  },
  [FilterTypesEnum.CURRENCY]: {
    ColumnType: FilterTypesEnum.CURRENCY,
    AggregateTypes: [
      AggregatesEnum.SUM,
      AggregatesEnum.AVERAGE,
      AggregatesEnum.MIN,
      AggregatesEnum.MAX,
    ],
    FilterTypes: [
      ComparisonTypesEnum.EQUAL,
      ComparisonTypesEnum.NOT_EQUAL,
      ComparisonTypesEnum.GREATER_THAN,
      ComparisonTypesEnum.GREATER_THAN_OR_EQUAL,
      ComparisonTypesEnum.LESS_THAN,
      ComparisonTypesEnum.LESS_THAN_OR_EQUAL,
      ComparisonTypesEnum.BETWEEN,
    ],
    DefaultSearchType: ComparisonTypesEnum.EQUAL,
    CanBeSorted: true,
  },
  [FilterTypesEnum.DATE]: {
    ColumnType: FilterTypesEnum.DATE,
    AggregateTypes: [AggregatesEnum.MIN, AggregatesEnum.MAX],
    FilterTypes: [
      ComparisonTypesEnum.EQUAL,
      ComparisonTypesEnum.NOT_EQUAL,
      ComparisonTypesEnum.GREATER_THAN,
      ComparisonTypesEnum.GREATER_THAN_OR_EQUAL,
      ComparisonTypesEnum.LESS_THAN,
      ComparisonTypesEnum.LESS_THAN_OR_EQUAL,
      ComparisonTypesEnum.BETWEEN,
    ],
    DefaultSearchType: ComparisonTypesEnum.BETWEEN,
    CanBeSorted: true,
  },
  [FilterTypesEnum.BOOLEAN]: {
    ColumnType: FilterTypesEnum.BOOLEAN,
    AggregateTypes: [],
    FilterTypes: [ComparisonTypesEnum.EQUAL, ComparisonTypesEnum.NOT_EQUAL],
    DefaultSearchType: ComparisonTypesEnum.EQUAL,
    CanBeSorted: true,
  },
  [FilterTypesEnum.PERCENTAGE]: {
    ColumnType: FilterTypesEnum.PERCENTAGE,
    AggregateTypes: [AggregatesEnum.AVERAGE, AggregatesEnum.MIN, AggregatesEnum.MAX],
    FilterTypes: [
      ComparisonTypesEnum.EQUAL,
      ComparisonTypesEnum.NOT_EQUAL,
      ComparisonTypesEnum.GREATER_THAN,
      ComparisonTypesEnum.GREATER_THAN_OR_EQUAL,
      ComparisonTypesEnum.LESS_THAN,
      ComparisonTypesEnum.LESS_THAN_OR_EQUAL,
      ComparisonTypesEnum.BETWEEN,
    ],
    DefaultSearchType: ComparisonTypesEnum.EQUAL,
    CanBeSorted: true,
  },
  [FilterTypesEnum.TAGS]: {
    ColumnType: FilterTypesEnum.TAGS,
    AggregateTypes: [],
    FilterTypes: [ComparisonTypesEnum.IN],
    DefaultSearchType: ComparisonTypesEnum.IN,
    CanBeSorted: true,
  },
  [FilterTypesEnum.NUMBER_COLLECTION]: {
    ColumnType: FilterTypesEnum.NUMBER_COLLECTION,
    AggregateTypes: [
      AggregatesEnum.UNIQUE_COUNT,
      AggregatesEnum.AVERAGE,
      AggregatesEnum.MIN,
      AggregatesEnum.MAX,
    ],
    FilterTypes: [
      ComparisonTypesEnum.EQUAL,
      ComparisonTypesEnum.NOT_EQUAL,
      ComparisonTypesEnum.GREATER_THAN,
      ComparisonTypesEnum.GREATER_THAN_OR_EQUAL,
      ComparisonTypesEnum.LESS_THAN,
      ComparisonTypesEnum.LESS_THAN_OR_EQUAL,
      ComparisonTypesEnum.BETWEEN,
    ],
    DefaultSearchType: ComparisonTypesEnum.EQUAL,
    CanBeSorted: true,
  },
  [FilterTypesEnum.TEXT_COLLECTION]: {
    ColumnType: FilterTypesEnum.TEXT_COLLECTION,
    AggregateTypes: [AggregatesEnum.UNIQUE_COUNT],
    FilterTypes: [
      ComparisonTypesEnum.EQUAL,
      ComparisonTypesEnum.NOT_EQUAL,
      ComparisonTypesEnum.STARTS_WITH,
      ComparisonTypesEnum.ENDS_WITH,
      ComparisonTypesEnum.CONTAINS,
      ComparisonTypesEnum.NOT_CONTAINS,
    ],
    DefaultSearchType: ComparisonTypesEnum.EQUAL,
    CanBeSorted: true,
  },
};

export const notRequiredFilterTypes = [
  'GreaterThan',
  'GreaterThanOrEqual',
  'LessThan',
  'LessThanOrEqual',
];

export const AGGREGATES_TITLES = {
  [AggregatesEnum.TOTAL]: 'Total',
  [AggregatesEnum.UNIQUE_COUNT]: 'Total Unique Count',
  [AggregatesEnum.AVERAGE]: 'Total AVG',
  [AggregatesEnum.MIN]: 'Total MIN',
  [AggregatesEnum.MAX]: 'Total MAX',
  [AggregatesEnum.SUM]: 'Total SUM',
};
