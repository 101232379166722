import { panda_logo, logo_white, big_panda_right, big_panda_right_white } from 'assets';
import { useDarkMode } from 'context';
import LandingNav from '../Navigation/LandingNav';
import styles from './Header.module.scss';

import { Link } from 'react-router-dom';
import { Drawer, Switch } from 'antd';
import { MenuOutlined, MoonFilled, SunFilled } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import MobileNavi from '../Navigation/MobileNavi';
import useWindowSize from 'hooks/useWindowSize';
import { Ellipse } from 'components';
// import { useLocation } from 'react-router-dom';

export default function Header() {
  const { toggleDarkMode, isDarkMode } = useDarkMode();
  const { isMobile } = useWindowSize();

  const [open, setOpen] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [headerHidden, setHeaderHidden] = useState(false);
  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };
  useEffect(() => {
    // Determine the target for the scroll event based on isMobile
    const scrollTarget = isMobile ? document.querySelector('#wrapper') : window;

    if (!scrollTarget) return; // Exit if scrollTarget is not found (relevant for '#wrapper')

    const handleScroll = () => {
      let currentScrollY: number;

      // Check if scrollTarget is an Element to access scrollTop safely
      if (scrollTarget instanceof Element) {
        currentScrollY = scrollTarget.scrollTop;
      } else {
        // Otherwise, it's safe to assume scrollTarget is window
        currentScrollY = window.scrollY;
      }

      if (currentScrollY > lastScrollY) {
        setHeaderHidden(true); // Scrolling down
      } else {
        setHeaderHidden(false); // Scrolling up
      }
      setLastScrollY(currentScrollY);
    };

    // Add event listener based on the determined target
    scrollTarget.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      scrollTarget.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, isMobile]); // Include isMobile in the dependency array

  return (
    <header className={`${styles.header} ${headerHidden ? styles.hidden : ''}`}>
      <div
        style={{
          height: '32px',
        }}
      >
        <Link to="/">
          <img
            style={{
              width: '100%',
              height: '100%',
            }}
            src={isDarkMode ? logo_white : panda_logo}
            alt="logo"
          />
        </Link>
      </div>
      <LandingNav />
      <div className={styles.headerActions}>
        {/* {params.pathname === '/' ? (
          <Button onClick={scrollToBottom} className={styles.headerButton}>
            Get started
          </Button>
        ) : null} */}
        {/* <Switcher onChange={toggleDarkMode} checked={isDarkMode} /> */}
        <Drawer
          style={{
            background: 'var(--hero-bg)',
            color: 'var(--text-color)',
          }}
          title="Pandatech"
          onClose={toggleDrawer}
          open={open}
          // getContainer={false}
        >
          <Ellipse width={100} height={100} color={'rgba(78, 55, 211)'} />
          <img
            style={{
              position: 'absolute',
              right: 0,
              top: '25%',
            }}
            src={!isDarkMode ? big_panda_right_white : big_panda_right}
            width={200}
            height={300}
            alt=""
          />
          <MobileNavi onLinkClick={toggleDrawer} />
        </Drawer>

        {isMobile ? <MenuOutlined onClick={toggleDrawer} /> : null}
        <Switch
          className="switcher"
          checked={isDarkMode}
          onChange={toggleDarkMode}
          checkedChildren={<SunFilled />}
          unCheckedChildren={<MoonFilled />}
          defaultChecked
        />
      </div>
    </header>
  );
}
