import { Button } from 'components';
import styles from './HelpSection.module.scss';
import FloatingLabelInput from 'components/shared/FloatingInput/FloatingInput';
import { useRequestQuoteMutation } from 'features/landing/services/landingApi';
import { FormEvent, useRef, useState } from 'react';
import { Spin } from 'antd';
type TCredentials = {
  fullName: string;
  email: string;
  phoneNumber: string;
};
export default function QuoteForm() {
  const [requestQuote, { isLoading }] = useRequestQuoteMutation();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null);
  const credentials = useRef<TCredentials>({
    fullName: '',
    email: '',
    phoneNumber: '',
  });
  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    await requestQuote(credentials.current);
    if (formRef.current) {
      formRef.current.reset();
      setSubmitted(true);
    }
  };
  const handleChange = (e: FormEvent<HTMLInputElement>) => {
    credentials.current = {
      ...credentials.current,
      [e.currentTarget.name]: e.currentTarget.value,
    };
  };
  return (
    <div className={styles.quoteForm}>
      {submitted ? (
        <SubbmitionText />
      ) : (
        <>
          <h1>We Are Here to Help You</h1>
          <form ref={formRef} className={styles.form} onSubmit={onSubmit}>
            <FloatingLabelInput
              onChange={handleChange}
              id="name"
              name="fullName"
              type="text"
              label="Name"
              required
            />

            <FloatingLabelInput
              onChange={handleChange}
              id="email"
              name={'email'}
              type="email"
              label="Email"
              required
            />

            <FloatingLabelInput
              onChange={handleChange}
              id="phone"
              name={'phoneNumber'}
              type="phone"
              required
              label="Phone"
            />
            {/* <FloatingLabelInput label="asd" required /> */}
            {isLoading ? (
              <Spin
                tip="Loading"
                style={{
                  marginTop: '1rem',
                  height: '44px',
                }}
              />
            ) : (
              <Button
                type="submit"
                style={{
                  width: '100%',
                  marginTop: '1rem',
                  padding: '0.7rem 1.2rem',
                }}
              >
                Request a Quote
              </Button>
            )}
          </form>
        </>
      )}
    </div>
  );
}

function SubbmitionText() {
  return (
    <div
      style={{
        maxWidth: '500px',
        textAlign: 'center',
        color: 'var(--secondary-text-color)',
        marginInline: 'auto',
      }}
    >
      <h1
        style={{
          fontSize: '48px',
          fontWeight: 500,
        }}
      >
        Congratulations!
      </h1>
      <p
        style={{
          marginTop: '24px',
        }}
      >
        Congratulations on taking the first step towards success
      </p>
      <p
        style={{
          marginTop: '1rem',
          lineHeight: '200%',
        }}
      >
        Our dedicated sales specialists are eagerly waiting to connect with you and provide the
        assistance you need. Expect a prompt response as they reach out to you to discuss your
        specific requirements and guide you towards achieving your goals. Get ready for an exciting
        journey filled with support and tailored solutions!
      </p>
    </div>
  );
}
