import React, { useEffect, useState } from 'react';
import { ComparisonTypesEnum, FilterTypesEnum, IItemFields } from '../../types';
import { Flex, Form, Input, Select, Spin } from 'antd';
import { detectComparisonTypeForSearch, filtersConvertor } from '../../utils/filterHelpers';
import { useSearchParams } from 'react-router-dom';
import { ISelectTypeV2 } from 'types';
import { debounce } from 'utils/helpers.ts';
import { useLazyGetColumnValuesQuery } from 'api';
import { notRequiredFilterTypes } from '../../constants';

interface Props {
  type:
    | FilterTypesEnum.BASE_36_ID
    | FilterTypesEnum.TEXT
    | FilterTypesEnum.ENCRYPTED_DATA
    | FilterTypesEnum.NUMBER
    | FilterTypesEnum.BOOLEAN
    | FilterTypesEnum.CURRENCY
    | FilterTypesEnum.TAGS;
  propertyName: string;
  endpointPath?: string;
  comparisonType: string;
  options?: ISelectTypeV2[];
  name: string;
  handleSelected: (data: string[]) => void;
  selected: string[];
  error: string;
  handleError: (value: string) => void;
  handleChangeComparisonType: (value: ComparisonTypesEnum) => void;
}

const SelectFilter: React.FC<Props> = ({
  type,
  propertyName,
  endpointPath,
  options,
  name,
  handleSelected,
  selected,
  error,
  handleError,
  handleChangeComparisonType,
  comparisonType,
}) => {
  const [searchParams] = useSearchParams();
  const paramsFilters = searchParams.get('filters');
  const [searchValue, setSearchValue] = useState<string>('');
  const [dataOptions, setDataOptions] = useState<{ label: string; value: string }[]>([]);
  const [getFiltersData, { isFetching: loading }] = useLazyGetColumnValuesQuery();

  const handleChange = (value: string[]) => {
    handleSelected(value);
  };

  const handleSearch = debounce((value) => {
    if (type !== FilterTypesEnum.CURRENCY && value.includes('.')) {
      handleError('Only numbers');
    }
    setSearchValue(value);
  });

  const handleDropdownScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - scrollTop === clientHeight) {
      getData({ loadMore: true });
    }
  };

  useEffect(() => {
    if (!paramsFilters) {
      handleSelected([]);
    }
    if (paramsFilters) {
      const filters: IItemFields[] = JSON.parse(paramsFilters);
      const findProperty = filters.find((el: IItemFields) => el.PropertyName === propertyName);
      if (findProperty) {
        handleSelected(findProperty.CheckedItems);
        handleChangeComparisonType(findProperty.ComparisonType as ComparisonTypesEnum);
      }
    }
  }, [paramsFilters]);

  useEffect(() => {
    if (!endpointPath) return;
    getData({ loadMore: false });
  }, []);

  const getData = ({ loadMore }: { loadMore: boolean }) => {
    const lastItem = dataOptions[dataOptions.length - 1]?.value;
    getFiltersData({
      tableName: endpointPath || '',
      Filter: filtersConvertor([
        ...(paramsFilters ? JSON.parse(paramsFilters) : []),
        ...(searchValue
          ? [
              {
                PropertyName: propertyName,
                Search: searchValue,
                Values: [searchValue],
                CheckedItems: selected,
                ComparisonType: detectComparisonTypeForSearch(type),
                TypeForUi: detectComparisonTypeForSearch(type),
                ColumnType: type,
              },
            ]
          : []),
        ...(loadMore
          ? [
              {
                PropertyName: propertyName,
                Search: '',
                Values: [lastItem],
                CheckedItems: [lastItem],
                ComparisonType: 'GreaterThan',
                TypeForUi: 'GreaterThan',
                ColumnType: FilterTypesEnum.BASE_36_ID,
              },
            ]
          : []),
      ]),
      PropertyName: propertyName,
      PageSize: 30,
    }).then((res) => {
      if ('data' in res) {
        setDataOptions((prev) => [
          ...prev,
          ...(res?.data?.data.map((el) => ({ label: el, value: el })) || []),
        ]);
      }
    });
  };

  const renderOptions = () => {
    if (options) {
      return options;
    }
    if (
      notRequiredFilterTypes.includes(comparisonType) &&
      searchValue &&
      !dataOptions.find((el) => searchValue === String(el))
    ) {
      return [{ label: searchValue, value: searchValue }, ...dataOptions];
    }
    return dataOptions;
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSelected([e.target.value]);
  };

  const handleChangeBetween = (e: React.ChangeEvent<HTMLInputElement>, index: 1 | 2) => {
    if (index === 1) {
      handleSelected([e.target.value, selected[1]]);
    }
    if (index === 2) {
      handleSelected([selected[0], e.target.value]);
    }
  };

  const renderContent = () => {
    switch (comparisonType) {
      case ComparisonTypesEnum.IN:
      case ComparisonTypesEnum.EQUAL:
      case ComparisonTypesEnum.NOT_EQUAL:
        return (
          <Select
            mode="multiple"
            id={`select_${name}`}
            allowClear
            style={{ width: '100%' }}
            placeholder={name}
            onChange={handleChange}
            options={renderOptions()}
            onSearch={handleSearch}
            value={selected}
            onPopupScroll={!options ? handleDropdownScroll : undefined}
            dropdownRender={(menu) => (
              <>
                {menu}
                {loading && (
                  <div style={{ textAlign: 'center', padding: '8px' }}>
                    <Spin />
                  </div>
                )}
              </>
            )}
          />
        );
      case ComparisonTypesEnum.ENDS_WITH:
      case ComparisonTypesEnum.STARTS_WITH:
        return (
          <Select
            mode="tags"
            id={`select_${name}`}
            allowClear
            style={{ width: '100%' }}
            placeholder={name}
            onChange={handleChange}
            onSearch={handleSearch}
            value={selected}
          />
        );
      case ComparisonTypesEnum.BETWEEN:
        return (
          <Flex gap={8}>
            <Input
              placeholder={'From'}
              value={selected[0] || ''}
              onChange={(e) => handleChangeBetween(e, 1)}
            />
            <Input
              placeholder={'To'}
              value={selected[1] || ''}
              onChange={(e) => handleChangeBetween(e, 2)}
            />
          </Flex>
        );
      default:
        return <Input onChange={handleChangeInput} placeholder={name} value={selected} />;
    }
  };

  return (
    <Form.Item validateStatus={error ? 'error' : ''} help={error}>
      {renderContent()}
    </Form.Item>
  );
};

export default SelectFilter;
