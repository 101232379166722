export const getLSData = (key: string) => {
  try {
    if (typeof window !== 'undefined') {
      const value = localStorage.getItem(key);

      if (value !== null) {
        return value;
      }
    }
  } catch (e: unknown) {
    console.log('error reading value', e);
    // error reading value
  }
};

export const setLSData = (key: string, value: string) => {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    console.log('saving error', e);
    // saving error
  }
};

export const removeLSData = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.log('remove error', e);
    // remove error
  }
};

export const clearAllLSData = () => {
  try {
    localStorage.clear();
  } catch (e) {
    console.log('clear storage error', e);
    // clear error
  }
};
