import { Outlet } from 'react-router-dom';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { big_panda, big_panda_right, big_panda_white, big_panda_right_white } from 'assets';
import { useDarkMode } from 'context';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function UseScrollTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

export default function LadingLayout() {
  const { isDarkMode } = useDarkMode();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div id="wrapper" className="wrapper">
      <Header />
      <main
        style={{
          marginTop: '80px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <img
          width={400}
          height={700}
          className="big-panda"
          src={isDarkMode ? big_panda : big_panda_white}
          alt="big panda"
        />
        <img
          width={400}
          height={700}
          className="big-panda-right"
          src={isDarkMode ? big_panda_right : big_panda_right_white}
          alt="big panda right"
        />

        <div
          style={{
            maxWidth: '1440px',
            marginInline: 'auto',
            position: 'relative',
            zIndex: '-1',
          }}
        >
          <Outlet />
        </div>
      </main>
      <Footer />
    </div>
  );
}
